import mapboxgl from 'mapbox-gl';

const VALID_RESOURCE_TYPES_MARKERS = ["land", "field", "gap"];
const CLASS_PER_RESOURCE_TYPE = {
    "land": "label-land",
    "field": "label-field",
    "gap": "label-gap"
};

export class MapMarkers {

    constructor(map, parent) {
        this.map = map;
        this.parent = parent;
        this.markers = {}
    }

    init() {
        const added = []
        const features = []

        this.parent.source().get().features.forEach(feature => {
            if (VALID_RESOURCE_TYPES_MARKERS.indexOf(feature.properties.resource_type) == -1) {
                return;
            }

            let [ lng, lat ] = feature.properties.centroid;
            added.push(feature.properties.id);
            /*this.addMarker({
                id: feature.properties.id,
                className: CLASS_PER_RESOURCE_TYPE[feature.properties.resource_type],
                lng,
                lat,
                textContent: feature.properties.name
            });*/
            features.push(
                {
                    'type': 'Feature',
                    'properties': {
                        
                        'name': feature.properties.name
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [ lng, lat ]
                    }
                }
            );
        });
        // Check if the source exists
        if (this.map.getSource('markers')) {
            // If the source exists, update its data
            this.map.getSource('markers').setData({
                'type': 'FeatureCollection',
                'features': features
            });
        } else {
            // If the source does not exist, add it
            this.map.addSource('markers', {
                'type': 'geojson',
                'data': {
                    'type': 'FeatureCollection',
                    'features': features
                }
            });
        }

        // Check if the layer exists
        if (!this.map.getLayer('markers')) {
            // If the layer does not exist, add it
            this.map.addLayer({
                'id': 'markers',
                'type': 'symbol',
                'source': 'markers',
                'layout': {
                    'icon-image': '{icon}',
                    'icon-size': 1.5,
                    'text-font': ['Open Sans Bold'],
                    'text-field': ["get", "name"], // set the text to the 'name' property
                    'text-offset': [0, 1.5],
                    'text-anchor': 'top'
                },
                'paint': {
                    'text-color': '#000',
                    'text-halo-color': '#fff',
                    'text-halo-width': 2,
                    'text-halo-blur': 1,
                }
            });
        }

        Object.keys(this.markers).forEach(key => {
            if (added.indexOf(key) === -1) {
                this.deleteMarker(key);
            }
        })
    }

    deleteMarker(id) {
        if (!this.markers[id]) {
            return
        }
        this.markers[id].marker.remove();
        delete this.markers[id];
    }

    hide(id) {
        if (!this.markers) {
            setTimeout(() => this.hide(id), 100);
            return
        }

        if (!this.markers[id]) {
            return
        }

        this.markers[id].container.style.visibility = "hidden";
    }

    show(id) {
        if (!this.markers) {
            setTimeout(() => this.show(id), 100);
            return
        }

        if (!this.markers[id]) {
            return
        }
        
        this.markers[id].container.style.visibility = "visible";
    }

    reload() {
        this.init();
    }

    addMarker({ id, className, lng, lat, popup, textContent }) {
        if (this.markers[id]) {
            this.markers[id].container.textContent = textContent;
            return;
        }

        const markerId = this._getMarkerId(id);
        var container = document.createElement('div');
        container.setAttribute("id", markerId);
        container.textContent = textContent;
        container.className = className;

        let marker = new mapboxgl.Marker(container)
            .setLngLat([lng, lat]);

        if (popup) {
            marker = marker.setPopup(popup);
        }

        marker = marker.addTo(this.map);
        this.markers[id] = { marker, container};
    }

    changeStyle(cls, display){
        const el = document.getElementsByClassName(cls);
        for (var i = 0; i < el.length; i++) {
            if (el[i].hasAttribute('ignore')) {
                continue;
            }

            el[i].style.display = display;
        }
    };

    handleZoom() {
        const $this = this;
        return () => {
            const zoom = $this.map.getZoom();

            if (zoom < 11) {
                $this.changeStyle('label-land', 'none');
                $this.changeStyle('label-field', 'block');
            } else {
                $this.changeStyle('label-land', 'block');
                $this.changeStyle('label-field', 'none');
            }

            if (zoom > 14) {
                $this.changeStyle('label-gap', 'block');
            } else {
                $this.changeStyle('label-gap', 'none');
            }
        };
    }
        
    _getMarkerId(id) {
        return "marker-" + id;
    }
}