
import axios from "axios";

const join = (url, path) => {
    return url + path;
};

const CREATE_LAND_PATH = "/api/v2/fields/:id/lands";
const GET_LAND_PATH = '/api/v1/fields/gis/:id';
const ALL_LANDS_PATH = "/api/v1/fields/gis";
const DATES_PATH = '/api/v1/fields/gis/dates';

const LAYER_PATH = '/api/v1/fields/gis/:id/eo';

const DATES_PATH_V2 = '/api/v2/fields/lands/:id/remote-sensing/dates';
const LAYER_PATH_V2 = "/api/v2/fields/lands/:land_id/remote-sensing/:layer";
const LAYER_TIFF_PATH_V2 = "/api/v2/fields/lands/:land_id/remote-sensing/tiff/:layer"
const UPDATE_LAND_V2 = "/api/v2/fields/lands/:land_id";

const LAYER_POINT_PATH = '/api/v1/fields/gis/:id/eo/point';
const AMBIENT_PATH = '/api/v1/fields/gis/:id/ambient';
const DOWNLOAD_LAYER_PATH = '/api/v1/fields/gis/:id/download';
const DOWNLOAD_AMBIENT_PATH = '/api/v1/fields/gis/:id/ambient/download';
const DOWNLOAD_IMAGE_AMBIENT_PATH = '/api/v1/fields/gis/:id/image-ambient/download';
const DOWNLOAD_PRESCRIPTION_AMBIENT_PATH = '/api/v1/fields/gis/:id/eo/ambient/prescription/download';
const DOWNLOAD_PRESCRIPTION_IMAGE_AMBIENT_PATH ='/api/v1/fields/gis/:id/eo/image-ambient/prescription/download'; 
const DOWNLOAD_PRESCRIPTION_PATH = '/api/v1/fields/gis/:id/eo/prescription/download';
const CLASSIFY_AMBIENT_PATH = "/api/v1/fields/gis/:id/eo/ambient/classify";
const IMAGE_AMBIENT_PATH = "/api/v1/fields/gis/:id/image-ambient";

export const create = (field_id, data) => {
    return axios.post(CREATE_LAND_PATH.replace(':id', field_id), data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const update = (land_id, data) => {
    return axios.put(GET_LAND_PATH.replace(':id', land_id), data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const updateV2 = (land_id, data) => {
    return axios.put(UPDATE_LAND_V2.replace(':land_id', land_id), data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const list = (field_id) => {
    return axios.get("/api/v2/fields/:id/lands".replace(":id", field_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const deleteLand = (land_id) => {
    return axios.delete(GET_LAND_PATH.replace(':id', land_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const get = (gis_id) => {
    return axios.get(GET_LAND_PATH.replace(':id', gis_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const all = (filters) => {
    return axios.get(ALL_LANDS_PATH, { params: { ...filters } }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const datesV2 = (land_id, filters) => {
    return axios.get(DATES_PATH_V2.replace(":id", land_id), { params: filters }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const dates = (filters) => {
    return axios.post(DATES_PATH, filters).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const getLayerV2 = (land_id, layer, options, cancelTokenRequest) => {
    return axios.get(LAYER_PATH_V2.replace(':land_id', land_id).replace(':layer', layer), { cancelToken: cancelTokenRequest.token, params: { ...options }, responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data,
                headers: res.headers
            };
        }
    });
}


export const getLayerTiff = (land_id, layer, options, cancelTokenRequest) => {
    return axios.get(LAYER_TIFF_PATH_V2.replace(':land_id', land_id).replace(':layer', layer), { cancelToken: cancelTokenRequest.token, params: { ...options }, responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const getLayer = (land_id, options, cancelTokenRequest) => {
    return axios.get(LAYER_PATH.replace(':id', land_id), { cancelToken: cancelTokenRequest.token, params: { ...options }, responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data,
                headers: res.headers
            };
        }
    });
}

export const getPointValue = (land_id, cancelTokenRequest, options) => {
    return axios.get(LAYER_POINT_PATH.replace(':id', land_id), { cancelToken: cancelTokenRequest, params: { ...options } }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data,
                headers: res.headers
            };
        }
    });
}

export const getAmbient = (gis_id) => {
    return axios.get(AMBIENT_PATH.replace(':id', gis_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const createAmbient = (gis_id, data) => {
    return axios.post(AMBIENT_PATH.replace(':id', gis_id), data).then(res => {
        if (res.status == 202) {
            return {
                ok: true,
                data: res.data
            };
        }
    });;
};

export const downloadLayer = (land_id, params) => {
    return axios.get(DOWNLOAD_LAYER_PATH.replace(':id', land_id), { params, responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const downloadAmbient = (land_id, params) => {
    return axios.get(DOWNLOAD_AMBIENT_PATH.replace(':id', land_id), { params, responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const downloadPrescriptionAmbient = ({ land_id, data }) => {
    return axios.post(DOWNLOAD_PRESCRIPTION_AMBIENT_PATH.replace(':id', land_id), data, { responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const downloadPrescription = ({ land_id, data }) => {
    return axios.post(DOWNLOAD_PRESCRIPTION_PATH.replace(':id', land_id), data, { responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const deleteAmbient = (land_id) => {
    return axios.delete(AMBIENT_PATH.replace(':id', land_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true
            };
        }
    });
}

export const updateAmbient = (gis_id, data) => {
    return axios.put(AMBIENT_PATH.replace(':id', gis_id), data).then(res => {
        if (res.status == 202) {
            return {
                ok: true,
                data: res.data
            };
        }
    });;
};

export const classifyPrescription = (land_id, data) => {
    return axios.post(CLASSIFY_AMBIENT_PATH.replace(':id', land_id), data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            }
        }
    });
};

export const createImageAmbient = (land_id, data) => {
    return axios.post(IMAGE_AMBIENT_PATH.replace(':id', land_id), data).then(res => {
        if (res.status == 202) {
            return {
                ok: true,
                data: res.data
            }
        }
    });
};


export const getImageAmbient = (gis_id) => {
    return axios.get(IMAGE_AMBIENT_PATH.replace(':id', gis_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const deleteImageAmbient = (land_id) => {
    return axios.delete(IMAGE_AMBIENT_PATH.replace(':id', land_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true
            };
        }
    });
}

export const downloadPrescriptionImageAmbient = ({ land_id, data }) => {
    return axios.post(DOWNLOAD_PRESCRIPTION_IMAGE_AMBIENT_PATH.replace(':id', land_id), data, { responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const downloadImageAmbient = (land_id, params) => {
    return axios.get(DOWNLOAD_IMAGE_AMBIENT_PATH.replace(':id', land_id), { params, responseType: 'blob' }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}