import { useState, useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useViewLandContext } from "../ViewLandContext";
import { useViewImageContext } from "./ViewImageContext";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import * as LandService from "../../../services/LandService";
import "./DateSelector.style.scss";

const DateSelector = () => {
  const viewLandContext = useViewLandContext();
  const viewImageContext = useViewImageContext();
  const [selectedDate, handleDateChange] = useState(null);
  const [openedDatePicker, setOpenedDatePicker] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();
  const [cloudinessFilter, setCloudinessFilter] = useState(20);
  const [debouncedCloudinessFilter, setDebouncedCloudinessFilter] = useState(cloudinessFilter);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedCloudinessFilter(cloudinessFilter);
    }, 800); // Ajusta el tiempo de debounce según lo necesario (500ms en este caso)

    return () => {
      clearTimeout(handler);
    };
  }, [cloudinessFilter]);

  useEffect(() => {
    if (viewLandContext.land == null) {
      return;
    }

    // Update dates based on cloudiness filter
    errorHandler(
      LandService.datesV2(viewLandContext.land.land_id, {
        cloud_coverage_filter: "below",
        cloud_coverage: debouncedCloudinessFilter
      })
    ).then((res) => {
      if (res.ok) {
        viewImageContext.setDates(res.data.dates);
      }
    });

    errorHandler(
      LandService.datesV2(viewLandContext.land.land_id, {
        cloud_coverage_filter: "above",
        cloud_coverage: debouncedCloudinessFilter
      })
    ).then((res) => {
      if (res.ok) {
        viewImageContext.setCloudyDates(res.data.dates);
      }
    });
  }, [viewLandContext.land, debouncedCloudinessFilter]); // Add cloudinessFilter as a dependency

  const handleNextDate = () => {
    const currentDate = viewImageContext.activeLands[viewLandContext.land.land_id]?.selectedDate;
  
    const currentDateIndex = viewImageContext.dates.indexOf(format(currentDate, 'yyyy-MM-dd'));

    if (currentDateIndex === -1){
      const currentCloudyDateIndex = viewImageContext.cloudyDates.indexOf(format(currentDate, 'yyyy-MM-dd'));

      const filteredDates = viewImageContext.dates.filter((date) => new Date(date) > new Date(currentDate));
      if (filteredDates.length === 0) {
        return
      }
      
        // Encuentra la fecha más cercana en viewImageContext.dates a currentCloudyDateIndex
      const closestDate = filteredDates.reduce((closest, date) => {
        const diffCurrent = Math.abs(new Date(date) - new Date(viewImageContext.cloudyDates[currentCloudyDateIndex]));
        const diffClosest = Math.abs(new Date(closest) - new Date(viewImageContext.cloudyDates[currentCloudyDateIndex]));

        return diffCurrent < diffClosest ? date : closest;
      }, viewImageContext.dates[0]);
      if (closestDate) {
        viewImageContext.changeDate(viewLandContext.land, new Date(closestDate + "T00:00:00"));
      }

    }
  
    if (currentDateIndex > 0) {
      const prevDate = viewImageContext.dates[currentDateIndex - 1];
      
      // Crear una nueva fecha pero sin ajustar la zona horaria
      const formattedDate = prevDate; // Esta fecha ya está en formato 'yyyy-MM-dd'
      
      // Crear la fecha en el backend como solo 'yyyy-MM-dd' sin interpretación de zona horaria
      viewImageContext.changeDate(viewLandContext.land, new Date(formattedDate + "T00:00:00"));
    }
  };
  
  const handlePrevDate = () => {
    const currentDate = viewImageContext.activeLands[viewLandContext.land.land_id]?.selectedDate;
  
    // if (!currentDate) return;
  
    const currentDateIndex = viewImageContext.dates.indexOf(format(currentDate, 'yyyy-MM-dd'));
    console.log(currentDateIndex);
    

    if (currentDateIndex === -1){

      const currentCloudyDateIndex = viewImageContext.cloudyDates.indexOf(format(currentDate, 'yyyy-MM-dd'));
      console.log("currentCloudyDateIndex");
        const filteredDates = viewImageContext.dates.filter((date) => new Date(date) < new Date(currentDate));
        if (filteredDates.length === 0) {
          return
        }
        const closestDate = filteredDates.find(date => new Date(date) <= currentDate) || viewImageContext.dates[0];
        
      if (closestDate) {
        viewImageContext.changeDate(viewLandContext.land, new Date(closestDate + "T00:00:00"));
        return;
      }

    }
  
    if (currentDateIndex < viewImageContext.dates.length - 1) {
      const nextDate = viewImageContext.dates[currentDateIndex + 1];
      
      // Crear una nueva fecha pero sin ajustar la zona horaria
      const formattedDate = nextDate; // Esta fecha ya está en formato 'yyyy-MM-dd'
      
      // Crear la fecha en el backend como solo 'yyyy-MM-dd' sin interpretación de zona horaria
      viewImageContext.changeDate(viewLandContext.land, new Date(formattedDate + "T00:00:00"));
    }
  };

  return (
    <>
      <div className="d-flex align-items-center p-5">
        <KTSVG
          path="/media/icons/duotune/general/gen014.svg"
          className="svg-icon svg-icon-2x svg-icon-primary me-5"
        />
        <div className="flex-grow-1 me-1 d-flex align-items-center" style={{ width: "55%" }}>
          {viewImageContext.activeLands[viewLandContext.land.land_id] &&
            viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate && (
              <>
                <button onClick={handlePrevDate} className="btn btn-icon btn-light">
                <KTSVG
                path="/media/icons/duotune/arrows/arr026.svg"
                className="svg-icon svg-icon-1x svg-icon-primary"
              />
                </button>
              </>
            )}
          <a
            onClick={(e) => {
              e.preventDefault();
              if (viewImageContext.loading) {
                return;
              }
              setOpenedDatePicker(true);
            }}
            href="#"
            className={`fw-bold text-gray-800 text-hover-primary fs-6 ` + (viewImageContext.loading ? "disabled" : "")}
            style={{margin: "auto"}}
          >
            {!viewImageContext.activeLands[viewLandContext.land.land_id] ||
            viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate == null
              ? t("images:date_selector")
              : format(viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate, "dd/MM/yyyy")}
          </a>
          {viewImageContext.activeLands[viewLandContext.land.land_id] &&
            viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate && (
              <>
                <button onClick={handleNextDate} className="btn btn-icon btn-light ms-2">
                <KTSVG
                path="/media/icons/duotune/arrows/arr027.svg"
                className="svg-icon svg-icon-1x svg-icon-primary"
              />
                </button>
              </>
            )}
        </div>
        <div className="flex-grow-1 d-flex align-items-center mt-3"  style={{ width: "45%" }}>
          <KTSVG
                  path="/media/icons/duotune/files/fil020.svg"
                  className="svg-icon svg-icon-2x svg-icon-primary"
                />
          <span className="me-2">&le;</span>
          <input
            type="number"
            min="0"
            max="100"
            value={cloudinessFilter.toString()}
            onChange={(e) => {
              let value = e.target.value.replace(/^0+(?!$)/, "");              
              value = Number(value);
            
              // Validar que el valor esté entre 0 y 100
              if (value < 0) {
                value = 0;
              } else if (value > 100) {
                value = 100;
              }            
              // Actualizar el estado
              setCloudinessFilter(value);
            }}
            className="form-control me-1"
          />
          %        
        </div>
      </div>
      
      {viewImageContext.activeLands[viewLandContext.land.land_id] && (
        <DatePicker
          open={openedDatePicker}
          value={viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate}
          renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
            const formattedDate = format(day, "yyyy-MM-dd");
            let extraClass = "";

            if (selectedDate != null && selectedDate.getTime() === day.getTime()) {
              extraClass = "active";
            }

            const isDateWithData = viewImageContext.dates.includes(formattedDate);
            const isDateWithCloud = viewImageContext.cloudyDates.includes(formattedDate);
        
            // Define la clase y el mensaje del tooltip basado en el filtro de nubosidad
            const dateClass = isDateWithData
              ? "date-with-data"
              : isDateWithCloud
              ? "date-with-cloud"
              : "";        

              const tooltipMessage = isDateWithData
              ? `Nubosidad menor a ${cloudinessFilter}%`
              : isDateWithCloud
              ? `Nubosidad mayor a ${cloudinessFilter}%`
              : "Sin datos de nubosidad";
        
            return (
              <div className={`${dateClass} ${extraClass}`} title={tooltipMessage}>
                {dayComponent}
              </div>
            );
          }}
          shouldDisableDate={(date) => {
            const formattedDate = format(date, "yyyy-MM-dd");
            return (
              viewImageContext.dates.indexOf(formattedDate) === -1 &&
              viewImageContext.cloudyDates.indexOf(formattedDate) === -1
            );
          }}
          onChange={(date) => viewImageContext.changeDate(viewLandContext.land, date)}
          onOpen={() => setOpenedDatePicker(true)}
          onClose={() => setOpenedDatePicker(false)}
          animateYearScrolling
          TextFieldComponent={() => null}
        />
      )}
    </>
  );
};

export default DateSelector;
