import { createContext, useContext, useRef, useState } from "react";
import { drawState } from "./DrawState";

const DrawV2Context = createContext();

export function useDrawV2() {
    return useContext(DrawV2Context);
};

export const DrawV2Consumer = DrawV2Context.Consumer;

export function DrawV2Provider({ children }) {
    const [ isDrawing, setIsDrawing ] = useState(false);
    const [ polygon, setPolygon ] = useState(false);
    const [ circle, setCircle ] = useState(false);
    const [ file, setFile ] = useState(false);
    const [ point, setPoint ] = useState(false);
    
    const startDrawing = ({ tools, onCreate, onUpdate, onDelete }) => {
        drawState.start({ tools, onCreate, onUpdate, onDelete });
        setIsDrawing(drawState.isDrawing());
        setPolygon(drawState.acceptPolygon())
        setCircle(drawState.acceptCircle());
        setFile(drawState.acceptFile());
    };

    const endDrawing = () => {
        drawState.end();
        setIsDrawing(drawState.isDrawing());
        setPolygon(drawState.acceptPolygon())
        setCircle(drawState.acceptCircle());
        setFile(drawState.acceptFile());
        deleteAllLayers();
    };

    const mode = (mode) => {
        drawState.mode(mode);
    };

    const deleteLayer = (layerId) => {
        drawState.deleteLayer(layerId);
    };

    const deleteAllLayers = () => {
        drawState.deleteAll();
    };

    const getAll = () => {
        return drawState.getAll();
    }
 
    const add = (polygon) => {
        return drawState.add(polygon);
    };

    const get = (featureId) => {
        return drawState.get(featureId);
    };

    const directSelect = (featureId) => {
        drawState.directSelect(featureId);
    };

    const forceCallback = (arg1, arg2) => {
        drawState.forceCallback(arg1, arg2);
    }

    const value = {
        startDrawing,
        endDrawing,
        add,
        get,
        mode,
        deleteLayer,
        deleteAllLayers,
        getAll,
        directSelect,
        forceCallback,
        isDrawing,
        polygon,
        circle,
        file,
        point,
    };

    return (
        <>
            <DrawV2Context.Provider value={value}>
                {children}
            </DrawV2Context.Provider>
        </>
    );
};