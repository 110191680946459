

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../../../../_metronic/elements/Modal";
import { openAlert } from "../../../../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewFieldContext } from "../../ViewFieldContext";
import { DrawTools } from "../../../../Map/Draw/DrawState";
import { useMapLayersV2 } from "../../../../Map/MapLayersV2Context";
import { useDrawV2 } from "../../../../Map/Draw/DrawV2Context";
import { useDrawEndButton } from "../../../../Map/Draw/DrawEndButton/DrawEndButtonContext";
import * as GapService from "../../../services/GapService";

const ModalHeader = () => {
  const { t } = useTranslation();
  return (
    <div className="modal-header p-5">
      <h5 className="modal-title">{t("gaps:create.title")}</h5>
    </div>
  );
};

const ModalBody = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-body p-5">
        <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-5">
          <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
            <h5 className="mb-1">{t("gaps:create.alerts.deleting.title")}</h5>
            <span>{t("gaps:create.alerts.deleting.message")}</span>
          </div>
        </div>
        <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-5">
          <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
            <h5 className="mb-1">{t("gaps:create.alerts.max_size.title")}</h5>
            <span>{t("gaps:create.alerts.max_size.message")}</span>
          </div>
        </div>
      </div>
    </>
  );
};

const ModalFooter = ({ fieldId, save, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="modal-footer">
      <button onClick={() => navigate(`/gis/field/view/${fieldId}`)} disabled={loading == true} type="button" className="btn btn-light">{t("gaps:create.buttons.cancel")}</button>
      <button type="button" onClick={save} className="btn btn-primary">
        {!loading && t("gaps:create.buttons.create")}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            {t("gaps:create.buttons.creating")}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  );
};

const CreateGap = () => {
  const [loading, setLoading] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { field_id } = useParams();
  const { load } = useViewFieldContext();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const drawV2 = useDrawV2();
  const mapLayersV2 = useMapLayersV2();
  const drawEndButton = useDrawEndButton();

  useEffect(() => {
    mapLayersV2.land.unfillByFieldId(field_id);
  }, []);

  useEffect(() => {
    openAlert('info', t("gaps:create.alerts.on_start.title"), t("gaps:create.alerts.on_start.message"));
    drawV2.startDrawing({
      tools: [DrawTools.POLYGON, DrawTools.CIRCLE],
      onCreate: () => {
        drawEndButton.show({
          title: t("gaps:create.alerts.on_start.drawed"),
          onFinish: () => {
            onComplete();
          },
          onCancel: () => {
            navigate(`/gis/field/view/${field_id}`);
          }
        });
      }
    });

    return () => {
      drawV2.endDrawing();
      drawV2.deleteAllLayers();
    };
  }, []);

  useEffect(() => {
    return () => {
      mapLayersV2.land.fillByFieldId(field_id);
    };
  }, []);

  const onComplete = () => {
    const features = drawV2.getAll();
    if (features.features.length < 1) {
      return openAlert('error', t("gaps:create.errors.polygon_required.title"), t("gaps:create.errors.polygon_required.message"));
    }
    setModalOpened(true);
    drawEndButton.hide();
    return;
  };

  const save = async (e) => {
    setLoading(true);
    const features = drawV2.getAll();
    const res = await errorHandler(
      GapService.create(field_id, { polygons: features.features.map(e => e.geometry) })
    );

    if (res.ok) {
      const message = features.features.length == 1 ? t("gaps:create.alerts.success.singular") : t("gaps:create.alerts.success.plural");
      openAlert('success', t("gaps:create.alerts.success.title"), message);
      navigate(`/gis/field/view/${field_id}`);

      mapLayersV2.reloadSource();
      load();
    }

    setLoading(false);
  };

  return (
    <>
      <Modal open={modalOpened}>
        <div className="modal-dialog">
          <div className="modal-content">
            <ModalHeader />
            <ModalBody />
            <ModalFooter save={save} loading={loading} fieldId={field_id} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateGap;