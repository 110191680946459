import { createContext, useContext } from "react";

const GisContext = createContext();

export function useGisContext() {
  return useContext(GisContext);
}

export const GisConsumer = GisContext.Consumer;

export function GisProvider({ children }) {
    const value = {};

    return (
        <>
            <GisContext.Provider value={value}>
                {children}
            </GisContext.Provider>
        </>
    );
};