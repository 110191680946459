import { useMemo, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading } from "../../../../../_metronic/elements/Loading";
import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import RecoverForm from "./RecoverForm";
import * as AuthService from "../../services/AuthService";

const Recover = () => {
    const { t } = useTranslation();
    const { errorHandler } = useErrorHandler();
    const [ loading, setLoading ] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const token = useMemo(() => {
        try {
            return  location.search.split('token=')[1];
        } catch (e) {
            return null;
        }
    }, [location]);

    const validateToken = async () => {
        const res = await errorHandler(AuthService.validateTokenForgotPassword(token), [404, 422]);
        if (!res.ok) {
            openAlert('danger', 'Error', t("auth:recover.error.not_valid_token"));
            navigate('/auth/forgot-password');
            return;
        }
        setLoading(false);
    };

    useEffect(() => {
        if (!token || token == null) {
            openAlert('danger', 'Error', t("auth:recover.error.url_not_valid"))
            navigate('/auth/forgot-password');
            return;
        }

        validateToken();
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <RecoverForm token={token} />
        </>
    );
};

export default Recover;