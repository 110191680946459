import { useTranslation } from "react-i18next";

const Password = ({ loading, formik, getInputClasses }) => {
  const { t } = useTranslation();
  return (
    <div className="fv-row mb-10">
      <label className='form-label fs-6 fw-bolder text-dark'>{t("auth:create_account.input.password.label")}</label>
      <input
        disabled={loading}
        placeholder={t("auth:create_account.input.password.placeholder")}
        type="password"
        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
          "password"
        )}`}
        name="password"
        {...formik.getFieldProps("password")}
      />
      {formik.touched.password && formik.errors.password ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors.password}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Password;