import { useDrawV2 } from "../DrawV2Context";
import { openAlert } from '../../../../../../_metronic/elements/NotificationAlert';
import { DrawTools } from "../DrawState";
import { useMapLayersV2 } from "../../MapLayersV2Context";
import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import * as MediaService from "../../../../gis/Fields/services/MediaService"
import * as turf from '@turf/turf';
import CircleAdd from "./icons/circle_add.png";
import LineAdd from "./icons/line_add.png";
import PointMarker from "./icons/marker-point.svg";
import CloudDownload from "./icons/cloud_upload-24px.svg";
import './DrawControl.style.scss';
import { useTranslation } from "react-i18next";


const DrawControl = () => {
  const draw = useDrawV2();
  const mapLayers = useMapLayersV2();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();

  if (!draw.isDrawing) {
    return (
      <>
      </>
    )
  }

  const onUpdateFile = async (e) => {
    const file = e.currentTarget.files[0];

    if (file.size > (2 * 1024 * 1024)) {
      openAlert('danger', t("lands:draw.errors.title"), t("lands:draw.errors.file_max_size"));
      return;
    }

    const acceptedTypes = [
      'application/zip',
      'application/vnd.google-earth.kml+xml',
      'application/vnd.google-earth.kmz',
      'application/geo+json',
      'application/json'
    ];

    if (acceptedTypes.indexOf(file.type) === -1 && file.type != "") {
      openAlert('danger', t("lands:draw.errors.title"), t("lands:draw.errors.file_type"));
      return
    }

    mapLayers.setLoading(true);

    const form = new FormData();
    form.append('file', file);

    const res = await errorHandler(
      MediaService.uploadMedia(form)
    );

    if (!res.ok) {
      mapLayers.setLoading(false);
      return;
    }

    if (res.data.data.length < 1) {
      openAlert('danger', t("lands:draw.errors.title"), t("lands:draw.errors.file_not_valid"));
      mapLayers.setLoading(false);
      return;
    }

    const polygons = [];
    const addedPolygons = {};
    res.data.data.forEach(polygon => {
      polygons.push(polygon);
      const n = draw.add(polygon);
      addedPolygons[n] = polygon;
    });

    let joinedPolygon = null;

    for (let i = 0; i < polygons.length; i++) {
      const polygon = polygons[i];
      if (joinedPolygon === null) {
        joinedPolygon = polygon;
      } else {
        joinedPolygon = turf.union(joinedPolygon, polygon);
      }
    }

    mapLayers.fitToPolygon(joinedPolygon);
    mapLayers.setLoading(false);

    Object.keys(addedPolygons).forEach(key => {
      draw.forceCallback({
        features: [
          { id: key, ...addedPolygons[key] }
        ]
      });
    })
  }

  return (
    <>
      <div className="map-draw-control">
        <div className="d-flex flex-row flex-column-fluid">
          <div className="d-flex flex-row-fluid flex-center me-5">
            <label>{t("lands:draw.tools")}</label>
          </div>
          <div className="d-flex flex-row-fluid">
            {draw.circle && (
              <button className="btn btn-outline btn-sm btn-outline-dashed btn-outline-dark button-action" size="small" onClick={() => draw.mode(DrawTools.CIRCLE)}>
                <img src={CircleAdd} />
              </button>
            )}

            {draw.polygon && (
              <button className="btn btn-sm btn-outline btn-outline-dashed btn-outline-dark button-action" size="small" onClick={() => draw.mode(DrawTools.POLYGON)}>
                <img src={LineAdd} />
              </button>
            )}

            {draw.point && (
              <button className="btn btn-sm btn-outline btn-outline-dashed btn-outline-dark button-action" onClick={() => draw.mode(DrawTools.POINT)}>
                <img src={PointMarker} />
              </button>
            )}

            {draw.file && (
              <button className="btn btn-sm btn-outline btn-outline-dashed btn-outline-dark button-action">
                <input type="file" className="file-selector" onChange={onUpdateFile} />
                <img src={CloudDownload} />
              </button>
            )}
          </div>
        </div>


      </div>

    </>
  );
};

export default DrawControl;