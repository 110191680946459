import { useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../../../../../_metronic/elements/Modal";
import { openAlert } from "../../../../../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewAmbientContext } from "../AmbientsContext";
import { useMapLegendContext } from '../../Helpers/MapLegend';
import { useViewImageContext } from "../../Images/ViewImageContext";
import * as LandService from "../../../../services/LandService";

const ModalHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="modal-header p-5">
      <h5 className="modal-title">{t("ambient:delete.title")}</h5>
    </div>
  );
};

const ModalFooter = ({ deleteAmbient, loading, setDeleteAmbient }) => {
  const { t } = useTranslation();
  return (
    <div className="modal-footer">
      <button onClick={() => setDeleteAmbient(false)} disabled={loading == true} type="button" className="btn btn-light">
        {t("ambient:delete.buttons.cancel")}
      </button>
      <button onClick={deleteAmbient} type="button" className="btn btn-danger">
        {!loading && t("ambient:delete.buttons.delete")}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            {t("ambient:delete.messages.deleting")}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  );
};

const DeleteAmbient = () => {
  const [loading, setLoading] = useState(false);
  const { land_id } = useParams();
  const { loadAmbientData, loadImageAmbientData, deleteAmbient, setDeleteAmbient, selectedElement } = useViewAmbientContext();
  const viewImageContext = useViewImageContext();
  const mapLegendContext = useMapLegendContext();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();

  const deleteAmbientFunc = async () => {
    setLoading(true);
    let res;
    if (selectedElement === "imageAmbient"){
      res = await errorHandler(LandService.deleteImageAmbient(land_id));  
    }
    else {
      res = await errorHandler(LandService.deleteAmbient(land_id));
    }
    

    if (res && res.ok) {
      loadAmbientData();
      loadImageAmbientData();
      mapLegendContext.deleteAll();
      viewImageContext.closeAllImages();
      setDeleteAmbient(false);
      openAlert('success', t("ambient:delete.messages.success"), '');
    }

    setLoading(false);
  };

  return (
    <>
      <Modal open={deleteAmbient} setOpen={setDeleteAmbient}>
        <div className="modal-dialog">
          <div className="modal-content">
            <ModalHeader />
            <ModalFooter deleteAmbient={deleteAmbientFunc} setDeleteAmbient={setDeleteAmbient} loading={loading} />
          </div>
        </div>
      </Modal>
    </>
  )
};

export default DeleteAmbient;