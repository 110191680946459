import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../_metronic/elements/NotificationAlert";
import ChangePasswordForm from "./ChangePasswordForm";
import * as UserService from "../services/UserService";

const UserPassword = () => {
  const { t } = useTranslation();
  const { errorHandler, setInputError } = useErrorHandler();
  const schema = Yup.object().shape({
    old_password: Yup.string().required(
      t("account:change_password.inputs.old_password.errors.required")
    ),
    new_password: Yup.string().required(
      t("account:change_password.inputs.new_password.errors.required")
    ).test('length', t("account:change_password.inputs.old_password.errors.min"), (e) => {
      if (e) {
        if (e.length >= 8) {
          return true;
        }
      }
  
      return false;
    }).test('length', t("account:change_password.inputs.old_password.errors.max"), (e) => {
      if (e) {
        if (e.length > 32) {
          return false;
        }
      }
  
      return true;
    }),
    repeat_new_password: Yup.string().required(
      t("account:change_password.inputs.repeat_new_password.errors.required")
    ).oneOf(
      [Yup.ref('new_password'), null], t("account:change_password.inputs.repeat_new_password.errors.not_match")
    )
  });
  const formik = useFormik({
    initialValues: {
      'old_password': '',
      'new_password': '',
      'repeat_new_password': ''
    },
    validationSchema: schema,
    onSubmit: async (values, { setFieldError }) => {
      const res = await errorHandler(UserService.updatePassword(values));
      if (res.ok) {
        openAlert('success', t("account:change_password.success.title"), t("account:change_password.success.message"));
        formik.resetForm();
      } else if (res.error_type == 'field_error') {
        setInputError({ response: res.data, setFieldError });
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='form'>
        <div className="card card-custom col-xl-6 col-md-6">
          <div className="card-header">
            <h3 className="card-title">{t("account:change_password.title")}</h3>
          </div>
          <div className="card-body">
            <ChangePasswordForm formik={formik} />
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button disabled={formik.isSubmitting} type="submit" className="btn btn-sm btn-primary">
              {t("account:change_password.buttons.change")}
            </button>
          </div>
        </div>
      </form>
    </>
  )
};

export default UserPassword;