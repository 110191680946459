import { useMemo, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import { format, parse, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";

import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useViewNDVIContext } from "../ViewNDVIContext";

const LandsUsed = ({ landUsed }) => {
  const { t } = useTranslation();
  
  if (landUsed.length < 1) {
    return <></>;
  }

  return (
    <>
      <h4 className="mt-5">{t("reports:ndvi.view.reports.accumulated_per_date.images_used")}</h4>
      {landUsed.map(land => {
        return (
          <div className="d-flex fw-semibold align-items-center">
            <div className="bullet w-8px h-3px rounded-2 bg-success me-3"></div>
            <div className="flex-grow-1 me-4">{land.name}</div>
            <div className="fw-bolder text-gray-700 text-xxl-end">{format(land.imageDate, "dd/LL/yyyy", new Date())}</div>
          </div>
        );
      })}
    </>
  );
}

const ReportDate = ({ date, accumulated }) => {
  const { t } = useTranslation();
  const context = useViewNDVIContext();
  const data = useMemo(() => {
    if (date == null) {
      return;
    }

    const dateData = accumulated.byDate[format(date, "yyyy-LL-dd", new Date())];
    const range = dateData.intervals;
    const categories = [];
    for (var i = 0; i < range.length - 1; i++) {
      categories.push(`${parseFloat(range[i]).toFixed(3)} - ${parseFloat(range[i + 1]).toFixed(3)}`);
    }

    categories.push(`> ${parseFloat(range[range.length - 1]).toFixed(3)}`)

    const chart = {
      options: {
        chart: {
          id: "avg-history"
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          decimalsInFloat: 2,
        },
        colors: [
          function ({ value, seriesIndex, dataPointIndex, w }) {
            if (dataPointIndex == 0) {
              return "#f1416c";
            } else if (dataPointIndex == 1) {
              return "#ffc700";
            } else if (dataPointIndex == 2) {
              return "#7239ea";
            } else if (dataPointIndex == 3) {
              return "#50cd89";
            }
          }
        ]
      },
      series: [
        {
          name: t("reports:ndvi.view.reports.accumulated_per_date.series_hectareas"),
          data: []
        }
      ],
    };
    const landNotUsed = [];
    const landUsed = [];

    const intervals = {}
    dateData.intervals.forEach(interval => {
      intervals[interval] = 0
    })

    for (var z in dateData.lands) {
      const current = dateData.lands[z];
      const land = context.report.lands.filter(e => e.land_id == current.land_id)[0];

      if (!land) {
        continue;
      }

      landUsed.push({
        name: land.field.name + " / " + land.name,
        imageDate: parseISO(current.image_date)
      });

      for (var x in current.interval) {
        intervals[current.interval[x].interval] += current.interval[x].ha;
      }
    }

    Object.keys(intervals).map(key => {
      chart.series[0].data.push(parseFloat(parseFloat(intervals[key]).toFixed(2)));
    });

    return {
      chart,
      landUsed,
      categories
    };
  }, [date, accumulated]);

  if (date == null) {
    return (<></>)
  }

  return (
    <>
      <h4 className="mt-5">{t("reports:ndvi.view.reports.accumulated_per_date.title")}</h4>
      <Chart
        options={data.chart.options}
        series={data.chart.series}
        type="bar"
        width="100%"
      />
      {data.categories.map((cat, index) => {
        return (
          <>
            <div className="d-flex fw-semibold align-items-center">
              <div className={"bullet w-10px h-10px rounded-2 me-3 " + [
                "bg-danger",
                "bg-warning",
                "bg-info",
                "bg-success"
              ][index]}></div>
              <div className="flex-grow-1 me-4">{cat} - {[
                t("reports:ndvi.view.status.regular"),
                t("reports:ndvi.view.status.good"),
                t("reports:ndvi.view.status.very_good"),
                t("reports:ndvi.view.status.superior")
              ][index]}</div>
              <div className="fw-bolder text-gray-700 text-xxl-end">{data.chart.series[0].data[index]} ha</div>
            </div>
          </>
        )
      })}
      <LandsUsed landUsed={data.landUsed} />
    </>
  );
};

const AccumulatedPerDate = () => {
  const [openedDatePicker, setOpenedDatePicker] = useState(false);
  const [date, setDate] = useState(null);
  const context = useViewNDVIContext();
  const accumulated = useMemo(() => {
    const reportDates = context.report.ndvi_aggregated_date;
    let dates = context.report.ndvi_aggregated_date.map(e => parse(e.day, "yyyy-LL-dd", new Date()));
    const availableDates = dates.map(e => format(e, "yyyy-LL-dd"));
    const byDate = {};

    for (var z in reportDates) {
      byDate[reportDates[z].day] = reportDates[z];
    }

    if (!availableDates || availableDates.length < 1) {
      return {
        availableDates: [],
        byDate: {}
      };
    }

    setDate(parse(availableDates[0], "yyyy-LL-dd", new Date()));

    return {
      availableDates,
      byDate
    };
  }, [context.report]);

  return (
    <>
      <DatePicker
        open={openedDatePicker}
        value={date}
        onOpen={() => setOpenedDatePicker(true)}
        onClose={() => setOpenedDatePicker(false)}
        onChange={(x) => setDate(x)}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          if (accumulated.availableDates.indexOf(format(day, "yyyy-LL-dd")) == -1) {
            return dayComponent;
          }

          let extraClass = "";
          if (selectedDate != null && selectedDate.getTime() == day.getTime()) {
            extraClass = "active";
          }
          return <div className={'date-with-data ' + extraClass}>{dayComponent}</div>;
        }}
        shouldDisableDate={(date) => {
          return accumulated.availableDates.indexOf(format(date, "yyyy-LL-dd")) == -1
        }}
        animateYearScrolling
        TextFieldComponent={() => null}
      />
      <a onClick={(e) => {
        e.preventDefault();
        setOpenedDatePicker(true);
      }} href="#" className={`fw-bold text-gray-800 text-hover-primary fs-7 `}>
        <KTSVG
          path="/media/icons/duotune/general/gen014.svg"
          className="svg-icon svg-icon-2x svg-icon-primary me-5"
        />
        {!date || date == null ? "Seleccionar fecha de reporte" : format(
          date, 'dd/MM/yyyy'
        )}
      </a>
      <ReportDate date={date} accumulated={accumulated} />
    </>
  );
};

export default AccumulatedPerDate;