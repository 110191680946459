import { useFormikContext } from "formik";


export const InputText = ({ formik, name, inputProps }) => {
    return (
        <>
            <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                {...inputProps}
                {...formik.getFieldProps(name)}
            />
            {formik.touched[name] && formik.errors[name] && (
                <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors[name]}</div>
                </div>
            )}
        </>
    )
};
