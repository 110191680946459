/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typeahead } from 'react-bootstrap-typeahead';
import { useNavigate, Link, useMatch } from "react-router-dom";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import DrawControl from '../Map/Draw/DrawControl';
import { useSearchContext } from './SearchContext';
import { useViewImageContext } from '../Fields/ViewField/ViewLand/Images/ViewImageContext';
import { useMapLegendContext } from '../Fields/ViewField/ViewLand/Helpers/MapLegend';
import { useTranslation } from 'react-i18next';

export const Search = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { options } = useSearchContext();

    return (
        <Typeahead
            id="search_field"
            labelKey="label"
            maxResults={200000}
            options={options}
            onChange={(e) => {
                if (e.length == 1) {
                    const [ land ] = e;
                    navigate(`/gis/field/view/${land.field_id}/land/view/${land.id}`)
                }
            }}
            placeholder={t("common:toolbar.search_placeholder")}
        />
    );
};

export const CreateFieldButton = () => {
    const navigate = useNavigate();
    let isCreatingLand = useMatch("/gis/field/view/:field_id/land/new");
    let isCreatingGap = useMatch("/gis/field/view/:field_id/gap/new");
    isCreatingLand = isCreatingLand || isCreatingGap;
    
    return (
        <button disabled={isCreatingLand} className='btn btn-sm btn-primary cursor-pointer' id='kt_toolbar_primary_button' onClick={() => navigate("/gis/field/new")}>
            Crear campo
        </button>
    );
};

export const Reports = () => {
    const { t } = useTranslation();
    return (
        <Link to="/gis/reports" className='btn btn-sm btn-light-primary cursor-pointer me-3'>{t("common:toolbar.buttons.reports")}</Link>
    );
};

export const ListFieldButton = () => {
    const { t } = useTranslation();
    return (
        <Link  to="/gis/field/list" className='btn btn-sm btn-light-primary cursor-pointer'>{t("common:toolbar.buttons.fields")}</Link>
    );
};

export const RemoveImages = () => {
    const imageContext = useViewImageContext();
    const mapLegendContext = useMapLegendContext();
    const { t } = useTranslation();

    if (!imageContext.hasImageActive) {
        return (<></>);
    }

    return (
        <>
            <button onClick={() => {
                mapLegendContext.deleteAll();
                imageContext.closeAllImages();
            }} className='btn btn-sm btn-light-danger cursor-pointer'>{t("common:toolbar.buttons.remove_images")}</button>
            <div className="bullet h-35px w-1px mx-3"></div>
        </>
    );
};

export const Content = () => {
    return (
        <>
            <RemoveImages />
            <Search />
            <div className="bullet h-35px w-1px mx-3"></div>
            <Reports />
            <ListFieldButton />
        </>
    );
};

export const Toolbar = () => {
    return (
        <>
            <div className='toolbar' id='kt_toolbar'>
                <div
                    id='kt_toolbar_container'
                    className={'container-fluid d-flex flex-stack'}
                >
                    <div className="d-flex flex-row m-5" style={{width: '100%'}}>
                        <div className="d-flex flex-column flex-row-fluid">
                            <div className="d-flex flex-row flex-column-fluid flex-end">
                                <div className="d-flex flex-row-auto flex-center">
                                    <DrawControl />
                                </div>
                                <div className="d-flex flex-row-fluid flex-center">
                                </div>
                                <div className="d-flex flex-row-auto flex-center">
                                    <Content />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id='kt_toolbar_container search-mobile'
                    className={''}
                >
                    <div className='d-flex search-mobile'>
                        <div className="d-flex flex-column flex-row-fluid">
                            <div className="d-flex flex-row flex-column-fluid flex-end">
                                <Search />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};