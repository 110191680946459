/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useTranslation } from "react-i18next";

import InviteUser from "../InviteUser";

export const Toolbar = ({ onInvite }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation()

  return (
    <>
      <div className='toolbar' id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className={'container-fluid d-flex flex-stack'}
        >
          <div className="d-flex flex-row m-5" style={{ width: '100%' }}>
            <div className="d-flex flex-column flex-row-fluid">
              <div className="d-flex flex-row flex-column-fluid flex-end">
                <div className="d-flex flex-row-auto flex-center">

                </div>
                <div className="d-flex flex-row-fluid flex-center">
                </div>
                <div className="d-flex flex-row-auto flex-center">
                  <button onClick={() => setOpen(true)} className='btn btn-sm btn-primary cursor-pointer' id='kt_toolbar_primary_button'>
                    {t("reseller:toolbar.buttons.invite")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InviteUser onInvite={onInvite} open={open} setOpen={setOpen} />
    </>
  );
};