
export const DrawEndButtonStatus = {
    HIDDEN: "hidden",
    SHOWING: "showing"
};

export class DrawEndButtonState {
    constructor() {
        this._status = DrawEndButtonStatus.HIDDEN;
        this._title = null;
        this._cancelButton = null;
        this._onFinish = null;
        this._onCancel = null;
        this._buttonText = null;
    }

    show({ title, onFinish, onCancel, buttonText }) {
        this._status = DrawEndButtonStatus.SHOWING;
        this._title = title;
        this._onFinish = onFinish;
        this._onCancel = onCancel;
        this._buttonText = buttonText;
    }

    hide() {
        this._status = DrawEndButtonStatus.HIDDEN;
        this._title = null;
        this._onFinish = null;
        this._onCancel = null;
        this._buttonText = null;
    }

    emit(event) {
        if (event == "finish" && this._onFinish != null) {
            this._onFinish();
        } else if (event == "cancel" && this._onCancel != null) {
            this._onCancel();
        }
    }

    isShowing() {
        return this._status == DrawEndButtonStatus.SHOWING;
    }

    getTitle() {
        return this._title;
    }

    getButtonText() {
        return this._buttonText;
    }
};

export const drawEndButtonState = new DrawEndButtonState();