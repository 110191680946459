import { useMemo } from "react";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { useDrawEndButton } from "./DrawEndButtonContext";
import { drawEndButtonState } from "./DrawEndButtonState";
import './DrawEndButton.style.scss';

const Buttons = () => {
    const drawEndButton = useDrawEndButton();
    const { title, buttonText } = useMemo(() =>{
        return {
            title: drawEndButtonState.getTitle(),
            buttonText: drawEndButtonState.getButtonText() || "Terminar selección"
        }
    }, [drawEndButton]);

    return (
        <>
        <div className="displayable notice draw-end-button-container d-flex bg-light-success rounded border-success border border-dashed p-6">
            <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-3x svg-icon-success me-4" />
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-semibold">
                    <h4 className="text-gray-900 fw-bold">{title}</h4>
                    <button onClick={() => drawEndButton.emit('finish')} className="btn btn-sm btn-primary me-2">{buttonText}</button>
                    <button onClick={() => drawEndButton.emit('cancel')} className="btn btn-sm btn-light">Cancelar</button>
                </div>
            </div>
        </div>
        </>
    );
};

const DrawEndButton = () => {
    const drawEndButton = useDrawEndButton();
    if (!drawEndButton.isShowing) {
        return (<></>);
    }

    return (
        <>
            <div className="d-flex flex-column flex-row-auto">
              <div className="d-flex flex-column-auto mb-4">
                  <Buttons/>
              </div>
          </div>
        </>
    );
};

export default DrawEndButton;