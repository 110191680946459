import { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { LoadingCard } from "../../../../_metronic/elements/Loading";
import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import * as UserService from "../services/UserService";

const ViewAccountInformation = () => {
    const { t } = useTranslation();
    const [ userData, setUserData ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const { errorHandler } = useErrorHandler();
    const load = useCallback(async () => {
        setLoading(true);
        const res = await errorHandler(UserService.me());
        if (res.ok) { 
            setUserData(res.data.data);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        load();
    }, []);

    if (loading || userData == null) { 
        return <LoadingCard />
    }

    return (
        <>
            <div className="card card-custom col-xl-6 col-md-6">
                <div className="card-header">
                    <h3 className="card-title">{t("account:view.title")}</h3>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-6">
                            <p><strong>{t("account:view.fields.name")}</strong> {userData.first_name}</p>
                        </div>
                        <div className="col-6">
                            <p><strong>{t("account:view.fields.last_name")}</strong> {userData.last_name}</p>
                        </div>
                        <div className="col-6">
                            <p><strong>{t("account:view.fields.email")}</strong> {userData.email}</p>
                        </div>
                        <div className="col-6">
                            <p><strong>{t("account:view.fields.company")}</strong> {userData.company.name}</p>
                        </div>
                        <div className="col-6">
                            <p><strong>{t("account:view.fields.state")}</strong> {userData.province}</p>
                        </div>

                        <div className="col-6">
                            <p><strong>{t("account:view.fields.address")}</strong> {userData.address}</p>
                        </div>

                        <div className="col-6">
                            <p><strong>{t("account:view.fields.city")}</strong> {userData.city}</p>
                        </div>
                        <div className="col-6">
                            <p><strong>{t("account:view.fields.zip")}</strong> {userData.zip_code}</p>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <Link to="/user/update" className="btn btn-primary">{t("account:view.update_button")}</Link>
                </div>
            </div>
        </>
    );
};

export default ViewAccountInformation;