import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import * as Yup from 'yup'

import { Modal } from "../../../../../../_metronic/elements/Modal";
import { InputText } from "../../../../../../_metronic/elements/Inputs";
import { useViewLandContext } from "../ViewLand/ViewLandContext";
import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../../_metronic/elements/NotificationAlert";
import { useMapLayersV2 } from "../../../Map/MapLayersV2Context"
import { useDrawV2 } from "../../../Map/Draw/DrawV2Context";
import { useDrawEndButton } from "../../../Map/Draw/DrawEndButton/DrawEndButtonContext";
import * as LandService from "../../services/LandService";
import { useSearchContext } from "../../../Toolbar/SearchContext";
import { useViewFieldContext } from "../ViewFieldContext";
import { useTranslation } from "react-i18next";

const ModalHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="modal-header p-5">
      <h5 className="modal-title">{t("lands:update.title")}</h5>
    </div>
  );
};

const ModalBody = ({ hasAmbient, polygonUpdated, updatePolygon, formik, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-body p-5">
      <div className="row">
        <div className='col-lg-12 fv-row'>
          <InputText disabled={loading} formik={formik} name="name" inputProps={{
            placeholder: 'Nombre'
          }} />
        </div>
        <div className="col-lg-12">
          {polygonUpdated && (
            <button onClick={updatePolygon} type="button" className="btn btn-light-success mt-5" style={{ width: '100%' }}>{t("lands:update.buttons.draw")}</button>
          )}
          {!polygonUpdated && (
            <button onClick={updatePolygon} type="button" className="btn btn-light-primary mt-5" style={{ width: '100%' }}>{t("lands:update.buttons.draw")}</button>
          )}
          {hasAmbient && <div className="alert alert-primary d-flex align-items-center p-5 mt-5">
            <div className="d-flex flex-column">
              <h5 className="mb-1">{t("lands:update.ambient_alert.title")}</h5>
              <span>{t("lands:update.ambient_alert.message")}</span>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

const ModalFooter = ({ loading, cancel }) => {
  const { t } = useTranslation();
  return (
    <div className="modal-footer">
      <button disabled={loading == true} type="button" className="btn btn-light" onClick={() => {
        cancel();
      }}>{t("lands:update.buttons.cancel")}</button>
      <button type="sibmit" className="btn btn-primary">
        {!loading && t("lands:update.buttons.update")}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            {t("lands:update.updating")}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  );
};

const UpdateLand = ({ open, setOpen }) => {
  const [polygonUpdated, setPolygonUpdated] = useState(false)
  const { land, setLand } = useViewLandContext();
  const [loading, setLoading] = useState(false);
  const [polygon, setPolygon] = useState(false);
  const { field_id, land_id } = useParams()
  const { errorHandler } = useErrorHandler();
  const [hasAmbient, setHasAmbient] = useState(false);
  const { t } = useTranslation();
  const viewFieldContext = useViewFieldContext();
  const search = useSearchContext();
  const drawV2 = useDrawV2();
  const drawEndButton = useDrawEndButton();
  const mapLayers = useMapLayersV2();
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    name: Yup.string().min(
      1, t("lands:update.form.input.name.errors.min")
    ).max(
      32, t("lands:update.form.input.name.errors.max")
    ).required(
      t("lands:update.form.input.name.errors.required")
    ),
  });


  const formik = useFormik({
    initialValues: { name: land.name, },
    validationSchema: schema,
    onSubmit: async (values) => {
      setLoading(true);
      const extra = {};

      if (polygon) {
        extra["polygon"] = polygon.geometry;
      }

      const res = await errorHandler(LandService.updateV2(
        land.land_id, { name: values.name, ...extra }
      ));

      if (res.ok) {
        mapLayers.reloadSource();
        cancel();
        search.load();
        viewFieldContext.loadLands(field_id);
        setLand(res.data);
        openAlert('success', t("lands:update.success.title"), t("lands:update.success.message"));
        navigate(`/gis/field/view/${field_id}/land/view/${land_id}`);
      }

      setLoading(false);
    }
  });

  useEffect(() => {
    errorHandler(LandService.getAmbient(land.land_id), [404]).then(res => {
      if (res.ok) {
        setHasAmbient(true);
      }
    })

    setPolygonUpdated(false);
  }, [land]);


  const updatePolygon = () => {
    // First hide the previous polygon and then fit
    setPolygonUpdated(true);
    mapLayers.land.hide(land.land_id);

    let polygonId;
    if (!polygon) {
      polygonId = drawV2.add(land.polygons || land.polygon);
      setPolygon({
        id: polygonId,
        geometry: land.polygons
      });
      mapLayers.fit(land.land_id);
    } else {
      polygonId = polygon.id;
    }

    drawV2.directSelect(polygonId);
    setOpen(false);
    openAlert('info', t("lands:update.draw.title"), t("lands:update.draw.message"));

    drawEndButton.show({
      title: t("lands:update.draw.drawed"),
      buttonText: t("lands:update.draw.end_button"),
      onFinish: () => {
        const newGeom = drawV2.get(polygonId).geometry;
        setOpen(true);
        setPolygon({ id: polygonId, geometry: newGeom });
      },
      onCancel: () => {
        setOpen(true);
        setPolygon(null);
        mapLayers.land.unfill(land.land_id);
        drawV2.endDrawing();
        drawEndButton.hide();
      }
    });
  };

  const onSetOpen = () => {
    return;
  }

  const cancel = () => {
    setPolygonUpdated(false);
    setPolygon(null);
    mapLayers.land.unfill(land.land_id);
    drawEndButton.hide();
    drawV2.endDrawing();
    drawV2.deleteAllLayers();

    formik.resetForm();
    return setOpen(false);
  };

  useEffect(() => {
    if (polygon == false) {
      setPolygonUpdated(false);
      return;
    }
    return;
  }, [polygon]);

  return (
    <>
      <Modal open={open} setOpen={onSetOpen}>
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit} className='form'>
              <ModalHeader />
              <ModalBody hasAmbient={hasAmbient} polygonUpdated={polygonUpdated} updatePolygon={updatePolygon} formik={formik} loading={loading} />
              <ModalFooter cancel={cancel} loading={loading} />
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateLand;