import { useTranslation } from "react-i18next";

import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useViewFieldContext } from "../../ViewFieldContext";
import { useMapLayersV2 } from "../../../../Map/MapLayersV2Context";
import * as GapService from "../../../services/GapService";
import "./Gap.style.scss";

const Gap = ({ gap }) => {
  const { t } = useTranslation();
  const field = useViewFieldContext();
  const mapLayers = useMapLayersV2();

  const deleteGap = async () => {
    if (window.confirm(t("gaps:delete.message"))) {
      const res = await GapService.deleteGap(gap.gap_id);

      if (res.ok) {
        field.load();
        mapLayers.reloadSource();
      }
    }
  }

  return (
    <div onClick={() => mapLayers.fit(gap.gap_id)} className="d-flex align-items-center p-5 gap-item">
      <span className="bullet bullet-vertical h-40px bg-success me-5"></span>
      <div className="flex-grow-1">
        <span className="text-gray-800 text-hover-primary fw-bold fs-6">{gap.name}</span>
        <span className="text-muted fw-semibold d-block">{parseFloat(gap.ha).toFixed(2)} ha</span>
      </div>
      <span onClick={deleteGap} className="btn btn-sm btn-light-danger fs-8 fw-bold"><KTSVG path="/media/icons/duotune/arrows/arr011.svg" /></span>
    </div>
  );
};

export default Gap;