import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Modal } from "../../../../../../_metronic/elements/Modal";
import { useViewFieldContext } from "../ViewFieldContext";
import { useViewLandContext } from "../ViewLand/ViewLandContext";
import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../../_metronic/elements/NotificationAlert";
import { useMapLayersV2 } from "../../../Map/MapLayersV2Context";
import * as LandService from "../../services/LandService";
import { useSearchContext } from "../../../Toolbar/SearchContext";
import { useTranslation } from "react-i18next";

const ModalHeader = () => {
    const { land } = useViewLandContext();
    const { t } = useTranslation();

    return (
        <div className="modal-header p-5">
            <h5 className="modal-title">{t("lands:delete.title", { name: land.name })}</h5>
        </div>
    );
};

const ModalFooter = ({ deleteLand, loading, setOpen }) => {
    const { t } = useTranslation();
    return (
        <div className="modal-footer">
            <button disabled={loading == true} type="button" className="btn btn-light" onClick={() => setOpen(false)}>{t("lands:delete.buttons.cancel")}</button>
            <button onClick={deleteLand} type="button" className="btn btn-danger">
                {!loading && t("lands:delete.buttons.delete")}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    {t("lands:delete.messages.deleting")}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </button>
        </div>
    );
};

const DeleteLand = ({ open, setOpen }) => {
    const [ loading, setLoading ] = useState(false);
    const { field_id, land_id } = useParams();
    const { load } = useViewFieldContext();
    const { errorHandler } = useErrorHandler();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const search = useSearchContext();
    const mapLayers = useMapLayersV2();

    const deleteLand = async () => {
        setLoading(true);
        const res = await errorHandler(LandService.deleteLand(land_id));
        
        mapLayers.reloadSource();
        if (res.ok) {
            load();
            search.load();
            navigate(`/gis/field/view/${field_id}/`);
            openAlert('success', t("lands:delete.messages.success"), '');
        }

        setLoading(false);
    };

    return (
        <>
            <Modal open={open} setOpen={setOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader />
                        <ModalFooter deleteLand={deleteLand} setOpen={setOpen} loading={loading} />
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default DeleteLand;