export const InputSelect = ({ formik, defaultOption, name, inputProps, options }) => {
    return (
        <>
            <select className='form-select form-select-solid' {...inputProps} {...formik.getFieldProps(name)}>
                {defaultOption && (
                    <option>{defaultOption}</option>
                )}
                {options.map(opt => {
                    return (
                        <option key={name+opt.key+''+opt.value} value={opt.value}>{opt.key}</option>
                    )
                })}
            </select>
            {formik.touched[name] && formik.errors[name] && (
                <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors[name]}</div>
                </div>
            )}
        </>
    )
};
