class ViewImageState {

  constructor() {
    this.lands = {};
    this.landTiff = {}
  }

  updateImageType(landId, imageType) {
    this._updateLand(landId, (n) => {
      n["imageType"] = imageType;
      return n
    });
  }

  updateImageDate(landId, date) {
    this._updateLand(landId, (n) => {
      n["selectedDate"] = date;
      return n
    });
  }

  getLand(landId) {
    return this.lands[landId];
  }

  _updateLand(landId, f) {
    if (!this.lands[landId]) {
      this.lands[landId] = {
        imageType: null,
        selectedDate: null
      };
    }

    this.lands[landId] = f(this.lands[landId]);
  }

  addLandTiff(landId, image) {
    this.landTiff[landId] = image;
  }

  getLandTiff(landId) {
    return this.landTiff[landId];
  }
}

export const viewImageState = new ViewImageState();