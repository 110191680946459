import {FC, useEffect} from 'react'
import {useLocation, useMatch } from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'
import { useMediaQuery } from 'react-responsive';

const Content: FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  const location = useLocation();
  const requireDrawer = useMediaQuery({
      query: '(max-width: 700px)'
  });

  useEffect(() => {
      DrawerComponent.hideAll();
  }, [location]);

  useEffect(() => {
      if (!requireDrawer) {
        DrawerComponent.hideAll();
        
        const elements = document.getElementsByClassName("drawer-overlay");
        while(elements.length > 0) {
            elements[0].parentNode?.removeChild(elements[0]);
        }
      }

      
      DrawerComponent.reinitialization();
  }, [requireDrawer]);

  const paths = [
    useMatch('/gis/field/list'),
    useMatch('/gis/field/view/:field_id'),
    useMatch('/gis/field/view/:field_id/land/view/:land_id'),
    useMatch('/gis/field/view/:field_id/land/view/:land_id/ambients'),
    useMatch('/gis/field/view/:field_id/land/view/:land_id/ambients/layers'),
  ]

  useEffect(() => {
    let itsTrue = false;
    for (var i = 0; i < paths.length; i++) {
      if (paths[i] != null) {
        itsTrue = true;
      }
    }

    if (!itsTrue) {
      return;
    }
    setTimeout(() => {
      const right = DrawerComponent.getInstance('kt_right_container');
      if (right) {
        right.show();
      }
    }, 1000)
    
  }, [paths]);

  return (
    <div id='kt_content_container' style={{
      position: 'relative',
      height: 'auto',
      overflow: 'auto'
    }} className={"p-0 " + clsx(classes.contentContainer.join(' '))}>
      {children}
    </div>
  )
}

export {Content}
