import { useTranslation } from "react-i18next";

export const Loading = ({ loadingText }) => {
    const { t } = useTranslation();
    return (
        <div className="d-flex align-items-center">
            <strong className="me-5">{!loadingText ? t("common:loading") : loadingText}</strong>
            <span style={{width: "3rem", height: "3rem" }} className="spinner-border spinner-border-sm align-middle ml-auto"></span>
        </div>
    );
}

export const LoadingCard = ({ loadingText, className }) => {
    return (
        <div className={`card shadow-sm ${className}`}>
            <div className="card-body">
                <Loading loadingText={loadingText} />
            </div>
        </div>
    );
};