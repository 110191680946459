import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { usePrescription } from "./PrescriptionContext";
import "./PrescriptionForm.scss";

const sortOrder = [
  'six',
  'five',
  'map',
  'ap',
  'pp',
  'bp',
  'mbp'
];

const getColor = (key, numClasses) => {
  return {
    2: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(26,150,65)'
    },
    3: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(255,255,192)',
      'pp': 'rgb(26,150,65)'
    },
    4: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(254,201,129)',
      'pp': 'rgb(196,230,135)',
      'ap': 'rgb(26,150,65)'
    },
    5: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(253,174,97)',
      'pp': 'rgb(255,255,192)',
      'ap': 'rgb(166,217,106)',
      'map': 'rgb(26,150,65)'
    },
    6: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(246,144,83)',
      'pp': 'rgb(255,223,154)',
      'ap': 'rgb(220,240,158)',
      'map': 'rgb(138,204,98)',
      'five': 'rgb(26,150,65)'
    },
    7: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(241,124,74)',
      'pp': 'rgb(254,201,129)',
      'ap': 'rgb(255,255,192)',
      'map': 'rgb(196,230,135)',
      'five': 'rgb(119,195,92)',
      'six': 'rgb(26,150,65)'
    }
  }[numClasses][key];
};

const PrescriptionForm = ({ prescription, numClasses }) => {
  const { columns, setColumns, prod, setProd } = usePrescription();
  const { t } = useTranslation();
  const totalHa = useMemo(() => {
    let total = 0;
    Object.keys(prescription).forEach(key => { total += prescription[key]['ha'] });
    return total;
  }, [prescription]);

  const filtered = useMemo(() => {
    return sortOrder.filter(key => prescription[key]['ha'] > 0);
  }, [prescription]);

  const handleProdChange = (k, value) => {
    setProd(e => {
      return { ...e, [k]: value };
    });
  };

  const handleColumnChange = (row, col, value) => {
    setColumns(e => {
      const total = { ...e };
      total[col][row] = value;
      return total;
    });
  }


  return (
    <>
      <div className="row">
        <div className="col-2">
          <span className="text-title">Mayor Productividad</span>
        </div>

        <div className="col-2">
          <span className="text-title">ha</span>
        </div>

        <div className="col-2">
          <span className="text-title">%</span>
        </div>

        <div className="col-2">
          <input
            type="text"
            className="input-base"
            value={prod['prod1']} onChange={e => handleProdChange('prod1', e.target.value)}
            placeholder="Prod1"
          />
        </div>

        <div className="col-2">
          <input
            type="text"
            className="input-base"
            value={prod['prod2']} onChange={e => handleProdChange('prod2', e.target.value)}
            placeholder="Prod1"
          />
        </div>

        <div className="col-2">
          <input
            type="text"
            className="input-base"
            value={prod['prod3']} onChange={e => handleProdChange('prod3', e.target.value)}
            placeholder="Prod1"
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-2"></div>
        <div className="col-2"></div>
        <div className="col-2"></div>
        <div className="col-2">
          <select className="input-base">
            <option value="10">{t("prescription:creation.units.seed_ha")}</option>
            <option value="20">{t("prescription:creation.units.seed_m2")}</option>
            <option value="30">{t("prescription:creation.units.seed_m")}</option>
            <option value="40">{t("prescription:creation.units.kg_ha")}</option>
            <option value="50">{t("prescription:creation.units.lt_ha")}</option>
          </select>
        </div>
        <div className="col-2">
          <select className="input-base">
            <option value="40">{t("prescription:creation.units.kg_ha")}</option>
            <option value="50">{t("prescription:creation.units.lt_ha")}</option>
          </select>
        </div>
        <div className="col-2">
          <select className="input-base">
            <option value="50">{t("prescription:creation.units.lt_ha")}</option>
            <option value="40">{t("prescription:creation.units.kg_ha")}</option>
          </select>
        </div>
      </div>
      {filtered.map(key => {
        return (
          <>
            <div className="row mt-2">
              <div className="col-2">
                <div className="box-cls" style={{ backgroundColor: getColor(key, numClasses) }}></div>
              </div>
              <div className="col-2">
                {parseFloat(prescription[key]['ha']).toFixed(2)}
              </div>
              <div className="col-2">
                {parseFloat((prescription[key]['ha'] * 100) / totalHa).toFixed(2)}
              </div>
              <div className="col-2">
                <input className="input-base" type="number" value={columns[0][prescription[key]['cls']]} onChange={e => handleColumnChange(prescription[key]['cls'], 0, e.target.value)} />
              </div>
              <div className="col-2">
                <input className="input-base" type="number" value={columns[1][prescription[key]['cls']]} onChange={e => handleColumnChange(prescription[key]['cls'], 1, e.target.value)} />
              </div>
              <div className="col-2">
                <input className="input-base" type="number" value={columns[2][prescription[key]['cls']]} onChange={e => handleColumnChange(prescription[key]['cls'], 2, e.target.value)} />
              </div>
            </div>
          </>
        )
      })}
      <div className="row">
        <div className="col-2">
          <span className="text-title">Menor Productividad</span>
        </div>
      </div>
    </>
  );
};

export default PrescriptionForm;