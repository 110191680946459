import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";

import { InputText } from "../../../../_metronic/elements/Inputs";
import { Modal, useModalContext } from "../../../../_metronic/elements/Modal";
import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../_metronic/elements/NotificationAlert";
import * as ResellerService from "../services/ResellerService";

const ModalHeader = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{t("reseller:invite_user.title")}</h5>
      </div>
    </>
  );
};

const ModalFooter = ({ loading }) => {
  const { t } = useTranslation();
  const modal = useModalContext();

  return (
    <div className="modal-footer">
      <button type="button" className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("reseller:invite_user.buttons.cancel")}
      </button>
      <button disabled={loading} type="submit" className="btn btn-primary">
        {t("reseller:invite_user.buttons.invite")}
      </button>
    </div>
  );
};

const ModalBody = ({ formik, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-body p-5">
      <div className="row">
        <div className='col-lg-12 fv-row mt-5'>
          <InputText disabled={loading} formik={formik} name="first_name" inputProps={{
            placeholder: t("reseller:invite_user.form.first_name.placeholder")
          }} />
        </div>
      </div>
      <div className="row">
        <div className='col-lg-12 fv-row mt-5'>
          <InputText disabled={loading} formik={formik} name="last_name" inputProps={{
            placeholder: t("reseller:invite_user.form.last_name.placeholder")
          }} />
        </div>
      </div>
      <div className="row">
        <div className='col-lg-12 fv-row mt-5'>
          <InputText disabled={loading} formik={formik} name="email" inputProps={{
            placeholder: t("reseller:invite_user.form.email.placeholder")
          }} />
        </div>
      </div>
    </div>
  );
};

const InviteUser = ({ open, setOpen, onInvite }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { errorHandler } = useErrorHandler();
  const schema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, t("reseller:invite_user.form.first_name.errors.min"))
      .max(50, t("reseller:invite_user.form.first_name.errors.max"))
      .required(t("reseller:invite_user.form.first_name.errors.required")),
    last_name: Yup.string()
      .min(3, t("reseller:invite_user.form.last_name.errors.min"))
      .max(50, t("reseller:invite_user.form.last_name.errors.max"))
      .required(t("reseller:invite_user.form.last_name.errors.required")),
    email: Yup.string()
      .email(t("reseller:invite_user.form.email.errors.email"))
      .min(3, t("reseller:invite_user.form.email.errors.min"))
      .max(50, t("reseller:invite_user.form.email.errors.max"))
      .required(t("reseller:invite_user.form.email.errors.required"))
  });
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: ''
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      errorHandler(ResellerService.inviteUser(values)).then(res => {
        if (res.ok) {
          openAlert('success', t("reseller:invite_user.success.title"), t("reseller:invite_user.success.message"));
          resetForm();
          onInvite()
          setOpen(false);
        }

        setLoading(false);
      });
    },
  })

  return (
    <Modal open={open} setOpen={setOpen}>
      <div className="modal-dialog">
        <div className="modal-content">
          <form onSubmit={formik.handleSubmit} className='form'>
            <ModalHeader />
            <ModalBody formik={formik} />
            <ModalFooter loading={loading} />
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default InviteUser;