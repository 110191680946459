import { useState, useEffect } from "react";
import { useViewAmbientContext, ViewAmbientProvider } from "../Ambients/AmbientsContext";
import { LoadingCard } from "../../../../../../../_metronic/elements/Loading";
import { useViewLandContext } from "../ViewLandContext";
import { openAlert } from "../../../../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import * as LandService from "../../../services/LandService";
import { useTranslation } from "react-i18next";

const LayersChild = () => {
  const [layer, setLayer] = useState(null);
  const [format, setFormat] = useState(null);
  const [loading, setLoading] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();
  const viewAmbientContext = useViewAmbientContext();
  const viewLandContext = useViewLandContext();
  const hasAmbients = !(viewAmbientContext.ambientData == null || viewAmbientContext.ambientData.status == 0);
  const hasImageAmbients = !(viewAmbientContext.imageAmbientData == null || viewAmbientContext.imageAmbientData.status == 0);
  const download = async () => {
    if (layer == null || format == null) {
      return;
    }

    setLoading(true);
    let fileName, response;

    if (layer == 'layer') {
      fileName = viewLandContext.land.name;
      response = await errorHandler((LandService.downloadLayer(viewLandContext.land.land_id, {
        driver: format
      })));
    } else {
      if (layer == "ambient"){
        fileName = `${t("layers:ambient_filename_prefix")}${viewLandContext.land.field.name} - ${viewLandContext.land.name}`
        response = await errorHandler(LandService.downloadAmbient(viewLandContext.land.land_id, {
          driver: format
        }));
      } else if (layer === "image_ambient"){
        fileName = `${t("layers:image_ambient_filename_prefix")}${viewLandContext.land.field.name} - ${viewLandContext.land.name}`
        response = await errorHandler(LandService.downloadImageAmbient(viewLandContext.land.land_id, {
          driver: format
        }));
      }
      
    }

    if (response.ok) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', `${fileName}.zip`);
      document.body.appendChild(link);
      link.click();

      openAlert('success', t("layers:success.title"), t("layers:success.message"));
      setLayer(null);
      setFormat(null);
    }

    setLoading(false);
  };

  useEffect(() => {
    setLayer(null);
    setFormat(null);
  }, [viewLandContext.land]);

  if (viewAmbientContext.loading) {
    return <LoadingCard />
  }

  return (
    <>
      <div className="p-8">


        <div className="mb-5">
          <label for="layer" className="form-label">{t("layers:layer_type.label")}</label>
          <select disabled={loading} onChange={(e) => setLayer(e.target.value)} value={layer} className="form-select" aria-label="Layer">
            <option value={null} selected={null == layer}>{t("layers:layer_type.placeholder")}</option>
            <option value="layer" selected={"layer" == layer}>{t("layers:layer_type.land")}</option>
            <option value="ambient" selected={"ambient" == layer} disabled={!hasAmbients}>{t("layers:layer_type.ambient")}{!hasAmbients ? " "+ t("layers:layer_type.missing_ambient") : ''}</option>
            <option value="image_ambient" selected={"image_ambient" == layer} disabled={!hasImageAmbients}>{t("layers:layer_type.image_ambient")}{!hasImageAmbients ? " "+ t("layers:layer_type.missing_ambient") : ''}</option>
          </select>
        </div>
        <div className="mb-5">
          <label for="format" className="form-label">{t("layers:format.label")}</label>
          <select disabled={loading} onChange={(e) => setFormat(e.target.value)} value={format} className="form-select" aria-label="Format">
            <option value={null} selected={null == format}>{t("layers:format.placeholder")}</option>
            <option value="KML" selected={"KML" == format}>KML</option>
            <option value="ESRI Shapefile" selected={"ESRI Shapefile" == format}>Shape</option>
          </select>
        </div>
        <div>
          <button onClick={download} type="button" disabled={loading} className="btn btn-primary" data-kt-indicator={loading ? 'on' : 'off'}>
            <span className="indicator-label">
              {t("layers:buttons.download")}
            </span>
            <span className="indicator-progress">
              {t("layers:buttons.downloading")} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

const Layers = () => {
  return (
    <ViewAmbientProvider>
      <LayersChild />
    </ViewAmbientProvider>
  )
}

export default Layers;