import { useEffect } from "react";
import { useMatch, useNavigate, useParams } from "react-router-dom";
import { format, parse } from "date-fns";
import { useTranslation } from "react-i18next";

import { Loading } from "../../../../../../_metronic/elements/Loading";
import { Modal, useModalContext } from "../../../../../../_metronic/elements/Modal";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { useViewNDVIContext, ViewNDVIProvider } from "./ViewNDVIContext";
import { openAlert } from "../../../../../../_metronic/elements/NotificationAlert";
import ModalBody from "./ModalBody";

const ModalHeader = () => {
  const { t } = useTranslation();
  const context = useViewNDVIContext();
  const modal = useModalContext();
  const name = context.report.name
  const from_date = format(parse(context.report.from_date, "yyyy-LL-dd", new Date()), "dd/LL/yyyy");
  const to_date = format(parse(context.report.to_date, "yyyy-LL-dd", new Date()), "dd/LL/yyyy")

  return (
    <div className="modal-header p-5">
      <h5 className="modal-title">{t("reports:ndvi.view.title", { name, from_date, to_date })}</h5>
      <div className="btn btn-sm btn-active-light-danger ms-2" onClick={() => context.setDeleteReport(true)} aria-label="Close">
        {t("reports:ndvi.delete.button")}
      </div>
      <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => modal.hideModal()} aria-label="Close">
        <KTSVG
          path="/media/icons/duotune/arrows/arr061.svg"
          className="svg-icon svg-icon-2x"
        />
      </div>
    </div>
  );
};

const ModalContent = () => {
  return (
    <>
      <ModalHeader />
      <ModalBody />
    </>
  );
};

const ViewNDVIModal = () => {
  const urlMatches = useMatch("/gis/reports/ndvi/view/:id")
  const { loading, load, report } = useViewNDVIContext();
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    load(id);
  }, [id]);

  return (
    <>
      <Modal open={urlMatches} setOpen={() => navigate("/gis/reports/ndvi")}>
        <div className="modal-dialog modal-lg">
          <div className={"modal-content " + (loading ? "p-5" : "")}>
            {loading && <Loading />}
            {(!loading && report) && <ModalContent />}
          </div>
        </div>
      </Modal>
    </>
  );
};

const ViewNDVI = () => {

  return (
    <ViewNDVIProvider>
      <ViewNDVIModal />
    </ViewNDVIProvider>
  )
}

export default ViewNDVI;