import { useTranslation } from "react-i18next";
import { KTSVG } from "../../../../../../../../_metronic/helpers";
import { useViewAmbientContext } from "../AmbientsContext";

const NoAmbient = () => {
  const { t } = useTranslation();
  const { createTriggered, create, setAmbientDialog } = useViewAmbientContext();

  return (
    <>
      <div onClick={() => create('default', {})} className={`d-flex align-items-center div-type-selector rounded p-5 ` + (createTriggered != null && createTriggered != 'default' ? 'disabled' : '') + ' ' + (createTriggered == 'default' ? 'active' : '')}>
        <span className="svg-icon svg-icon-warning me-5">
          <KTSVG path="/media/icons/duotune/general/gen055.svg" />
        </span>
        <div className="flex-grow-1 me-2">
          <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("ambient:view.buttons.create")}</span>
          {createTriggered == 'default' && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
      <div onClick={() => setAmbientDialog(true)} className={`d-flex align-items-center div-type-selector rounded p-5 ` + (createTriggered != null && createTriggered != 'personalized' ? 'disabled' : '')}>
        <span className="svg-icon svg-icon-warning me-5">
          <KTSVG path="/media/icons/duotune/general/gen055.svg" />
        </span>
        <div className="flex-grow-1 me-2">
          <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("ambient:view.buttons.create_personalized")}</span>
          {createTriggered == 'personalized' && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
    </>
  );
};

export default NoAmbient;
