import { useState, useEffect, useCallback } from "react";

import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { LoadingCard } from "../../../../../_metronic/elements/Loading";
import * as UserService from "../../services/UserService";
import UpdateAccountInformationForm from "./UpdateAccountInformationForm";

const UpdateAccountInformation = () => {
    const [ userData, setUserData ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const { errorHandler } = useErrorHandler();
    const load = useCallback(async () => {
        setLoading(true);
        const res = await errorHandler(UserService.me());
        if (res.ok) { 
            setUserData(res.data.data);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        load();
    }, []);

    if (loading || userData == null) { 
        return <LoadingCard />
    }

    return (
        <>
            <UpdateAccountInformationForm userData={userData} />
        </>
    );
};

export default UpdateAccountInformation;