import { Store } from 'react-notifications-component';

export const openAlert = (type, title, message) => {
    Store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: "top-right",
        animationIn: ["animate_animated", "animate_fadeIn"],
        animationOut: ["animate_animated", "animate_fadeOut"],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
};
