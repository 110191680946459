import {Suspense} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import { ReactNotifications } from 'react-notifications-component'
import { PaymentProvider } from './routing/Payment'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import PaymentModal from './routing/PaymentModal';
import 'react-notifications-component/dist/theme.css';

const App = () => {
  Sentry.init({
    dsn: "https://e1458d9e261144d9ba132dfd0366cdbf@o1405037.ingest.sentry.io/4504044308660224",
    integrations: [new BrowserTracing()],
  
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <PaymentProvider>
            <PaymentModal />
            <AuthInit>
              <ReactNotifications />
              <Outlet />
              <MasterInit />
            </AuthInit>
          </PaymentProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
