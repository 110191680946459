import { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import * as ReportService from "../../services/ReportService";

const ViewNDVIContext = createContext();

export function useViewNDVIContext() {
  return useContext(ViewNDVIContext);
}

export const ViewNDVIConsumer = ViewNDVIContext.Consumer;

export function ViewNDVIProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [report, setReport] = useState(null);
  const [deleteReport, setDeleteReport] = useState(false);
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();
  const load = (id) => {
    setLoading(true);
    setReport(null);
    errorHandler(ReportService.getNdviReport(id)).then(res => {
      if (res.ok) {
        setReport(res.data);
      } else {
        navigate("/gis/reports/ndvi");
      }

      setLoading(false);
    });
  };

  const value = {
    loading,
    load,
    report,
    deleteReport, setDeleteReport
  };

  return (
    <>
      <ViewNDVIContext.Provider value={value}>
        {children}
      </ViewNDVIContext.Provider>
    </>
  )
};