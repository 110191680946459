
import axios from "axios";


const ALL_GAP_PATH = "/api/v1/fields/gap";
const CREATE_GAP_PATH = "/api/v2/fields/:id/gaps";
const DELETE_GAP_PATH = "/api/v2/fields/gaps/:gap_id";

export const create = (field_id, data) => {
    return axios.post(CREATE_GAP_PATH.replace(':id', field_id), data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const list = (field_id) => {
    return axios.get("/api/v2/fields/:id/gaps".replace(':id', field_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const deleteGap = (gap_id) => {
    return axios.delete(DELETE_GAP_PATH.replace(':gap_id', gap_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true
            };
        }
    });
};

export const all = () => {
    return axios.get(ALL_GAP_PATH).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};