import { Routes, Route } from "react-router-dom";

import { Content } from "../../../_metronic/layout/components/Content";
import Toolbar from "./Toolbar";
import UserPassword from "./UserPassword";
import AccountInformation from "./AccountInformation";
import PaymentInformation from "./PaymentInformation";

const UserModule = () => {

    return (
        <>
        {/*<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>*/}
        <div id='kt_content' className='p-0 content d-flex flex-column flex-column-fluid'>
            <Toolbar />
            <div className='post d-flex flex-column-fluid p-5' id='kt_post'>
                <Content>
                    <Routes>
                        <Route path="/password" element={<UserPassword />} />
                        <Route path="/payment" element={<PaymentInformation />} />
                        <Route path="/*" element={<AccountInformation />} />
                    </Routes>
                </Content>
            </div>
        </div>
        </>
    );
};

export { UserModule };