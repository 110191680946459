/* eslint import/no-webpack-loader-syntax: off */
import { createContext, useContext, useRef } from "react";
import mapboxgl from '!mapbox-gl';
import * as turf from '@turf/turf';

import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import * as LandService from "../services/LandService";
import * as GapService from "../services/GapService";
import "./MapLayersContext.style.scss";

const MapLayersContext = createContext();

export function useMapLayers() {
  return useContext(MapLayersContext);
}

export const MapLayersConsumer = MapLayersContext.Consumer;

export function MapLayersProvider({ children }) {
    const { errorHandler } = useErrorHandler();
    const activeImages = useRef({});
    const mapInstance = useRef(null);
    const gisPolygons = useRef({});
    const fieldPolygons = useRef({});
    const fieldLands = useRef({});
    const noAgroZones = useRef([])
    const markers = useRef({});

    const addMarker = ({ name, container, lng, lat, popup }) => {
        let marker = new mapboxgl.Marker(container)
            .setLngLat([lng, lat]);

        if (popup) {
            marker = marker.setPopup(popup)
        }

        marker = marker.addTo(mapInstance.current);
        markers.current[name] = marker;
    };

    const getLandIds = (landId) => {
        return [
            `gis_${landId}`,
            `gis_${landId}_layer`,
            `gis_${landId}_line`,
            `gis_${landId}_label`
        ];
    };

    const getFieldId = (fieldId) => {
        return [
            `field_${fieldId}`,
            `field_${fieldId}_layer`,
            `field_${fieldId}_line`,
            `field_${fieldId}_label`
        ];
    };

    const addFieldPolygon = ({ field, label, land }) => {
        const [ sourceName, polygonId, lineId, labelId ] = getFieldId(field.field_id);
        fieldPolygons.current[field.field_id] = { name: field.name, lands: [] };

        if (!field.polygons) {
            return;
        }

        fieldPolygons.current[field.field_id] = {
            source: sourceName,
            field_id: field.field_id,
            polygon: field.polygons,
            name: field.name,
            line: {
                show: true,
                id: lineId
            },
            layer: {
                show: true,
                id: polygonId
            },
            properties: {
                //...color,
                name: field.name
            },
            ...(label ? {
                label: {
                    show: true,
                    id: labelId
                }
            } : {})
        };
    };

    const addGisPolygon = ({ land, label }) => {
        const [ sourceName, polygonId, lineId, labelId ] = getLandIds(land.land_id);

        if (!fieldLands.current[land.field.field_id]) {
            addFieldPolygon({ field: land.field, land })
            fieldLands.current[land.field.field_id] = [];
        }

        if (!fieldPolygons.current[land.field.field_id]["lands"]) {
            fieldPolygons.current[land.field.field_id]["lands"] = [];
        }
        
        fieldPolygons.current[land.field.field_id]["lands"].push(land);
        fieldLands.current[land.field.field_id].push(land.land_id);
        gisPolygons.current[land.land_id] = {
            source: sourceName,
            field_id: land.field.field_id,
            polygon: land.polygons,
            line: {
                show: true,
                id: lineId
            },
            layer: {
                show: true,
                id: polygonId
            },
            properties: {
                //...color,
                name: land.name
            },
            ...(label ? {
                label: {
                    show: true,
                    id: labelId
                }
            } : {})
        };

        if (label) {
            const [ lng, lat] = turf.centroid(land.polygons).geometry.coordinates;
            var el = document.createElement('div');
            el.setAttribute("id", labelId);
            el.textContent = land.name;
            el.className = 'label-gis';
            addMarker({
                name: labelId, 
                container: el, 
                lng: lng, 
                lat: lat
            });
        }
    };

    const deleteLandPopup = (landId) => {
        const labelId = getLandIds(landId)[3];
        markers.current[labelId].remove();
    };

    const deleteLandFromSource = (landId) => {
        const source = mapInstance.current.getSource('lands');
        const data = source._data;
        data.features = data.features.filter(e => e.properties.land_id != landId);
        source.setData(data);
    };


    const addLandToSource = (land) => {
        const color = { color: "#1982e5", opacity: 0.5, border_color: '#1982e5' };
        const feature = {
            'type': 'Feature',
            'geometry': land.polygons,
            'properties': {
                ...color,
                'name': land.name,
                'field_id': land.field.field_id,
                'land_id': land.land_id
            }
        };
        const source = mapInstance.current.getSource('lands');
        const data = source._data;
        data.features.push(feature)
        source.setData(data);
    };

    const addFieldToSource = (field) => {
        const feature = {
                'type': 'Feature',
                'geometry': field.polygons,
                'properties': {
                    'border_color': '#ff5722',
                    'name': field.name,
                    'field_id': field.field_id
                }
        };
        const source = mapInstance.current.getSource('fields');
        const data = source._data;
        data.features.push(feature)
        source.setData(data);
    }

    const addFieldLabel = (field) => {
        const [lng, lat] = turf.centroid(field.polygons).geometry.coordinates;
        var el = document.createElement('div');
        el.textContent = field.name;
        el.className = 'label-fields';

        addMarker({
            name: `field-${field.field_id}-label`, 
            container: el, 
            lng: lng, 
            lat: lat
        });
    }

    const deleteFieldPopup = (fieldId) => {
        const id = `field-${fieldId}-label`;
        markers.current[id].remove();
    };

    const addLandPopup = ({ land }) => {
        const labelId = getLandIds(land.land_id)[3];
        const [ lng, lat] = turf.centroid(land.polygons).geometry.coordinates;
        var el = document.createElement('div');
        el.setAttribute("id", labelId);
        el.textContent = land.name;
        el.className = 'label-gis';
        addMarker({
            name: labelId, 
            container: el, 
            lng: lng, 
            lat: lat
        });
    }
 
    const addGisToMap = (lands) => {
        const m = mapInstance.current;
        const color = { color: "#1982e5", opacity: 0.5, line_opacity: 1, border_color: '#1982e5' };
        
        const features = lands.map(land => {
            return {
                'type': 'Feature',
                'geometry': land.polygons,
                'properties': {
                    ...color,
                    'name': land.name,
                    'field_id': land.field.field_id,
                    'land_id': land.land_id
                }
            };
        })

        /* Add source */
        m.addSource("lands", {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': features
            }
        });

        /* Add layer */
        m.addLayer({
            'id': "land",
            'source': "lands",
            'type': 'fill',
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'fill-color': ['get', 'color'],
                'fill-opacity': ['get', 'opacity']
            }
        });

        m.addLayer({
            'id': "land_lines",
            'type': 'line',
            'source': "lands",
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'line-color': ['get', 'border_color'],
                'line-width': 4,
                'line-opacity': ['get', 'line_opacity']
            }
        });  
    };

    const plotFields = (fields) => {
        const m = mapInstance.current;
        const features = fields.filter(e => e.polygons).map(e => {
            return {
                'type': 'Feature',
                'geometry': e.polygons,
                'properties': {
                    'border_color': '#ff5722',
                    'name': e.name,
                    'field_id': e.field_id
                }
            };
        })
        /* Add source */
        m.addSource('fields', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': features
            }
        });

        m.addLayer({
            'id': 'fields_line',
            'type': 'line',
            'source': 'fields',
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                
                'line-color': ['get', 'border_color'],
                'line-width': 4
            }
        });
    }

    const fitBounds = (polygons) => {
        const total = {
            type: 'FeatureCollection',
            features: polygons.map(e => {
                return {
                    type: 'Feature',
                    properties: {},
                    geometry: e
                };
            })
        }
        
        if (mapInstance.current == null) {
            return setTimeout(() => fitBounds(polygons), 200);
        }

        if (total.features.length < 1) {
            return;
        }

        mapInstance.current.fitBounds(turf.bbox(total));
    };

    const plotFieldLabels = (fields) => {
        Object.keys(fields).forEach(key => {
            const total = [];
            
            if (fields[key]['polygons'] && !Array.isArray(fields[key]['polygons'])){
                fields[key]['polygons'] = [fields[key]['polygons']]
            } else if (fields[key]["lands"]) {
                fields[key]["polygons"] = fields[key]["lands"].map(e => e.polygons);
            } else if (!fields[key]['polygon']) {
                for (var z in fields[key]['gis']) {
                    total.push(gisPolygons.current[fields[key]['gis'][z]]['polygon']);
                }

                fields[key]['polygons'] = total;
            }  else {
                fields[key]['polygons'] = [fields[key]['polygon']]
            }
        });
       
        const totalPoints = [];
        Object.keys(fields).forEach(k => {
            if (fields[k]['polygons'].length < 1) {
                return;
            }

            const total = {
                type: 'FeatureCollection',
                features: fields[k].polygons.map(e => {
                    const feature = {
                        type: 'Feature',
                        properties: {},
                        geometry: e
                    };
                    totalPoints.push(e);
                    return feature;
                })
            };

            if (total.features.length < 1) {
                return;
            }
            
            const [lng, lat] = turf.centroid(total).geometry.coordinates;
            var el = document.createElement('div');
            el.textContent = fields[k].name;
            el.className = 'label-fields';

            addMarker({
                name: `field-${k}-label`, 
                container: el, 
                lng: lng, 
                lat: lat
            });
        });
        
        fitBounds(totalPoints);
    };

    const handleZoom = (zoom) =>  {
        const changeStyle = (cls, display) => {
            const el = document.getElementsByClassName(cls);
            for (var i = 0; i < el.length; i++) {
                if (el[i].hasAttribute('ignore')) {
                    continue;
                }
    
                el[i].style.display = display;
            }
        };
    

        if (zoom < 11) {
            changeStyle('label-gis', 'none');
            changeStyle('label-fields', 'block');
        } else {
            changeStyle('label-gis', 'block');
            changeStyle('label-fields', 'none');
        }

        if (zoom > 14) {
            changeStyle('label-gap', 'block');
        } else {
            changeStyle('label-gap', 'none');
        }
    };

    const reloadAllFromField = (field_id) => {
        errorHandler(LandService.all()).then(res => {
            if (res.ok) {
                for (var z in res.data.data) {
                    const land = res.data.data[z];
                    if (land.field.field_id == field_id) {
                        addLandToSource(land);
                        addGisPolygon({
                            land,
                            label: true
                        });
                    }
                }
            }
        });
    };

    const initMapLayers = (map) => {
        mapInstance.current = map;

        map.on('zoom', function () {
            const zoom = map.getZoom();
            handleZoom(zoom);
        });

        errorHandler(LandService.all()).then(res => {
            if (res.ok) {
                const fields = {}
                for (var z in res.data.data) {
                    const land = res.data.data[z];
                    fields[land.field.field_id] = land.field;
                    addGisPolygon({
                        land,
                        label: true
                    });
                }
                
                plotFields(Object.keys(fields).map(k => fields[k]));
                addGisToMap(res.data.data);
                plotFieldLabels(Object.assign({}, fieldPolygons.current));
                loadNoAgroZone();
            }
        });
    };

    const addNoAgroZoneToMap = (gaps) => {
        const m = mapInstance.current;
        const color = { color: "#ff4569", opacity: 0, border_color: '#4caf50' };
        
        const features = gaps.map(gap => {
            return {
                'type': 'Feature',
                'geometry': gap.polygons,
                'properties': {
                    ...color,
                    'name': gap.name,
                    'field_id': gap.field_id,
                }
            };
        })
        
        /* Add source */
        m.addSource("gaps", {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': features
            }
        });

        m.addLayer({
            'id': "gap_lines",
            'type': 'line',
            'source': "gaps",
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'line-opacity': 0.4,
                'line-color': ['get', 'border_color'],
                'line-width': 4
            }
        });  
    };

    const plotNoAgroLabels = (gap) => {
        const [lng, lat] = turf.centroid(gap.polygons).geometry.coordinates;
        var el = document.createElement('div');
        el.textContent = gap.name;
        el.className = 'label-gap';

        addMarker({
            name: `gap-${gap.gap_id}-label`, 
            container: el, 
            lng: lng, 
            lat: lat
        });
    }

    const loadNoAgroZone = () => {
        try {
            deleteLayer("gap_lines");
            deleteSource("gaps");
        } catch (e) {
            
        }

        function removeElementsByClass(className){
            const elements = document.getElementsByClassName(className);
            while(elements.length > 0){
                elements[0].parentNode.removeChild(elements[0]);
            }
        }
        removeElementsByClass("label-gap")
        errorHandler(GapService.all()).then(res => {
            if (res.ok) {
                const gaps = res.data.data;
                noAgroZones.current = gaps;
                addNoAgroZoneToMap(gaps);
                gaps.forEach(plotNoAgroLabels);
            }
        })
    }

    const deleteSource = (id) => {
        try {
            mapInstance.current.removeSource(id);
        } catch (ex) {}
    };

    const deleteLayer = (id) => {
        try {
            mapInstance.current.removeLayer(id);
        } catch (exc) {}
    };

    const addImage = ({ layerName, imageUrl, landId, polygon }) => {
        if(!activeImages.current[layerName]) {
            activeImages.current[layerName] = [];
        }

        activeImages.current[layerName].push({
            type: 'image',
            landId,
            layerName,
            polygon,
            imageUrl
        });


        const map = mapInstance.current;
        let bbox = turf.bboxPolygon(turf.bbox(turf.polygon(polygon.coordinates))).geometry.coordinates[0];
        
        if (bbox.length != 4) {
            bbox.splice(bbox.length - 1, 1);
        }

        bbox = bbox.reverse();

        if (map.getStyle().layers.filter(x => x['id'] == layerName).length >= 1) {
            deleteLayer(layerName);
            deleteSource(layerName);
        } 
       
        map.addSource(layerName, {
            type: 'image',
            url: imageUrl,
            coordinates: bbox
        });

        map.addLayer({
            id: layerName,
            source: layerName,
            type: 'raster',
            paint: { 'raster-opacity': 1 }
        });

        // Reorder draw layers
        const layersList = map.getStyle().layers;
        for (var z in layersList) {
            if (layersList[z]['id'].includes("draw")) {
                map.moveLayer(layersList[z]['id']);
            }
        }
    };

    // Delete all images/markers, etc from layer
    const deleteAllFromLayer = (layer) => {
        if (!activeImages.current[layer]) {
            return;
        }
        
        for (var z in activeImages.current[layer]) {
            const c = activeImages.current[layer][z];
            if (c.type == 'image') {
                deleteLayer(c.layerName);
                deleteSource(c.layerName);
            } else if (c.type == 'geojson') {
                for (var l in c['layers']) {
                    deleteLayer(c['layers'][l]['id']);
                }
                deleteSource(c.sourceName)
            } else if (c.type == 'marker') {
                //deleteMarker(c.name);
            } else if (c.type == 'popup') {
                c.popup.remove();   
            }
        }
        /*
        if (['evi', 'ndvi', 'visible'].indexOf(layer) !== -1 && (isLayerActive('ambient') || isLayerActive('anomaly'))) {
            handleAmbientOpacity(true);
            handleAnomalyOpacity(true);
        }*/

        delete activeImages.current[layer];
    };

    const addGeoJSON = ({ layer, landId, sourceName, geojson, layers }) => {
        const map = mapInstance.current;
        if(!activeImages.current[layer]) {
            activeImages.current[layer] = [];
        }

        map.addSource(sourceName, {
            'type': 'geojson',
            'data': geojson
        });

        for (var z in layers) {
            map.addLayer({
                'id': layers[z]['id'],
                'source': sourceName,
                ...layers[z]['property']
            });
        }
        
        activeImages.current[layer].push({
            type: 'geojson',
            landId,
            geojson,
            sourceName,
            layers
        });
    };

    const addPopup = ({ layer, landId, name, html, lng, lat, options }) => {
        const popup = new mapboxgl.Popup({ ...options })
                .setLngLat([lng, lat])
                .setHTML(html)
                .addTo(mapInstance.current);

        activeImages.current[layer].push({
            type: 'popup',
            landId,
            name,
            popup,
            lng,
            lat
        });
    };

    const hideFillLand = (landId, fieldId) => {
        if (mapInstance.current == null) { return }
        const source = mapInstance.current.getSource('lands');

        if (source == null || source == undefined ){ return setTimeout(() => hideFillLand(landId), 100) }

        const data = source._data;
        data.features = data.features.map(e => {
            let match = false;
            if (landId && e.properties.land_id == landId) {
                match = true;
            } else if (fieldId && e.properties.field_id == fieldId) {
                match = true;
            }

            e['properties'].opacity = match ? 0 : 0.5;
            e['properties'].line_opacity = 1;
            return e;
        });
        source.setData(data);
    };

    const hideLand = (landId, fieldId) => {
        if (mapInstance.current == null) { return }
        const source = mapInstance.current.getSource('lands');

        if (source == null || source == undefined ){ return setTimeout(() => hideFillLand(landId), 100) }

        const data = source._data;
        data.features = data.features.map(e => {
            let match = false;
            if (landId && e.properties.land_id == landId) {
                match = true;
            } else if (fieldId && e.properties.field_id == fieldId) {
                match = true;
            }

            e['properties'].opacity = match ? 0 : 0.5;
            e['properties'].line_opacity = match ? 0 : 1;
            return e;
        });
        source.setData(data);
    };

    const fillAllLands = () => {
        if (mapInstance.current == null) { return }
        const source = mapInstance.current.getSource('lands');

        if (source == null || source == undefined ){ return setTimeout(() => fillAllLands(), 100) }

        const data = source._data;
        data.features = data.features.map(e => {
            e['properties'].opacity = 0.5;
            e['properties'].line_opacity = 1;
            return e;
        });
        source.setData(data);
    };

    const value = {
        addLandToSource,
        addFieldToSource,
        addFieldLabel,
        addPopup,
        addGisPolygon,
        addFieldPolygon,
        addLandPopup,
        fitBounds,
        initMapLayers,
        addImage,
        addGeoJSON,
        deleteAllFromLayer,
        fillAllLands,
        hideFillLand,
        hideLand,
        deleteLandPopup,
        deleteLandFromSource,
        deleteFieldPopup,
        plotFieldLabels,
        addFieldPolygon,
        reloadAllFromField,
        loadNoAgroZone,
        mapInstance
    };

    return (
        <>
            <MapLayersContext.Provider value={value}>
                {children}
            </MapLayersContext.Provider>
        </>
    )
};