/* eslint import/no-webpack-loader-syntax: off */
import { useRef, useEffect, useState } from "react";
import { format } from 'date-fns';
import axios from "axios";
import mapboxgl from '!mapbox-gl';

import { useErrorHandler } from "../../../../../../../../../_metronic/helpers/ErrorHandler";
import { useMapLayersV2 } from "../../../../../../Map/MapLayersV2Context";
import { viewImageState } from "../../ViewImageState";
import GeoTIFF from "geotiff";


const PopupEvent = () => {
    const mapLayers = useMapLayersV2();
    const { errorHandler } = useErrorHandler();

    // Events
    const loadPopup = (map, land, e) => {
        const { lat, lng } = e.lngLat;

        const image = viewImageState.getLandTiff(land)
       
        const bbox = image.getBoundingBox();
        const pixelWidth = image.getWidth();
        const pixelHeight = image.getHeight();
        const bboxWidth = bbox[ 2 ] - bbox[ 0 ];
        const bboxHeight = bbox[ 3 ] - bbox[ 1 ];
        const widthPct = ( lng - bbox[ 0 ] ) / bboxWidth;
        const heightPct = ( lat - bbox[ 1 ] ) / bboxHeight;
        const xPx = Math.floor( pixelWidth * widthPct );
        const yPx = Math.floor( pixelHeight * ( 1 - heightPct ) );

        const window = [ xPx, yPx, xPx + 1, yPx + 1 ];
        image.readRasters( {window} ).then(res => {
            new mapboxgl.Popup()
                .setLngLat([lng, lat])
                .setHTML(parseFloat(res[0][0]).toFixed(2))
                .addTo(map);
        });
    };

    useEffect(() => {
        mapLayers.on('click', (map) => { 
            return (e) => { 
                const features = map.queryRenderedFeatures(e.point);
                if (features.length >= 1 && features[0].properties.resource_type == "land") {
                    const imageLand = viewImageState.getLand(features[0].properties.id);

                    if (!imageLand) {
                        return;
                    }

                    if (["evi", "ndvi"].indexOf(imageLand.imageType) === -1) {
                        return; 
                    }

                    const layer = {"evi": "evi-standard", "ndvi": "ndvi-standard"}[imageLand.imageType];
                    loadPopup(map, features[0].properties.id, e);
                }
            };
        });
    }, []);

    return (
        <>
        </>
    );
}

export default PopupEvent;
