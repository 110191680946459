import { FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import { GisModule } from "../../app/modules/gis";
import { UserModule } from "../../app/modules/user";
import { ResellerModule } from '../modules/reseller/Reseller';
import TopBarProgress from 'react-topbar-progress-indicator'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/gis' />} />
        {/* Pages */}
        <Route path={
          'gis/*'
        } element={
          <SuspensedView>
            <GisModule />
          </SuspensedView>
        } />
        <Route path={
          'reseller/*'
        } element={
          <SuspensedView>
            <ResellerModule />
          </SuspensedView>
        } />
        <Route path={
          'user/*'
        } element={
          <SuspensedView>
            <UserModule />
          </SuspensedView>
        } />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
