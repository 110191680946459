import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";

import { useViewImageContext } from "../../Images/ViewImageContext";
import { useViewLandContext } from "../../ViewLandContext";
import "./CommonMapLegend.style.scss";
import { useMapLegendContext } from "./MapLegendContext";

const CommonMapLegend = ({ headers }) => {
    const { t } = useTranslation();
    const mapLegendContext = useMapLegendContext();
    const viewImageContext = useViewImageContext();
    const viewLandContext = useViewLandContext();
    const classes = [
        'int1',
        'int2',
        'int3',
        'int4'
    ];
    const intervals = useMemo(() => {
        return JSON.parse(headers['x-index-range']).reverse();
    }, [headers]);

    return (
        <>
            <div className="card shadow-sm mb-5 displayable right-card-data">
                <div className="card-header collapsible">
                    <h3 className="card-title cursor-pointer" data-bs-toggle="collapse" data-bs-target="#kt_image_legend">
                        {t("images:legend.title")}
                    </h3>
                    <div className="card-toolbar">
                        <span style={{marginRight: '5px'}}>{t("images:legend.enhance")}</span> <Switch disabled={viewImageContext.loading} onChange={() => mapLegendContext.handleEnhanced(viewLandContext.land.land_id)} checked={mapLegendContext.enhanced[viewLandContext.land.land_id]}/>
                    </div>
                </div>
                <div id="kt_image_legend" className="collapse show">
                    <div className="card-body">
                        <div className="row legend-row legend-top-column">
                            <div className="col-6 fw-bold fs-7">{t("images:legend.index")}</div>
                            <div className="col-3 fw-bold fs-7">ha</div>
                            <div className="col-3 fw-bold fs-7">%</div>
                        </div>
                        {intervals.map((e, index) => {
                            return (
                                <div className="row legend-row">
                                    <div className="col-12" style={{paddingRight: '0px'}}>
                                        <div className={`box-square ${classes[index]}`}></div>
                                    </div>
                                    <div className="col-6">{e['cluster']}</div>
                                    <div className="col-3">{parseFloat(e['ha']).toFixed(2)}</div>
                                    <div className="col-3">{parseFloat(e['percentage']).toFixed(2)}%</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>

        </>
    );
};

export default CommonMapLegend;