/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useAuth } from '../../../../app/modules/auth'

const HeaderUserMenu = () => {
  const { currentUser, logout } = useAuth();
  const { t } = useTranslation();

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <div className="symbol-label fs-2 fw-bold bg-primary text-inverse-primary">
              {currentUser.fullname[0]}
            </div>
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser.fullname}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      <div className='menu-item px-5 my-1'>
        <Link to='/user' className='menu-link px-5'>
          {t("account:user_menu.account_config")}
        </Link>
      </div>
      {currentUser.reseller && <div className='menu-item px-5 my-1'>
        <Link to='/reseller' className='menu-link px-5'>
          {t("account:user_menu.consultant_panel")}
        </Link>
      </div>}

      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          {t("account:user_menu.logout")}
        </a>
      </div>
    </div>
  )
}

export { HeaderUserMenu }
