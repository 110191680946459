import { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { useMapLayersV2 } from "../../Map/MapLayersV2Context";
import * as LandService from "../services/LandService";
import * as FieldService from '../services/FieldService';
import * as GapService from "../services/GapService";

const ViewFieldContext = createContext();

export function useViewFieldContext() {
  return useContext(ViewFieldContext);
}

export const ViewFieldConsumer = ViewFieldContext.Consumer;

export function ViewFieldProvider({ children }) {
    const [ loading, setLoading ] = useState(true);
    const [ field, setField ] = useState(null);
    const [ fieldId, setFieldId ] = useState(null);

    const [ loadingLands, setLoadingLands ] = useState(true);
    const [ lands, setLands ] = useState([]);

    const [ loadingGaps, setLoadingGaps ] = useState(true);
    const [ gaps, setGaps ] = useState([]);

    const mapLayers = useMapLayersV2();
    const { errorHandler } = useErrorHandler();
    const load = useCallback((callback) => {
        setLoading(true);
        mapLayers.fit(fieldId);
        errorHandler(FieldService.get(fieldId)).then(res => {
            if (res.ok) {
                if (callback) {
                    callback(res.data);
                }
                setField(res.data);
                loadLands(fieldId);
                loadGaps(fieldId);
            }
            setLoading(false);
        });
    }, [fieldId]);

    const sortLandsByName = (fieldsArray) => {
        return fieldsArray.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
    
            return nameA.localeCompare(nameB, 'en', { numeric: true });
        });
    };

    const loadLands = (fieldId) => {
        setLoadingLands(true);
        errorHandler(LandService.list(fieldId)).then(res => {
            setLands(sortLandsByName(res.data.lands));
            setLoadingLands(false);
        });
    }

    const loadGaps = (fieldId) => {
        setLoadingGaps(true);
        errorHandler(GapService.list(fieldId)).then(res => {
            setGaps(res.data.gaps);
            setLoadingGaps(false);
        });
    }
    
    useEffect(() => {
        if (fieldId != null) {
            load();
        }
    }, [fieldId]);

    const value = {
        field,
        setField,
        setFieldId,
        loading,
        load,
        lands,
        loadingLands,
        gaps,
        loadingGaps,

        loadLands
    };

    return (
        <>
            <ViewFieldContext.Provider value={value}>
                {children}
            </ViewFieldContext.Provider>
        </>
    );
};