import { useState, useMemo } from 'react';

import { Link } from 'react-router-dom';

import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';

import { Email, Password } from "./Inputs";
import { useAuth } from '../../core/Auth';
import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import Action from "./Action";
import * as AuthService from '../../services/AuthService';


const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const canCreate = useMemo(() => {
    function ValidateIPaddress(ipaddress) {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return (true)
      }
      return (false)
    }
    const hostname = window.location.hostname;
    if (ValidateIPaddress(hostname)) {
      return true;
    } else {
      let hostnames = hostname.split(".");
      let data = hostnames[hostnames.length - 2] + "." + hostnames[hostnames.length - 1];

      if (data == "specific-ag.co") {
        data = "natalseeds.app";
      }

      if (data == "natalseeds.app") {
        return false;
      }
      return true;
    }

  }, []);

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("auth:login_box.input.email.errors.wrong_format"))
      .min(3, t("auth:login_box.input.email.errors.min"))
      .max(50, t("auth:login_box.input.email.errors.max"))
      .required(t("auth:login_box.input.email.errors.required")),
    password: Yup.string()
      .min(3, t("auth:login_box.input.password.errors.min"))
      .max(50, t("auth:login_box.input.password.errors.max"))
      .required(t("auth:login_box.input.password.errors.required")),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res = await errorHandler(AuthService.login(values.email, values.password));
        if (res.ok) {
          saveAuth({
            api_token: res.data.data.access_token
          })
          const res2 = await errorHandler(AuthService.me(res.data.data.access_token));
          setCurrentUser(res2.data.data)
        } else {
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        //setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{t("auth:login_box.title")}</h1>
        {canCreate && <div className='text-gray-400 fw-bold fs-4'>
          {t("auth:login_box.or")}{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            {t("auth:login_box.create_account_action")}
          </Link>
        </div>}
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <Email formik={formik} />
      <Password formik={formik} />
      <Action formik={formik} loading={loading} />
    </form>
  )
}
