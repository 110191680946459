import { Routes, Route, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading } from "../../../../../_metronic/elements/Loading";
import CreateNDVIReport from "./CreateNDVIReport";
import ViewNDVI from "./ViewNDVI/ViewNDVI";
import Report from "./Report";
import { NDVIReportProvider, useNDVIReportContext } from "./NDVIReportContext";

const NDVIReport = () => {
  const { t } = useTranslation();
  const { loading, reports } = useNDVIReportContext();

  return (
    <>
      <div className="d-flex flex-column-auto mt-4">
        <div className="card shadow-sm view-land-container displayable right-card-data">
          <div className="card-header collapsible">
            <h3 className="card-title cursor-pointer" data-bs-toggle="collapse" data-bs-target="#kt_view_land_collapsible">Reporte NDVI</h3>
            <div className="card-toolbar">
              <Link to={"/gis/reports/ndvi/new"} className="btn btn-sm btn-light-primary">
                {t("reports:ndvi.list.buttons.create")}
              </Link>
            </div>
          </div>
          <div id="kt_view_land_collapsible" className="collapse show">
            <div className={"card-body " + ((reports.length < 1 || loading) ? "p-5" : "p-0")}>
              {loading && <Loading />}
              {!loading && reports.length < 1 && (
                <p className="text-center m-0">- {t("reports:ndvi.list.no_reports")} -</p>
              )}
              {reports.map(r => {
                return <Report report={r} />
              })}
            </div>
          </div>
        </div>
      </div>
      <Routes>
        <Route path="/new" element={<CreateNDVIReport />} />
        <Route path="/view/:id" element={<ViewNDVI />} />
      </Routes>
    </>
  );
};

const NDVIReportModule = () => {
  return (
    <NDVIReportProvider>
      <NDVIReport />
    </NDVIReportProvider>
  );
};

export default NDVIReportModule;