
import axios from "axios";

const UPDATE_PASSWORD_PATH = "/api/v1/users/me/password";
const ME_PATH = "/api/v1/users/me";
const COMPANY_PATH = "/api/v1/users/company";
const CURRENT_PLAN_PATH = "/api/v1/users/company/plan";
const USAGE_PLAN_PATH = "/api/v1/users/company/usage";

export const updatePassword = (data) => {
    return axios.put(UPDATE_PASSWORD_PATH, data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const me = () => {
    return axios.get(ME_PATH).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const updateMe = (values) => {
  return axios.put(ME_PATH, values).then(res => {
      if (res.status == 200) {
          return {
              ok: true,
              data: res.data
          };
      }
  });
};

export function updateCompany(values) {
    return axios.put(COMPANY_PATH, values).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export function currentPlan() {
  return axios.get(CURRENT_PLAN_PATH).then(res => {
      if (res.status == 200) {
          return {
              ok: true,
              data: res.data
          };
      }
  });;
}

export function companyUsage() {
    return axios.get(USAGE_PLAN_PATH).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });;
  }
