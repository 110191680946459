import { useEffect, useState } from "react";
import { Routes, Route, useParams, useNavigate, Link } from "react-router-dom";
import { parse, format } from "date-fns";

import { Loading } from "../../../../../../../_metronic/elements/Loading";
import { Modal, useModalContext } from "../../../../../../../_metronic/elements/Modal";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import * as NatalseedsServices from "./NatalseedsServices";
import ViewNatalseedsCampaign from "./ViewNatalseedsCampaign";

const ModalHeader = () => {
    const modal = useModalContext();

    return (
        <div className="modal-header p-5">
            <h5 className="modal-title">Campañas</h5>
            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => modal.hideModal()} aria-label="Close">
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
            </div>
        </div>
    );
};

const ModalBody = ({ loading, campaigns }) => {
    const { field_id, land_id } = useParams();

    return (
        <div className="modal-body p-0">
            <div className="row">
                <div className='col-lg-12 fv-row'>
                    {loading && <div className="p-5"><Loading /></div>}
                    {campaigns && campaigns.map(campaign => {
                        let date = format(parse(campaign.sowing_date, "yyyy-MM-dd'T'HH:mm:ss", new Date()), "dd/MM/yyyy");
                        return (
                            <>
                            <Link to={`/gis/field/view/${field_id}/land/view/${land_id}/natalseeds/campaign/${campaign.id}`} className="d-flex align-items-center p-5 land-item">
                                <span className="bullet bullet-vertical h-40px bg-primary me-5"></span>
                                <div className="flex-grow-1">
                                    <span className="text-gray-800 text-hover-primary fw-bold fs-6">{`Campaña ${date}`}</span>
                                </div>
                                <span className="btn btn-sm btn-light-primary fs-8 fw-bold"><KTSVG path="/media/icons/duotune/arrows/arr024.svg" /></span>
                            </Link>
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const ListCampaigns = ({ loading, campaigns }) => {

    return (
        <>
            <ModalHeader />
            <ModalBody loading={loading} campaigns={campaigns} />
        </>
    );
};

const NatalseedsCampaignModal = () => {
    const { field_id, land_id } = useParams();
    const navigate = useNavigate();
    const { errorHandler } = useErrorHandler();
    const [ loading, setLoading ] = useState(true);
    const [ campaigns, setCampaigns ] = useState([])
    
    useEffect(() => {
        errorHandler(NatalseedsServices.getCampaigns(land_id)).then(res => {
            setLoading(false);
            setCampaigns(res.data);
        });
    }, [])


    return (
        <>
            <Modal open={true} setOpen={() => navigate(`/gis/field/view/${field_id}/land/view/${land_id}`)}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <Routes>
                            <Route path="/:campaign_id" element={<ViewNatalseedsCampaign loading={loading} campaigns={campaigns} />} />
                            <Route path="/" element={<ListCampaigns loading={loading} campaigns={campaigns} />} />
                        </Routes>
                    </div>
                </div>
            </Modal>
        </>
    )
};

const NatalseedsCampaign = () => {

    return (
        <>
            <Routes>
                <Route path="/natalseeds/campaign" element={<NatalseedsCampaignModal />} />
                <Route path="/natalseeds/campaign/*" element={<NatalseedsCampaignModal />} />
            </Routes>
        </>
    );
};

export default NatalseedsCampaign;