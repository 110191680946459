import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend';

i18n
  .use(HttpApi) // if you want to load translations over http request
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "es", // if you're using a language detector, do not define the lng option
    fallbackLng: "es", // use en if detected lng is not available
    debug: true,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    defaultNS: "common",
    ns: ["common", "auth", "errors", "fields", "confirm", "lands", "ambient", "images", "img_ambient", "prescription", "layers", "gaps", "reports", "account", "payments", "reseller"],

    // if you're using http-backend
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;