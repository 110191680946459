import { useState, useEffect } from "react";

import { useErrorHandler } from "../../../_metronic/helpers/ErrorHandler";
import { Content } from "../../../_metronic/layout/components/Content";
import { Toolbar } from "./Toolbar";
import ListUsers from "./ListUsers";
import AssignAmbients from "./AssignResource";
import * as ResellerService from "./services/ResellerService";

const ResellerModule = () => {
    const { errorHandler } = useErrorHandler();
    const [ open, setOpen ] = useState(false);
    const [ user, setUser ] = useState(null);
    const [ loading, setLoading ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const load = () => {
        errorHandler(ResellerService.listUser()).then(res => {
            setUsers(res.data.data);
            setLoading(false);
        });
    }
    useEffect(() => {
        load();
    }, [])

    return (
        <>
        {/*<PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>*/}
        <div id='kt_content' className='p-0 content d-flex flex-column flex-column-fluid'>
            <Toolbar onInvite={() => load()} />
            <AssignAmbients loadUsers={load} open={open} setOpen={setOpen} user={user} />
            <div className='post d-flex flex-column-fluid p-5' id='kt_post'>
                <Content>
                    <ListUsers setOpen={setOpen} setUser={setUser} users={users} loading={loading} />
                </Content>
            </div>
        </div>
        </>
    );
};

export { ResellerModule };