import { useTranslation } from "react-i18next";

const ConfirmPassword = ({ loading, formik, getInputClasses }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fv-row mb-10">
        <label className='form-label fs-6 fw-bolder text-dark'>{t("auth:create_account.input.repeatpassword.label")}</label>
        <input
          disabled={loading}
          placeholder={t("auth:create_account.input.repeatpassword.placeholder")}
          type="password"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "repeatpassword"
          )}`}
          name="repeatpassword"
          {...formik.getFieldProps("repeatpassword")}
        />
        {formik.touched.repeatpassword && formik.errors.repeatpassword ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {formik.errors.repeatpassword}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ConfirmPassword;