import * as turf from "@turf/turf";

export class MapLayers {
    constructor(map, parent) {
        this.map = map;
        this.parent = parent
    }

    init() {
        /* Add land */
        this.map.addLayer({
            'id': "land",
            'source': "geojson",
            'type': 'fill',
            'filter': ["==", "resource_type", "land"],
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'fill-color': ['get', 'color'],
                'fill-opacity': ['get', 'opacity']
            }
        });

        this.map.addLayer({
            'id': "land_lines",
            'type': 'line',
            'source': "geojson",
            'filter': ["==", "resource_type", "land"],
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'line-color': ['get', 'borderColor'],
                'line-width': ['get', 'borderWidth'],
                'line-opacity': ['get', 'borderOpacity']
            }
        });  

        /* Add fields */
        this.map.addLayer({
            'id': 'fields_line',
            'type': 'line',
            'source': 'geojson',
            'filter': ["==", "resource_type", "field"],
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'line-opacity': ['get', 'borderOpacity'],
                'line-color': ['get', 'borderColor'],
                'line-width': ['get', 'borderWidth']
            }
        });

        /* Add no-agro zones */
        this.map.addLayer({
            'id': "gap_lines",
            'type': 'line',
            'source': "geojson",
            'filter': ["==", "resource_type", "gap"],
            'layout': {
                'visibility': 'visible'
            },
            'paint': {
                'line-opacity': ['get', 'borderOpacity'],
                'line-color': ['get', 'borderColor'],
                'line-width': ['get', 'borderWidth']
            }
        });  
    }

    addImage(imageId, imageUrl, polygon, bbox) {
        if (!this.sourceExists(imageId)) {
            this.map.addSource(imageId, {
                type: 'image',
                url: imageUrl,
                coordinates: bbox,
            });
        }

        if (!this.layerExists(imageId)) { 
            this.map.addLayer({
                id: imageId,
                type: "raster",
                source: imageId,
                paint: {
                    "raster-opacity": 1,
                },
            });
        }
    }

    deleteLayerAndSource(imageId) {
        try {
            if (this.layerExists(imageId)) {
                this.map.removeLayer(imageId);
            }
            
            if (this.sourceExists(imageId)) {
                this.map.removeSource(imageId);
            }
        } catch(e) {}
    }

    sourceExists(sourceId) {
        return !!this.map.getSource(sourceId);
    }
      
    layerExists(layerId) {
        return !!this.map.getLayer(layerId);
    }

    addAmbient({ landId, geojson }) {
        const sourceName = `${landId}-ambient`;
        this.map.addSource(sourceName, {
            'type': 'geojson',
            'data': geojson
        });

        this.map.addLayer({
            'id': sourceName,
            'source': sourceName,
            'type': 'fill',
            'paint': {
                'fill-color': ['get', 'color'],
            }
        });
        return sourceName;
    }

    removeAmbient({ landId }) {
        const sourceName = `${landId}-ambient`;
        this.deleteLayerAndSource(sourceName)
        return sourceName;
    }

    sourceExists(sourceId) {
        return !!this.map.getSource(sourceId);
    }
      
    layerExists(layerId) {
        return !!this.map.getLayer(layerId);
    }
}
