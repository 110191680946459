import { Link, useMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { KTSVG } from "../../../../../_metronic/helpers";
import { t } from "i18next";

const NDVIReport = () => {
  const { t } = useTranslation();
  const active = useMatch("/gis/reports/ndvi");
  return (
    <Link to={`/gis/reports/ndvi`} end className={"d-flex text-hover-primary align-items-center rounded p-5 " + (active ? 'bg-light-primary' : '')}>
      <KTSVG
        path="/media/icons/duotune/graphs/gra011.svg"
        className="svg-icon svg-icon-2x svg-icon-primary me-5"
      />
      <div className="flex-grow-1 me-2">
        <span className="fw-bold text-gray-800 fs-6">{t("reports:selector.ndvi_report")}</span>
      </div>
    </Link>
  );
}

const ReportsSelector = () => {
  return (
    <>
      <div className="card shadow-sm field-view-container displayable right-card-data">
        <div className="card-header collapsible">
          <h4 className="card-title cursor-pointer" data-bs-toggle="collapse" data-bs-target="#kt_reports_selector">
            {t("reports:selector.title")}
          </h4>
        </div>
        <div id="kt_reports_selector" className="collapse show">
          <div className={`card-body p-0`}>
            <NDVIReport />
          </div>
        </div>
      </div>
    </>
  );
};

const ReportsSelectorContainer = () => {
  return (
    <>
      <div className="d-flex flex-column flex-row-auto field-view-flex-container">
        <div className="d-flex flex-column-auto">
          <ReportsSelector />
        </div>
      </div>
    </>
  );
};

export default ReportsSelectorContainer;