import { Modal } from "../../../../../../../_metronic/elements/Modal";

const ImageAmbientModal = ({ open, setOpen, children }) => {

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {children}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImageAmbientModal;