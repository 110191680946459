import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { openAlert } from "../../../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import { useViewFieldContext } from "../ViewFieldContext";
import { useSearchContext } from "../../../Toolbar/SearchContext";
import { useDrawV2 } from "../../../Map/Draw/DrawV2Context";
import { DrawTools } from "../../../Map/Draw/DrawState";
import { useMapLayersV2 } from "../../../Map/MapLayersV2Context";
import { KTSVG } from "../../../../../../_metronic/helpers";
import * as LandService from "../../services/LandService";
import * as turf from '@turf/turf';

const CreateLand = () => {
    const [ polygons, setPolygons ] = useState({});
    const [ polygonNames, setPolygonNames ] = useState({});
    const [ polygonSize, setPolygonSize ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const { errorHandler } = useErrorHandler();
    const { field_id } = useParams();
    const { field, load, lands } = useViewFieldContext();
    const { t } = useTranslation();
    const counter = useRef(0);
    const searchContext = useSearchContext();
    const mapLayers = useMapLayersV2();
    const navigate = useNavigate();
    const draw = useDrawV2();
    const hasAddedLands = Object.keys(polygonNames).length >= 1;

    const save = async () => {
        const lands = [];
        Object.keys(polygons).forEach(key => {
            if (polygonNames[key] == null || polygonNames[key].length < 1) {
                openAlert('danger', 'Error', t("lands:create.form.input.name.errors.required"));
                throw Error("Missing name");
            }


            if (polygonNames[key].length > 64) {
                openAlert('danger', 'Error', t("lands:create.form.input.name.errors.max"));
                throw Error("Missing name");
            }

            const size = polygonSize[key];
            if (size < 5) {
                openAlert('danger', 'Error', t("lands:create.form.errors.min_size"));
                throw Error("Min ha error");
            }
            
            const polygon = polygons[key]
            let geometry;
            if (polygon.geometry) {
                geometry = polygon.geometry;
            } else {
                geometry = polygon;
            }

            lands.push({
                name: polygonNames[key],
                polygon: geometry
            });
        });

        if (lands.length < 1) {
            openAlert('danger', 'Error', t("lands:create.form.errors.one_required"));
            return;
        }

        setLoading(true);

        const res = await errorHandler(
            LandService.create(field_id, {
                lands
            })
        );

        if (!res.ok) {
            setLoading(false);
            return
        }

        setLoading(false);
        navigate(`/gis/field/view/${field_id}`);
        openAlert('success', t("lands:create.success.title"), t("lands:create.success.message"));
        mapLayers.reloadSource();
        searchContext.load();
        load();
    }

    const start = () => {
        draw.startDrawing({
            tools: [DrawTools.POLYGON, DrawTools.CIRCLE, DrawTools.UPLOAD_FILE],
            onCreate: (e, isFile) => {
                const n = e.features[0];
                setPolygons(prev => ({...prev, [n.id]: n}));
                
                let polygonName;

                if (n.properties && n.properties.name) {
                    polygonName = n.properties.name;
                } else {
                    polygonName = `${field.name} - Lote ${(1 + counter.current)}`;
                }
                 
                setPolygonNames(prev => ({...prev, [n.id]: polygonName}));
                counter.current += 1;

                let geometry;
                if (n.geometry) {
                    geometry = n.geometry;
                } else {
                    geometry = n;
                }
                addHectareaSize(n.id, geometry);
            },
            onUpdate: (e) => {
                const n = e.features[0];
                setPolygons(prev => ({...prev, [n.id]: n}));

                let geometry;
                if (n.geometry) {
                    geometry = n.geometry;
                } else {
                    geometry = n;
                }
                addHectareaSize(n.id, geometry);
            },
            onDelete: (e) => {
                const n = e.features[0];
                deletePolygon(n.id)();
            }
        });
    };

    const updateName = (id) => (e) => {
        const value = e.target.value;
        setPolygonNames(prev => ({...prev, [id]: value}));
    }

    const addHectareaSize = (id, polygon) => {
        const areaInSquareMeters = turf.area(polygon);
        const areaInHectares = areaInSquareMeters / 10000;
        setPolygonSize(prev => ({...prev, [id]: areaInHectares}));
    }

    const fitPolygon = (polygon) => {
        let geometry;
        if (polygon.geometry) {
            geometry = polygon.geometry;
        } else {
            geometry = polygon;
        }
       
        mapLayers.fitToPolygon(geometry)
    }

    const deletePolygon = (id, deletePolygon) => () => {
        setPolygons(prev => {
            const p = {...prev};
            delete p[id];
            return p;
        });
        setPolygonNames(prev => {
            const p = {...prev};
            delete p[id];
            return p;
        });
        setPolygonSize(prev => {
            const p = {...prev};
            delete p[id];
            return p;
        });

        if (deletePolygon) {
            draw.deleteLayer(id);
        }
    };
    
    useEffect(() => {
        document.getElementById('kt_content_container').scroll({top:0,behavior:'smooth'});
        openAlert('info', t("lands:create.alert.title"), t("lands:create.alert.message"));

        return () => {
            draw.endDrawing();
        };
    }, []);

    useEffect(() => {
        if (field != null) {
            counter.current = lands.length;
            start();
        } else {
            counter.current = 0;    
        }
    }, [field]);

    return (
        <>
                <div className="d-flex flex-column flex-row-auto field-view-flex-container">
                    <div className="d-flex flex-column-auto displayable mb-4">
                        <div className="card shadow-sm field-view-container right-card-data">
                            <div className="card-header">
                                <h4 className="card-title">{t("lands:create.title")}</h4>
                            </div>
                            <div className="card-body">
                                {hasAddedLands && (<>
                                    <p className="fw-bold">{t("lands:create.form.title")}</p>
                                    {Object.keys(polygonNames).map(key => {
                                        return (
                                            <>
                                            <div className="input-group mb-3">
                                                <input required type="text" onFocus={e => {
                                                    fitPolygon(polygons[key])
                                                }} className="form-control mb-3" onChange={updateName(key)} value={polygonNames[key]}/>
                                                <div className="input-group-append">
                                                    <button onClick={deletePolygon(key, true)} className="btn btn-light-danger" type="button"w>
                                                        <KTSVG path="/media/icons/duotune/art/art004.svg" className="svg-icon svg-icon-primary" />
                                                    </button>
                                                </div>
                                            </div>
                                            {(polygonSize[key] < 5) && (
                                                <div className="alert alert-dismissible bg-light-warning border border-warning d-flex flex-column flex-sm-row p-5 mb-5">
                                                    <div className="d-flex flex-column pe-0 pe-sm-10">
                                                        <span>{t("lands:create.form.errors.min_size")}</span>
                                                    </div>
                                                </div>
                                            )}
                                            </>
                                        )
                                    })}
                                </>)}
                                {!hasAddedLands && (<>
                                    <div className="alert alert-dismissible bg-light-primary border border-primary d-flex flex-column flex-sm-row p-5 mb-5">
                                        <div className="d-flex flex-column text-primary pe-0 pe-sm-10">
                                            <h5 className="mb-1">{t("lands:create.note.title")}</h5>
                                            <span>{t("lands:create.note.message")}</span>
                                        </div>
                                    </div>
                                </>)}
                            </div>
                            <div className="card-footer d-flex justify-content-end py-6 px-9">
                                <button onClick={save} disabled={!hasAddedLands || loading} type="button" className="btn btn-sm btn-primary me-5">
                                    {t("lands:create.buttons.create")}
                                </button>
                                <Link to={`/gis/field/view/${field_id}`} className="btn btn-sm btn-light-danger">
                                    {t("lands:create.buttons.cancel")}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
};

export default CreateLand;