import axios from 'axios';

const join = (url, path) => {
  return url + path;
};

const LIST_NDVI_REPORTS_PATH = "/api/v2/fields/lands/reports/ndvi";
const NEW_NDVI_REPORTS_PATH = "/api/v2/fields/lands/reports/ndvi";
const GET_NDVI_REPORTS_PATH = "/api/v2/fields/lands/reports/ndvi/:id";

export function list(page, size) {
    return axios.get(LIST_NDVI_REPORTS_PATH, {
        params: { page, size }
    }).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });;
}

export function newNdviReport(data) {
    return axios.post(NEW_NDVI_REPORTS_PATH, data).then(res => {
        if (res.status == 202) {
            return {
                ok: true,
                data: res.data
            };
        }
    });;
}

export function getNdviReport(id) {
    return axios.get(GET_NDVI_REPORTS_PATH.replace(":id", id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    })
}

export function deleteNdviReport(id) {
    return axios.delete(GET_NDVI_REPORTS_PATH.replace(":id", id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    })
}