
import axios from "axios";

const join = (url, path) => {
    return url + path;
};

const INVITE_USER_PATH = "/api/v1/users/reseller";
const LIST_USER_PATH = "/api/v1/users/reseller";
const ASSIGN_AMBIENT_PATH = "/api/v1/users/reseller/assign";
const TOKEN_PATH = "/api/v1/users/reseller/token";

export function inviteUser(values) {
    return axios.post(INVITE_USER_PATH, values).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export function listUser() {
    return axios.get(LIST_USER_PATH).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export function assignAmbient(values) {
    return axios.post(ASSIGN_AMBIENT_PATH, values).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export function token(values) {
    return axios.post(TOKEN_PATH, values).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}
