import React, { createContext, useState, useContext } from "react";

const ImageAmbientContext = createContext();

export function useImageAmbient() {
  return useContext(ImageAmbientContext);
}

export const ImageAmbientConsumer = ImageAmbientContext.Consumer;

export function ImageAmbientProvider({ children }) {
    const [ prod, setProd ] = useState({
        'prod1': 'Prod 1',
        'prod2': 'Prod 2',
        'prod3': 'Prod 3' 
    });
    const [ columns, setColumns ] = useState({
        0: {},
        1: {},
        2: {} 
    });
    const value = {
        prod,
        setProd,

        columns,
        setColumns
    };

    return (
        <ImageAmbientContext.Provider value={value}>
            {children}
        </ImageAmbientContext.Provider>
    );
}

// TODO: CHECK IF THIS IS NEEDED