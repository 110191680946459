import { useNavigate, useParams } from "react-router-dom";
import { KTSVG } from "../../../../../../../_metronic/helpers";

const NatalseedsLandMenu = () => {
    const { field_id, land_id } = useParams();
    const navigate = useNavigate();

    return (
        <div className="row" style={{ borderBottom: '1px solid var(--kt-card-border-color)' }}>
            <div class="col-12" onClick={() => navigate(`/gis/field/view/${field_id}/land/view/${land_id}/natalseeds/campaign`)}>
                <div className='card'>
                    <div className={'card-body d-flex justify-content-center text-center flex-column p-5 '}>
                        <a href='#' className='text-gray-800 text-hover-primary d-flex flex-column'>
                            <div className='symbol symbol-25px mb-4'>
                                <KTSVG path={'/media/icons/duotune/files/fil002.svg'} className={`svg-icon svg-icon-2x `} />
                            </div>
                            <div className='fs-5 fw-bolder mb-2'>Campañas</div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NatalseedsLandMenu
