import { useState, useEffect, useMemo } from "react";
import { useMatch } from "react-router-dom";

import { ImageAmbientProvider } from "./ImageAmbientContext";
import ImageAmbientDialogClasses from "./ImageAmbientDialogClasses";
import ImageAmbientCount from './ImageAmbientCount'
import { useViewImageContext } from "../Images/ViewImageContext";
import { useViewLandContext } from "../ViewLandContext";
import ImageAmbientModal from "./ImageAmbientModal";

const ImageAmbient = ({ open, setOpen, ambient, evi }) => {
    const [ loading, setLoading ] = useState(false);
    const [ countClasses, setCountClasses ] = useState(2);
    const [ nextStep, setNextStep ] = useState(false);
    const viewImageContext = useViewImageContext();
    const viewLandContext = useViewLandContext();
    const hasEvi = useMemo(() => {
        let land = viewLandContext.land;

        if (!viewImageContext.activeLands[land.land_id]) {
            return false;
        }

        return viewImageContext.activeLands[land.land_id]['imageType'] == 'evi';
    }, [viewLandContext.land, viewImageContext.activeLands]);
    const isAmbientActive = useMatch('/gis/field/view/:field_id/land/view/:land_id/ambients');
    const mustShow = useMemo(() => {
        let land = viewLandContext.land;

        if (land == null) {
            return false;
        }

        let hasAmbient = !(ambient == null || Object.keys(ambient).length == 0);

        return hasAmbient || hasEvi;
    }, [viewLandContext.land, viewImageContext.activeLands, ambient, hasEvi]);
    
    const onCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (isAmbientActive && ambient && open) {
            setCountClasses(ambient.params.breaks);
            setNextStep(true);
        } else {
            setCountClasses(2);
            setNextStep(false);
        }
    }, [open]);

    if (!mustShow)  {
        return (
            <></>
        );
    }
    return (
        <>
            <ImageAmbientProvider>
                <ImageAmbientModal open={open} setOpen={setOpen}>
                    {nextStep && (
                        <ImageAmbientDialogClasses setNextStep={setNextStep} hasEvi={hasEvi} open={open} setOpen={setOpen} numClasses={countClasses} ambient={ambient} />
                    )}
                    {!nextStep && (
                        <ImageAmbientCount setNextStep={setNextStep} open={open} setOpen={setOpen} setCountClasses={setCountClasses} />
                    )}
                </ImageAmbientModal>
            </ImageAmbientProvider>
        </>
    )
};

export default ImageAmbient;