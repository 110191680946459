import { useTranslation } from "react-i18next";
import { InputSelect, InputText } from "../../../../../_metronic/elements/Inputs";

const provinces = [
  "Buenos Aires",
  "Ciudad Autónoma de Buenos Aires",
  "Catamarca",
  "Chaco",
  "Chubut",
  "Córdoba",
  "Corrientes",
  "Entre Rios",
  "Formosa",
  "Jujuy",
  "La Pampa",
  "La Rioja",
  "Mendoza",
  "Misiones",
  "Neuquén",
  "Rio Negro",
  "Salta",
  "San Juan",
  "San Luis",
  "Santa Cruz",
  "Santa Fe",
  "Santiago del Estero",
  "Tierra del Fuego, Antártida e Islas del Atlántico Sur",
  "Tucumán"
];

const UpdateAccountInformationInputs = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <div className="row">
      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="first_name" inputProps={{
            placeholder: t("account:update.fields.name.placeholder"),
            id: "first_name"
          }} />
          <label for="first_name">{t("account:update.fields.name.label")}</label>
        </div>
      </div>
      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="last_name" inputProps={{
            placeholder: t("account:update.fields.last_name.placeholder"),
            id: "last_name"
          }} />
          <label for="last_name">{t("account:update.fields.last_name.label")}</label>
        </div>
      </div>
      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="company_name" inputProps={{
            placeholder: t("account:update.fields.company_name.placeholder"),
            id: "company_name"
          }} />
          <label for="company_name">{t("account:update.fields.company_name.label")}</label>
        </div>
      </div>
      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="phone" inputProps={{
            placeholder: t("account:update.fields.phone.placeholder"),
            id: "phone"
          }} />
          <label for="phone">{t("account:update.fields.phone.label")}</label>
        </div>
      </div>
      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="address" inputProps={{
            placeholder: t("account:update.fields.address.placeholder"),
            id: "address"
          }} />
          <label for="address">{t("account:update.fields.address.label")}</label>
        </div>
      </div>

      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="city" inputProps={{
            placeholder: t("account:update.fields.city.placeholder"),
            id: "city"
          }} />
          <label for="city">{t("account:update.fields.city.label")}</label>
        </div>
      </div>

      <div className="col-6">
        <div className="form-floating mb-7">
          <InputSelect defaultOption={t("account:update.fields.state.default_option")} disabled={formik.isSubmitting} formik={formik} name="province" inputProps={{
            placeholder: t("account:update.fields.state.placeholder"),
            id: "province"
          }} options={
            provinces.map(p => ({ key: p, value: p }))
          } />
          <label for="province">{t("account:update.fields.state.label")}</label>
        </div>
      </div>
      <div className="col-6">
        <div className="form-floating mb-7">
          <InputText disabled={formik.isSubmitting} formik={formik} name="zip_code" inputProps={{
            placeholder: t("accountupdate.fields.zip_code.placeholder"),
            id: "zip_code"
          }} />
          <label for="zip_code">{t("accountupdate.fields.zip_code.label")}</label>
        </div>
      </div>
    </div>
  );
};

export default UpdateAccountInformationInputs;