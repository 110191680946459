import axios from 'axios';

const UPLOAD_MEDIA_PATH = "/api/v2/geojson/file";

export function uploadMedia(data) {
    return axios.post(UPLOAD_MEDIA_PATH, data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });;
}
