import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { LoadingCard } from "../../../../_metronic/elements/Loading";
import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import * as UserService from "../services/UserService";



const PaymentInformation = () => {
  const { t } = useTranslation();
  const names = {
    'ambients': t("payments:view.names.ambients"),
    'members': t("payments:view.names.users"),
    "reports_ndvi_ha": t("payments:view.names.reports_ndvi")
  };
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const { errorHandler } = useErrorHandler();
  const load = async () => {
    setLoading(true);
    const res = await errorHandler(UserService.currentPlan());

    if (res.ok) {
      setData(res.data.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  if (loading) {
    return <LoadingCard />
  }

  return (
    <>
      <div className="card card-custom col-xl-6 col-md-6">
        <div className="card-header">
          <h3 className="card-title">{t("payments:view.title")}</h3>
        </div>
        <div className="card-body">
          {data.resources.filter(e => names[e.resource_id]).map(resource => {
            return (
              <div className="row">
                <div className="col-6">
                  <strong>{names[resource.resource_id]}</strong>
                </div>
                <div className="col-6">
                  {resource.quantity}
                </div>
              </div>
            );
          })}

        </div>
      </div>
    </>
  );
};

export default PaymentInformation;