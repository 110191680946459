
import axios from "axios";

const GEOJSON_PATH = "/api/v2/geojson";

export const fetch = () => {
    return axios.get(GEOJSON_PATH).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};