/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { KTSVG } from '../../../../_metronic/helpers';

const RightDrawer = ({ children }) => {
  return (
    <>
      {/* begin::Demos drawer */}
      <div
        id='kt_right_container'
        className='bg-body'
        data-kt-drawer='true'
        data-kt-drawer-name='right-container'
        data-kt-drawer-activate='true'
        data-kt-drawer-overlay='true'
        data-kt-drawer-width="{default:'350px', 'lg': '475px'}"
        data-kt-drawer-direction='end'
        data-kt-drawer-toggle='#kt_right_toggle'
        data-kt-drawer-close='#kt_right_close'
      >
        {/* begin::Card  */}
        <div className='card shadow-none w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_explore_header'>
            <h1 className="card-title">{` `}</h1>
            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                id='kt_right_close'
              >
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body p-0' id='kt_explore_body'>
            {/* begin::Content */}
            {children}
            {/* end::Content */}
          </div>
          {/* end::Body */}
        </div>
        {/* begin::Card */}
      </div>
      {/* end::Demos drawer */}
    </>
  )
}

export default RightDrawer;