import { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";
import { ConfirmPassword, Email, FirstName, LastName, Password } from "./Input";
import Action from "./Action";
import * as AuthService from '../../services/AuthService';

const Registration = () => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const navigate = useNavigate();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      repeatpassword: ""
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string()
        .min(3, t("auth:create_account.input.first_name.errors.min"))
        .max(50, t("auth:create_account.input.first_name.errors.max"))
        .required(t("auth:create_account.input.first_name.errors.required")),
      last_name: Yup.string()
        .min(3, t("auth:create_account.input.last_name.errors.min"))
        .max(50, t("auth:create_account.input.last_name.errors.max"))
        .required(t("auth:create_account.input.last_name.errors.required")),
      email: Yup.string()
        .email(t("auth:create_account.input.email.errors.email"))
        .max(50, t("auth:create_account.input.email.errors.max"))
        .required(t("auth:create_account.input.email.errors.required")),
      password: Yup.string()
        .min(8, t("auth:create_account.input.password.errors.min"))
        .max(32, t("auth:create_account.input.password.errors.max"))
        .required(t("auth:create_account.input.password.errors.required")),
      repeatpassword: Yup.string()
        .required(t("auth:create_account.input.repeatpassword.errors.required"))
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("auth:create_account.input.repeatpassword.errors.equal")
          ),
        })
    }),
    onSubmit: (values, { resetForm }) => {
      enableLoading();

      const body = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        password: values.password
      };

      errorHandler(AuthService.register(body))
        .then((res) => {
          if (!res.ok) {
            disableLoading();
            return;
          }

          openAlert('success', t("auth:create_account.success.title"), t("auth:create_account.success.message"));
          disableLoading();
          navigate('/auth/login');
          resetForm();
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {t("auth:create_account.title")}
        </h3>
      </div>
      <form
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={formik.handleSubmit}
      >
        <Email loading={loading} formik={formik} getInputClasses={getInputClasses} />
        <FirstName loading={loading} formik={formik} getInputClasses={getInputClasses} />
        <LastName loading={loading} formik={formik} getInputClasses={getInputClasses} />
        <Password loading={loading} formik={formik} getInputClasses={getInputClasses} />
        <ConfirmPassword loading={loading} formik={formik} getInputClasses={getInputClasses} />
        <Action formik={formik} loading={loading} />
      </form>
    </div>
  );
}

export default Registration;
