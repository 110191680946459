import { useTranslation } from "react-i18next";

const Email = ({ loading, formik, getInputClasses }) => {
  const { t } = useTranslation();

  return (
    <div className="fv-row mb-10">
      <label className='form-label fs-6 fw-bolder text-dark'>{t("auth:create_account.input.email.label")}</label>
      <input
        disabled={loading}
        placeholder={t("auth:create_account.input.email.placeholder")}
        type="email"
        className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
          "email"
        )}`}
        name="email"
        {...formik.getFieldProps("email")}
      />
      {formik.touched.email && formik.errors.email ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">{formik.errors.email}</div>
        </div>
      ) : null}
    </div>
  );
};

export default Email;