import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup'

import { Modal } from "../../../../../_metronic/elements/Modal";
import { InputText } from "../../../../../_metronic/elements/Inputs";
import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";
import { useMapLayersV2 } from "../../Map/MapLayersV2Context";
import { useDrawV2 } from "../../Map/Draw/DrawV2Context";
import { useDrawEndButton } from "../../Map/Draw/DrawEndButton/DrawEndButtonContext";
import { useViewFieldContext } from "../ViewField/ViewFieldContext";
import * as FieldService from "../services/FieldService";
import { useSearchContext } from "../../Toolbar/SearchContext";

const ModalHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="modal-header p-5">
            <h5 className="modal-title">{t("fields:update.title")}</h5>
        </div>
    );
};

const ModalBody = ({ formik, loading, polygonUpdated, updatePolygon, field }) => {
    const { t } = useTranslation();

    return (
        <div className="modal-body p-5">
            <div className="row">
                <div className='col-lg-12 fv-row'>
                    <InputText disalbed={loading} formik={formik} name="name" inputProps={{
                        placeholder: t("fields:create.input.name.placeholder")
                    }} />
                    {field.polygons && (
                        <>
                            {polygonUpdated && (
                                <button onClick={updatePolygon} type="button" className="btn btn-light-success mt-5" style={{ width: '100%' }}>{t("fields:update.buttons.update_polygon")}</button>
                            )}
                            {!polygonUpdated && (
                                <button onClick={updatePolygon} type="button" className="btn btn-light-primary mt-5" style={{ width: '100%' }}>{t("fields:update.buttons.update_polygon")}</button>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

const ModalFooter = ({ loading, setOpen }) => {
    const { t } = useTranslation();

    return (
        <div className="modal-footer">
            <button disabled={loading == true} type="button" className="btn btn-light" onClick={() => setOpen(false)}>{t("fields:update.buttons.cancel")}</button>
            <button type="submit" className="btn btn-primary">
                {!loading && t("fields:update.buttons.update")}
                {loading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                        {t("fields:update.messages.updating")}{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </button>
        </div>
    );
};

const UpdateField = ({ open, setOpen }) => {
    const { field, setField } = useViewFieldContext();
    const [polygonUpdated, setPolygonUpdated] = useState(false);
    const [polygon, setPolygon] = useState(false);
    const [loading, setLoading] = useState(false);
    const { field_id } = useParams();
    const { errorHandler } = useErrorHandler();
    const { t } = useTranslation();
    const mapLayers = useMapLayersV2();
    const drawV2 = useDrawV2();
    const drawEndButton = useDrawEndButton();
    const search = useSearchContext();
    const navigate = useNavigate();
    const schema = Yup.object().shape({
        name: Yup.string().min(
            1, t("fields:create.input.name.errors.min")
        ).max(
            32, t("fields:create.input.name.errors.max")
        ).required(t("fields:create.input.name.errors.required")),
    });

    const formik = useFormik({
        initialValues: {
            name: field.name,
        },
        enableReinitialze: true,
        validationSchema: schema,
        onSubmit: async (values, { setFieldValue }) => {
            setLoading(true);

            const extra = {};
            if (polygon) {
                extra["polygons"] = polygon.geometry;
            }
            const res = await errorHandler(FieldService.update(field_id, { name: values.name, ...extra }));

            if (res.ok) {
                mapLayers.reloadSource();
                search.load();

                cancel();

                setField(res.data);
                openAlert('success', t("fields:update.messages.success_title"), t("fields:update.messages.success"));
                setFieldValue("name", res.data.name)
                setOpen(false);
                navigate(`/gis/field/view/${field_id}`);
            }

            setLoading(false);
        }
    });

    const updatePolygon = () => {
        setPolygonUpdated(true);
        mapLayers.field.hide(field_id);

        let polygonId;
        if (!polygon) {
            polygonId = drawV2.add(field.polygons);
            setPolygon({
                id: polygonId,
                geometry: field.polygons
            });
            mapLayers.fit(field_id);
        } else {
            polygonId = polygon.id;
        }

        drawV2.directSelect(polygonId);
        setOpen(false);
        openAlert('info', t("fields:update.update_polygon.title"), t("fields:update.update_polygon.message"));

        drawEndButton.show({
            title: t("fields:update.update_polygon.updated_polygon"),
            buttonText: t("fields:update.buttons.end_edition"),
            onFinish: () => {
                const newGeom = drawV2.get(polygonId).geometry;
                setOpen(true);
                setPolygon({ id: polygonId, geometry: newGeom });
            },
            onCancel: () => {
                setOpen(true);
                setPolygon(null);
                mapLayers.field.show(field_id);
                drawV2.endDrawing();
                drawEndButton.hide();
            }
        });
    };

    const cancel = () => {
        setPolygonUpdated(false);
        setPolygon(null);

        mapLayers.field.show(field_id);
        drawEndButton.hide();
        drawV2.endDrawing();
        drawV2.deleteAllLayers();

        formik.resetForm();
        return setOpen(false);
    };

    useEffect(() => {
        if (polygon == false) {
            setPolygonUpdated(false);
            return;
        }
        return;
    }, [polygon]);

    return (
        <>
            <Modal open={open} setOpen={setOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={formik.handleSubmit} className='form'>
                            <ModalHeader />
                            <ModalBody formik={formik} field={field} loading={loading} polygonUpdated={polygonUpdated} updatePolygon={updatePolygon} />
                            <ModalFooter loading={loading} setOpen={cancel} />
                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default UpdateField;