import axios from "axios";

export const getCampaigns = (landId) => {
    return axios.get(`/api/v2/fields/tenants/natalseeds/campaign/${landId}`).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};