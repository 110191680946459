import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useModalContext } from "../../../../../../../_metronic/elements/Modal";

const ModalHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{t("prescription:count.title")}</h5>
      </div>
    </>
  );
};

const ModalFooter = () => {
  const modal = useModalContext();
  const { t } = useTranslation();

  return (
    <div className="modal-footer">
      <button type="button" className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("prescription:count.buttons.cancel")}
      </button>
      <button type="submit" className="btn btn-primary">
        {t("prescription:count.buttons.accept")}
      </button>
    </div>
  )
}

const PrescriptionCount = ({ open, setOpen, setCountClasses, setNextStep }) => {
  const [cls, setCls] = useState(2);
  const onSubmit = (e) => {
    e.preventDefault();
    setCountClasses(cls)
    setNextStep(true);
  }

  return (
    <>
      <form onSubmit={onSubmit} className='form'>
        <ModalHeader />
        <div className="modal-body">
          <select value={cls} className="form-select" onChange={e => setCls(e.target.value)}>
            {[2, 3, 4, 5, 6, 7].map(e => {
              return <option value={e} selected={cls == e}>{e}</option>
            })}
          </select>
        </div>
        <ModalFooter />
      </form>
    </>
  );
};

export default PrescriptionCount;