
import { useTranslation } from "react-i18next";

import { useViewImageContext } from "./ViewImageContext";
import { useViewLandContext } from "../ViewLandContext";
import { KTSVG } from "../../../../../../../_metronic/helpers";
import DateSelector from "./DateSelector";
import "./Images.style.scss";

const ImageTypeChoice = () => {
  const { t } = useTranslation();
  const viewImageContext = useViewImageContext();
  const viewLandContext = useViewLandContext();
  const activedChoice = viewImageContext.activeLands[viewLandContext.land.land_id] ? viewImageContext.activeLands[viewLandContext.land.land_id].imageType : null;
  const hasSelectedDate = viewImageContext.activeLands[viewLandContext.land.land_id] ? viewImageContext.activeLands[viewLandContext.land.land_id].selectedDate : false
  const disabled = viewImageContext.loading;

  return (
    <>
      <div onClick={() => viewImageContext.changeImageType(viewLandContext.land, "rgb")} className={`d-flex align-items-center div-type-selector rounded p-5 ` + (activedChoice == 'rgb' ? 'active' : '') + ` ` + (disabled ? 'disabled' : '')}>
        <div class="symbol symbol-45px symbol-circle me-5">
          <div class="symbol-label" style={{ backgroundImage: "url(/media/images/rgb.png)" }}></div>
        </div>
        <div className="flex-grow-1 me-2">
          <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("images:selector.visible")}</span>
          {(activedChoice == 'rgb' && viewImageContext.loading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>

      <div onClick={() => viewImageContext.changeImageType(viewLandContext.land, "evi")} className={`d-flex align-items-center div-type-selector rounded p-5 ` + (activedChoice == 'evi' ? 'active' : '') + ` ` + (disabled ? 'disabled' : '')}>
        <div class="symbol symbol-45px symbol-circle me-5">
          <div class="symbol-label" style={{ backgroundImage: "url(/media/images/evi.png)" }}></div>
        </div>
        <div className="flex-grow-1 me-2">
          <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("images:selector.evi")}</span>
          {(activedChoice == 'evi' && viewImageContext.loading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
        <div 
            onClick={() => {
              if (activedChoice === 'evi' && hasSelectedDate && !viewImageContext.loading) {
                viewImageContext.setOpenImageAmbient(true);
              }
            }}
            className={`d-flex align-items-center gen-button rounded p-3 ${activedChoice === 'evi' && hasSelectedDate && !viewImageContext.loading ? '' : 'disabled'}`}
          >
          <span className="svg-icon svg-icon-warning me-2">
            <KTSVG path="/media/icons/duotune/maps/map005.svg" className="svg-icon-1" />
          </span>
          <div className="flex-grow-1 me-2">
            <span className="fw-bold text-gray-800 text-hover-primary fs-8">{t("images:buttons.create_ambientation")}</span>
          </div>
        </div>
      </div>

      <div onClick={() => viewImageContext.changeImageType(viewLandContext.land, "ndvi")} className={`d-flex align-items-center div-type-selector rounded p-5 ` + (activedChoice == 'ndvi' ? 'active' : '') + ` ` + (disabled ? 'disabled' : '')}>
        <div class="symbol symbol-45px symbol-circle me-5">
          <div class="symbol-label" style={{ backgroundImage: "url(/media/images/ndvi.png)" }}></div>
        </div>

        <div className="flex-grow-1 me-2">
          <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("images:selector.ndvi")}</span>
          {(activedChoice == 'ndvi' && viewImageContext.loading) && <span className='spinner-border spinner-border-sm align-middle ms-2'></span>}
        </div>
      </div>
    </>
  );
};

const Images = () => {

  return (
    <>
      <DateSelector />
      <div className="d-flex align-items-center rounded p-5">
        <div className="flex-grow-1 me-2">
          <h4 className="" style={{ marginLeft: '5px' }}>Tipo</h4>
        </div>
      </div>
      <ImageTypeChoice />
    </>
  );
};


export default Images;