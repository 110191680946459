import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useErrorHandler } from "../../_metronic/helpers/ErrorHandler";
import { useAuth } from "../modules/auth";
import * as AuthService from "../modules/auth/services/AuthService";

const LoginAs = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { errorHandler } = useErrorHandler();
    const { saveAuth, setCurrentUser } = useAuth();
    const load = async () => {
        const access_token = location.search.split("token=")[1];

        saveAuth({
            api_token: access_token
        })
        const res = await errorHandler(AuthService.me(access_token));
        setCurrentUser(res.data.data)
        navigate("/gis");
    }

    useEffect(() => {
        load();
    }, [location]);

    return (
        <>
        </>
    );
};

export default LoginAs;