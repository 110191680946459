import { Routes, Route } from "react-router-dom";
import ReportsSelector from "./ReportsSelector";
import NDVIReport from "./NDVIReport";


export const LeftContainerReports = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<ReportsSelector />} />
      </Routes>
      <Routes>
        <Route path="/ndvi/*" element={<NDVIReport />} />
      </Routes>
    </>
  );
};
