import { useEffect, useCallback, useState, useRef } from "react";
import { format } from "date-fns";
import axios from "axios";

import { useErrorHandler } from "../../../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewImageContext } from "../../ViewImageContext";
import { useMapLegendContext } from "../../../Helpers/MapLegend";
import { useMapLayersV2 } from "../../../../../../Map/MapLayersV2Context";
import * as LandService from "../../../../../services/LandService";

const parseHeaders = (totalHeaders) => {
    if (!totalHeaders || totalHeaders.length < 1) {
        return null;
    }

    const ha = {};
    let totalIntervals = {};
    let totalHaIntervals = 0;

    for (var z in totalHeaders) {
        const intervals = JSON.parse(totalHeaders[z]['headers']['x-index-range']);
        const gis = totalHeaders[z]['id'];

        if (!ha[gis]) { 
            ha[gis] = {
                'total': 0,
                'count': 0
            };
        }
        
        intervals.forEach(e => {
            if (!totalIntervals[e['cluster']]) {
                totalIntervals[e['cluster']] = {
                    'total': 0,
                    'count': 0
                };
            }

            totalIntervals[e['cluster']]['count'] += 1;
            totalIntervals[e['cluster']]['total'] += parseFloat(e['ha']);
            totalHaIntervals += parseFloat(e['ha']);
            ha[gis]['total'] += parseFloat(e['ha']);
        });

        ha[gis]['count'] += 1;
    }

    const intervals = [];
    Object.keys(totalIntervals).forEach(key => {
        const haInterval = totalIntervals[key]['total'] / totalIntervals[key]['count']; 
        const percentage = (totalIntervals[key]['total'] * 100) / totalHaIntervals;
        intervals.push({
            cluster: key,
            ha: haInterval,
            percentage
        });
    });

    let totalHa = 0;
    Object.keys(ha).forEach(key => {
        totalHa += (ha[key]['total'] / ha[key]['count']);
    });
    
    return {'x-index-range': JSON.stringify(intervals), 'x-total-ha': totalHa};
};


const EVI = ({ land, selectedDate }) => {
    const { errorHandler } = useErrorHandler();
    const mapLayers = useMapLayersV2();
    const viewImageContext = useViewImageContext();
    const cancelTokenRequest = axios.CancelToken.source();
    const mapLegendContext = useMapLegendContext();
    const executing = useRef(false);

    const deleteOldImage = () => {
        mapLayers.land.deleteImage({ landId: land.land_id, imageType: "evi" });
    }

    const loadImage = useCallback(async () => {
        if (executing.current) {
            return;
        }

        executing.current = true;
        viewImageContext.setLoading(true);  
        try {
            mapLayers.fit(land.land_id);
            let enhanced = !mapLegendContext.enhanced[land.land_id] ? false : true
            const res = await errorHandler(LandService.getLayerV2(land.land_id, "evi", {
                date: format(selectedDate, 'yyyy-MM-dd'),
                enhanced: enhanced
            }, cancelTokenRequest));

            const tif = await errorHandler(LandService.getLayerTiff(land.land_id, "evi", {
                date: format(selectedDate, 'yyyy-MM-dd')
            }, cancelTokenRequest));
            viewImageContext.addLandTiff(land.land_id, tif.data);

            if (res.ok) {
                const url = window.URL || window.webkitURL;
                const src = url.createObjectURL(res.data);
                mapLayers.land.addImage({
                    landId: land.land_id,
                    imageType: "evi",
                    imageUrl: src,
                    polygon: land.polygons,
                    bbox: JSON.parse(res.headers["x-bbox"]),
                    tif: tif.data
                });

                mapLegendContext.changeBasicLegend(land.land_id, parseHeaders([{id: land.land_id, date: selectedDate, headers: res.headers}]));
            }

        } catch (e) {
            console.error(e);
        }

        viewImageContext.setLoading(false);
        executing.current = false;
    }, [land, selectedDate, mapLegendContext.enhanced[land.land_id]])

    useEffect(() => {
        loadImage();
    }, [land, selectedDate]);

    useEffect(() => {
        deleteOldImage();
        loadImage();
    }, [mapLegendContext.enhanced[land.land_id]]);

    useEffect(() => {
        return () => {
            mapLegendContext.deleteLegend(land.land_id);
            executing.current = false;
            deleteOldImage();
        };
    }, []);

    return (
        <>
        </>
    );
};

export default EVI;