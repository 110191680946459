import { useMemo } from "react";
import { parseISO, format, differenceInDays, parse } from "date-fns";
import Chart from "react-apexcharts";

import { useViewNDVIContext } from "../ViewNDVIContext";

const TotalAvgHistory = () => {
  const context = useViewNDVIContext();
  const chartConfig = useMemo(() => {
    const report = Object.assign({}, context.report);
    let dates = new Set();
    const lands = {};

    const reportDates = report.ndvi_land_dates;
    for (var z in reportDates) {
      const current = reportDates[z];
      if (current.usage != "USED") {
        continue;
      }

      let currentDate = null;
      if (current.date instanceof Date) {
        currentDate = current.date;
      } else {
        currentDate = parse(current.date, "yyyy-LL-dd'T'HH:mm:ss", new Date());
      }

      dates.add(format(currentDate, "yyyy-LL-dd"));

      if (!lands[current.land_id]) {
        lands[current.land_id] = [];
      }

      lands[current.land_id].push(current);
    }

    dates = [...dates]
    dates.sort()
    const totalSeries = []

    report.lands.forEach(land => {
      if (!lands[land.land_id]) {
        return;
      }

      const reportDates = lands[land.land_id].map(report => {
        const n = Object.assign({}, report);
        n["date"] = parseISO(report["date"]);
        return n;
      });

      const series = []
      dates.forEach(date => {
        const parsedDate = parse(date, "yyyy-LL-dd", new Date());
        let currentDiff = Number.MAX_VALUE;
        let choiced = null;

        reportDates.forEach(report => {
          const diff = Math.abs(differenceInDays(parsedDate, report.date));
          if (diff < currentDiff) {
            currentDiff = diff;
            choiced = report;
          }
        });

        if (choiced != null) {
          series.push(choiced.avg_index);
        }
      });

      if (series.length < 1) {
        return;
      }

      totalSeries.push({
        name: land.field.name + " / " + land.name,
        data: series
      });
    });

    return {
      options: {
        chart: {
          id: "avg-history"
        },
        xaxis: {
          categories: Array.from(dates).map(e => format(parse(e, "yyyy-LL-dd", new Date()), "dd/LL/yyyy"))
        },
        yaxis: {
          decimalsInFloat: 3,
        }
      },
      series: totalSeries,
    };
  }, [context.report]);

  return (
    <>
      <Chart
        options={chartConfig.options}
        series={chartConfig.series}
        type="line"
        width="100%"
      />
    </>
  )
}

export default TotalAvgHistory;