import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loading } from '../../../../../../../_metronic/elements/Loading';
import { useViewFieldContext } from '../../ViewFieldContext';
import Gap from './Gap';

const ListGaps = () => {
  const { t } = useTranslation();
  const { field, gaps, loadingGaps } = useViewFieldContext();
  const totalHa = useMemo(() => {
    if (gaps.length < 1) {
      return 0
    }

    let total = 0;
    gaps.forEach(e => {
      total += parseFloat(e.ha);
    });

    return total;
  }, [gaps]);

  return (
    <>
      <div className="card shadow-sm field-view-container right-card-data">
        <div className="card-header collapsible">
          <div data-bs-toggle="collapse" data-bs-target="#kt_list_gaps_collapsible" className="card-title cursor-pointer">
            <div className="d-flex flex-column">
              <div className="d-flex align-items-center">
                <h4>{t("gaps:list.title")}</h4>
              </div>
            </div>
            <span class="text-gray-400 pt-1 fw-semibold fs-7" style={{ marginLeft: '10px' }}>{t("gaps:list.total_ha", { hectareas: parseFloat(totalHa).toFixed(2) })}</span>
          </div>
        </div>
        <div id="kt_list_gaps_collapsible" className="collapse show">
          <div className={`card-body ${loadingGaps ? 'p-5' : 'p-0'}`}>
            {loadingGaps && (
              <Loading />
            )}
            {(!loadingGaps && gaps.length < 1) && (
              <>
                <h5 className="m-5">{t("gaps:list.empty")}</h5>
              </>
            )}
            {(!loadingGaps && gaps.length >= 1) && gaps.map(gap => {
              return <Gap gap={gap} field={field} />
            })}
          </div>
        </div>
      </div>
    </>
  );
};

const ListGapsContainer = () => {

  return (
    <>
      <div className="d-flex flex-column flex-row-auto field-view-flex-container displayable">
        <div className="d-flex flex-column-auto">
          <ListGaps />
        </div>
      </div>
    </>
  );
};

export default ListGapsContainer;