import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useAmbientDialogContext } from "../AmbientDialogContext";

const Year = () => {
    const { years, setYears, monthFrom, monthTo, disabled } = useAmbientDialogContext();
    const { t } = useTranslation();
    const yearsList = useMemo(() => {
        const total = [];
        for (var i = 2013; i <= new Date().getFullYear(); i++) {
            total.push(i);
        }
        return total;
    }, []);
    const showMultiple = useMemo(() => {
        if (parseInt(monthFrom) > parseInt(monthTo)) {
            return true;
        } else {
            return false;
        }
    }, [monthFrom, monthTo]);

    const handleChange = (year) => {
        setYears(y => {
            let n = [...y];
            if (n.indexOf(year) !== -1) {
                n.splice(n.indexOf(year), 1);
            } else {
                n.push(year);
            }
            return n;
        })
    };


    return (
        <>

            <p className="fs-7 text-center fw-bold mb-3">{t("ambient:create.input.years.label")}</p>
            <div className="" >
                {yearsList.map(year => {
                   return (
                        <div className="form-check form-check-custom form-check-solid me-3 mb-3" style={{float: 'left'}}>
                            <input checked={years.indexOf(year) !== -1} onChange={() => { handleChange(year) }} disabled={disabled} className="form-check-input h-30px w-30px" name="year" type="checkbox" id={`year-${year}`} />
                            <label className="form-check-label" for={`year-${year}`}>
                                {showMultiple ? `${year}-${year + 1}` : year}
                            </label>
                        </div>
                   );
                })}
            </div>
        </>
    );
};

export default Year;