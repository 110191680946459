import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Modal } from "../../../../../_metronic/elements/Modal";
import { useViewFieldContext } from "../ViewField/ViewFieldContext";
import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";
import { useMapLayersV2 } from "../../Map/MapLayersV2Context";
import * as FieldService from "../services/FieldService";
import { useSearchContext } from "../../Toolbar/SearchContext";
import { t } from "i18next";

const ModalHeader = () => {
    const { field } = useViewFieldContext();
    const { t } = useTranslation();

    return (
        <div className="modal-header p-5">
            <h5 className="modal-title">{t("fields:delete.title", { name: field.name })}</h5>
        </div>
    );
};

const ModalBody = () => {
    const { t } = useTranslation();

    return (
        <div className="modal-body p-5">
            <div className="row">
                <div className='col-lg-12 fv-row'>
                    {t("fields:delete.messages.lands_will_be_deleted")}
                </div>
            </div>
        </div>
    );
};

const ModalFooter = ({ deleteField, loading, setOpen }) => {
    const { t } = useTranslation();

    return (
        <div className="modal-footer">
            <button disabled={loading == true} type="button" className="btn btn-light" onClick={() => setOpen(false)}>
                {t("fields:delete.buttons.cancel")}
            </button>
            <button onClick={deleteField} type="button" className="btn btn-danger">
                {!loading && t("fields:delete.buttons.delete")}
                {loading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                    {t("fields:delete.messages.deleting")}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
            </button>
        </div>
    );
};

const DeleteField = ({ open, setOpen }) => {
    const [ loading, setLoading ] = useState(false);
    const { field_id } = useParams();
    const { errorHandler } = useErrorHandler();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const mapLayers = useMapLayersV2();
    const search = useSearchContext();

    const deleteField = async () => {
        setLoading(true);
        const res = await errorHandler(FieldService.deleteField(field_id));
        mapLayers.reloadSource();
        search.load();

        if (res.ok) {
            navigate(`/gis`);
            openAlert('success', t("fields:delete.messages.deleted"), '');
        }

        setLoading(false);
    };

    return (
        <>
            <Modal open={open} setOpen={setOpen}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <ModalHeader />
                        <ModalBody />
                        <ModalFooter deleteField={deleteField} setOpen={setOpen} loading={loading} />
                    </div>
                </div>
            </Modal>
        </>
    )
};

export default DeleteField;