import { useState, useCallback, useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { useModalContext } from "../../../../../../../_metronic/elements/Modal";
import { useImageAmbient } from "./ImageAmbientContext";
import { openAlert } from "../../../../../../../_metronic/elements/NotificationAlert";
import { Loading } from "../../../../../../../_metronic/elements/Loading";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewLandContext } from "../ViewLandContext";
import { useViewImageContext } from "../Images/ViewImageContext";
import { useMapLegendContext } from '../Helpers/MapLegend';
import { useViewAmbientContext } from "../Ambients/AmbientsContext";
import { format } from "date-fns";
import ImageAmbientForm from "./ImageAmbientForm";
import * as LandService from "../../../services/LandService";
import { useTranslation } from "react-i18next";
import { StepButton } from "@material-ui/core";

const clsPresc = {
  0: 'mbp',
  1: 'bp',
  2: 'pp',
  3: 'ap',
  4: 'map',
  5: 'five',
  6: 'six'
};

const clsPrescInv = {
  'mbp': 0,
  'bp': 1,
  'pp': 2,
  'ap': 3,
  'map': 4,
  'five': 5,
  'six': 6
};

const ModalHeader = ({setNextStep}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-header modal-header-with-actions">
      <button
        className="btn btn-light me-3" // 'me-3' adds margin to separate button from title
        onClick={(e) => {
          e.preventDefault();
          setNextStep(false);
        }}
        aria-label="Go back"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
          <path fillRule="evenodd" d="M15 8a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708L2.707 7.5H14.5A.5.5 0 0 1 15 8z"/>
        </svg>
      </button>
        <h5 className="modal-title">{t("img_ambient:creation.title")}</h5>
      </div>
    </>
  );
};

const ModalFooter = ({ downloading }) => {
  const modal = useModalContext();
  const { t } = useTranslation();

  return (
    <div className="modal-footer">
      <button disabled={downloading} type="button" className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("img_ambient:creation.buttons.cancel")}
      </button>
      <button disabled={downloading} type="submit" className="btn btn-primary">
        {t("img_ambient:creation.buttons.save_ambientation")}
      </button>
    </div>
  )
}

const ImageAmbientDialogClasses = ({ open, setOpen, ambient, numClasses, setNextStep }) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [img_ambient, setImageAmbient] = useState({});
  const [geojson_preview, setGeojsonPreview] = useState({});
  const [bounding_box, setBoundingBox] = useState([]);
  const { prod, columns } = useImageAmbient();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();
  const isAmbientActive = useMatch('/gis/field/view/:field_id/land/view/:land_id/ambients');
  const viewLandContext = useViewLandContext();
  const viewImageContext = useViewImageContext();
  const mapLegendContext = useMapLegendContext();
  const { loadImageAmbientData, setSelectedElement } = useViewAmbientContext();
  const navigate = useNavigate();
  const modal = useModalContext();

  const load = useCallback(async () => {
    setLoading(true);

    const img_amb = {
      map: { ha: 0 },
      ap: { ha: 0 },
      pp: { ha: 0 },
      bp: { ha: 0 },
      mbp: { ha: 0 },
      five: { ha: 0 },
      six: { ha: 0 }
    };

    console.log("ambient in PrescriptionDialogClasses", ambient);
    
    

    if (!isAmbientActive || !ambient) {
      const imageLand = viewImageContext.getLand(viewLandContext.land.land_id);

      if (imageLand == null) {
        return;
      }

      const res = await errorHandler(LandService.classifyPrescription(viewLandContext.land.land_id, {
        layer: imageLand['imageType'] == 'evi' ? 'evi' : 'ndvi',
        date: format(imageLand['selectedDate'], 'yyyy-MM-dd'),
        num_classes: numClasses
      }));

      for (var z in res.data.data.classes) {
        const { cls, sup_ha } = res.data.data.classes[z];
        img_amb[clsPresc[cls]]['ha'] += sup_ha;
        img_amb[clsPresc[cls]]['cls'] = cls;
      }
      
      const geojson_preview = res.data.data.preview;
      const bounding_box = res.data.data.bounding_box;
      setGeojsonPreview(geojson_preview);
      setBoundingBox(bounding_box);

    } else {
      ambient.geojson.features.forEach(e => {
        img_amb[clsPresc[e.properties.cls]]['ha'] += e.properties.sup_ha;
        img_amb[clsPresc[e.properties.cls]]['cls'] = e.properties.cls;
      });
    }

    setImageAmbient(img_amb);
    setLoading(false);
  }, [ambient, viewLandContext.land, isAmbientActive]);

  useEffect(() => {
    load();
  }, [ambient]);

  const handleGeneration = async (e) => {
    e.preventDefault();
    setDownloading(true);
    

    let res;
   
    const imageLand = viewImageContext.getLand(viewLandContext.land.land_id);
    const totalRequest = {
      layer: imageLand['imageType'] == 'evi' ? 'evi' : 'ndvi',
      layer_date: format(imageLand['selectedDate'], 'yyyy-MM-dd'),
      num_classes: numClasses,
      geojson: geojson_preview
    };
    console.log(viewLandContext.land.land_id);
    res = await errorHandler(LandService.createImageAmbient(viewLandContext.land.land_id, totalRequest ));
  

    if (res.ok) { 
      // TODO - LOAD AND FOCUS ON THE MAP
      openAlert('success', t("img_ambient:creation.success.title"), t("img_ambient:creation.success.message"));
      modal.hideModal();
      mapLegendContext.deleteAll();
      viewImageContext.closeAllImages();
      await loadImageAmbientData();
      setSelectedElement("imageAmbient");
      
      navigate(`/gis/field/view/${viewLandContext.land.field.field_id}/land/view/${viewLandContext.land.land_id}/ambients`);


    }

    setDownloading(false);
  }


  if (loading) {
    return <div className="p-5 flex"><Loading/></div>;
  }

  if (img_ambient == null || Object.keys(img_ambient).length < 1) {
    return (<></>);
  }

  return (
    <>
      <form onSubmit={handleGeneration} className='form'>
        <ModalHeader setNextStep={setNextStep} />
        <div className="modal-body">
          {!loading && <ImageAmbientForm numClasses={numClasses} img_ambient={img_ambient} 
          geojson_preview={geojson_preview} bounding_box={bounding_box}/>}
        </div>
        <ModalFooter downloading={downloading} />
      </form>
    </>
  );
};

export default ImageAmbientDialogClasses;