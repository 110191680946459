import { useTranslation } from "react-i18next";
import { useAmbientDialogContext } from "../AmbientDialogContext";

const Season = () => {
    const { season, setSeason, disabled } = useAmbientDialogContext();
    const { t } = useTranslation();

    return (
        <>
            <p className="fs-7 text-center fw-bold mb-3">{t("ambient:create.season.title")}</p>
            <div className="d-flex flex-center">
                {['Estival', 'Invernal'].map(i => {
                   return (
                        <div className="form-check form-check-custom form-check-solid me-3">
                            <input disabled={disabled} checked={season == i} onChange={() => setSeason(i)} className="form-check-input h-30px w-30px" name="season" type="radio" value={i} id={`season-${i}`} />
                            <label className="form-check-label" for={`season-${i}`}>
                                {i == "Estival" && t("ambient:create.season.summer")}
                                {i == "Invernal" && t("ambient:create.season.wintry")}
                            </label>
                        </div>
                   );
                })}
            </div>
        </>
    );
};

export default Season;