import { createContext, useContext, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { format, differenceInMonths } from "date-fns";

import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../../_metronic/elements/NotificationAlert";
import { useNDVIReportContext } from "../NDVIReportContext";
import { useSearchContext } from "../../../Toolbar/SearchContext";
import * as ReportService from "../../services/ReportService";
import { useTranslation } from "react-i18next";

const CreateNDVIReportContext = createContext();

export function useCreateNDVIReportContext() {
  return useContext(CreateNDVIReportContext);
}

export const CreateNDVIReportConsumer = CreateNDVIReportContext.Consumer;

export function CreateNDVIReportProvider({ children }) {
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const { groupedOptions } = useSearchContext();
  const [name, setName] = useState(`Reporte NDVI ${format(new Date(), "dd/LL/yyyy")}`);
  const [selected, setSelected] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const { load } = useNDVIReportContext();
  const navigate = useNavigate();

  const count = useMemo(() => {
    return Object.values(selected).filter(t => t).length;
  }, [selected]);

  const validate = (newCount) => {
    if (newCount + count > 25) {
      openAlert("danger", t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.errors.max_lands"));
      return false;
    }

    return true;
  };

  const updateLandSelected = (field, land) => {
    const selectedCopy = { ...selected };
    if (!selectedCopy[land.id] && !validate(1)) {
      selectedCopy[land.id] = false;
      setSelected(selectedCopy);
      return;
    }
    selectedCopy[land.id] = !selectedCopy[land.id];
    setSelected(selectedCopy);
  };

  const updateFieldSelected = (field) => {
    const selectedCopy = { ...selected };
    const allSelected = field.lands.every(l => selected[l.id]);

    let lands = [];
    groupedOptions.forEach(f => {
      if (f.field_id == field.field_id) {
        lands = f["lands"];
      }
    });

    if (!allSelected && !validate(lands.length)) {
      lands.forEach(l => {
        selectedCopy[l.id] = false;
      });
      setSelected(selectedCopy);
      return;
    }

    groupedOptions.forEach(f => {
      if (f.field_id == field.field_id) {
        f["lands"].forEach(l => {
          selectedCopy[l.id] = !allSelected;
        });
      }
    });
    setSelected(selectedCopy);
  };

  const onSubmit = () => {
    if (name == null || name.length < 1) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.name.errors.required"));
      return;
    }

    if (name.length > 32) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.name.errors.max"));
      return;
    }

    if (fromDate == null || toDate == null) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.dates.errors.required"));
      return;
    }

    if (fromDate > toDate) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.dates.errors.from_date_gt"));
      return;
    }

    if (differenceInMonths(toDate, fromDate) < 1) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.dates.errors.at_least_one_month"));
      return;
    }

    if (differenceInMonths(toDate, fromDate) > 6) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.dates.errors.max_6_months"));
      return;
    }

    if (Object.values(selected).filter(e => e).length < 2) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.lands.errors.required"));
      return;
    }

    if (Object.values(selected).filter(e => e).length > 25) {
      openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.inputs.lands.errors.max"));
      return;
    }

    const data = {
      name,
      "from_date": format(fromDate, "yyyy-LL-dd"),
      "to_date": format(toDate, "yyyy-LL-dd"),
      "land_ids": Object.keys(selected).filter(k => selected[k])
    };

    setLoading(true);
    errorHandler(ReportService.newNdviReport(data), [402]).then(res => {
      if (!res.ok) {
        if (res.error_type == "need_payment") {
          openAlert('danger', t("reports:ndvi.create.errors.title"), t("reports:ndvi.create.errors.need_payment"));
        }
        setLoading(false);
        return;
      }

      openAlert('info', t("reports:ndvi.create.success.title"), t("reports:ndvi.create.success.message", { name: name }));
      navigate("/gis/reports/ndvi");
      load();
    });
  };

  const value = {
    name, setName,
    selected,
    fromDate, setFromDate,
    toDate, setToDate,

    loading,
    onSubmit,
    updateLandSelected,
    updateFieldSelected
  };

  return (
    <>
      <CreateNDVIReportContext.Provider value={value}>
        {children}
      </CreateNDVIReportContext.Provider>
    </>
  )
};