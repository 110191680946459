import { createContext, useContext, useState, useEffect } from "react";

import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import * as ReportService from "../services/ReportService";

const NDVIReportContext = createContext();

export function useNDVIReportContext() {
  return useContext(NDVIReportContext);
}

export const NDVIReportConsumer = NDVIReportContext.Consumer;

export function NDVIReportProvider({ children }) {
  const { errorHandler } = useErrorHandler();
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const load = (notLoad) => {
    if (!notLoad) {
      setLoading(true);
    }
    errorHandler(ReportService.list(0, 20)).then(res => {
      if (res.ok) {
        setReports(res.data.ndvi_reports);
        let inprogress = false;
        res.data.ndvi_reports.forEach(report => {
          if (report.status == "in_progress") {
            inprogress = true;
          }
        });

        if (inprogress) {
          setTimeout(() => load(true), 5000)
        }
      }


      setLoading(false);
    });
  };

  useEffect(() => {
    load();
  }, []);

  const value = {
    reports,
    loading,
    load
  }

  return (
    <>
      <NDVIReportContext.Provider value={value}>
        {children}
      </NDVIReportContext.Provider>
    </>
  )
};