import { useEffect, useState } from "react";
import { Link, useParams, Routes, Route, useMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { KTSVG } from "../../../../../_metronic/helpers";
import { ListLands } from "./ListLands";
import { useViewFieldContext } from "./ViewFieldContext";
import { LoadingCard } from "../../../../../_metronic/elements/Loading";
import { ViewLand } from "./ViewLand";
import { ListGaps } from "./Gap/ListGaps";
import UpdateField from "../UpdateField";
import DeleteField from "../DeleteField";
import './ViewField.style.scss';
import { useMapLayersV2 } from "../../Map/MapLayersV2Context";

const FieldNotFound = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="card shadow-sm field-view-container">
                <div className="card-body">
                    <h5 className="m-0">{t("fields:errors.field_not_exists")}</h5>
                </div>
            </div>
        </>
    );
};

const NewLandButton = ({ field }) => {
    const { t } = useTranslation();
    const active = useMatch("/gis/field/view/:field_id/land/new");
    const isCreatingNoZoneAgro = useMatch("/gis/field/view/:field_id/gap/new");

    const attr = {}
    if (isCreatingNoZoneAgro) {
        attr["onClick"] = (e) => e.preventDefault();
    }

    return (
        <>
            <Link {...attr} to={`/gis/field/view/${field.field_id}/land/new`} className={"d-flex text-hover-primary align-items-center rounded p-5 " + ( active ? "bg-light-primary" : "" ) + " " + (isCreatingNoZoneAgro ? "disabled-link" : '')}>
                <KTSVG
                    path="/media/icons/duotune/files/fil005.svg"
                    className="svg-icon svg-icon-2x svg-icon-primary me-5"
                />
                <div className="flex-grow-1 me-2">
                    <span className="fw-bold text-gray-800 fs-6">{t("fields:buttons.add_lands")}</span>
                </div>
            </Link>
        </>
    )
};

const NewGapButton = ({ field }) => {
    const { t } = useTranslation();
    const active = useMatch("/gis/field/view/:field_id/gap/new");
    const isCreatingLand = useMatch("/gis/field/view/:field_id/land/new");

    const attr = {}
    if (isCreatingLand) {
        attr["onClick"] = (e) => e.preventDefault();
    }

    return (
        <>
            <Link {...attr} to={`/gis/field/view/${field.field_id}/gap/new`} className={"d-flex text-hover-primary align-items-center rounded p-5 " + ( active ? "bg-light-primary " : "" ) + " " + (isCreatingLand ? "disabled-link" : '')}>
                <KTSVG
                    path="/media/icons/duotune/maps/map008.svg"
                    className="svg-icon svg-icon-2x svg-icon-primary me-5"
                />
                <div className="flex-grow-1 me-2">
                    <span className="fw-bold text-gray-800 fs-6">{t("fields:buttons.add_no_agro_zone")}</span>
                </div>
            </Link>
        </>
    )
};

const ListLandsButton = ({ field }) => {
    const { t } = useTranslation();
    const active = useMatch("/gis/field/view/:field_id/");

    return (
        <>
            <Link to={`/gis/field/view/${field.field_id}/`} end className={"d-flex text-hover-primary align-items-center rounded p-5 " + (active ? 'bg-light-primary' : '')}>
                <KTSVG
                    path="/media/icons/duotune/files/fil001.svg"
                    className="svg-icon svg-icon-2x svg-icon-primary me-5"
                />
                <div className="flex-grow-1 me-2">
                    <span className="fw-bold text-gray-800 fs-6">{t("fields:buttons.lands")}</span>
                </div>
            </Link>
        </>
    )
};

const ViewField = () => {
    const { t } = useTranslation();
    const mapLayers = useMapLayersV2();
    const { field, loading } = useViewFieldContext();
    const [ updateField, setUpdateField ] = useState(false);
    const [ deleteField, setDeleteField ] = useState(false);

    if (loading) {
        return <LoadingCard className="field-view-container" />
    }

    if (!loading && field == null) {
        return <FieldNotFound />;
    }

    return (
        <>
        <div className="d-flex flex-column-auto displayable">
            <div className="card shadow-sm field-view-container right-card-data">
                <div className="card-header collapsible">
                    <h4 className="card-title cursor-pointer" style={{width: "125px"}} data-bs-toggle="collapse" data-bs-target="#kt_view_field_collapsible">{field.name}</h4>
                    <div className="card-toolbar">
                        <button type="button" onClick={() => mapLayers.fit(field.field_id)} class="btn btn-color-muted btn-active-color-primary btn-sm me-2" style={{paddingRight: 0, paddingLeft: 0}}>
                            <KTSVG
                                path="/media/icons/duotune/maps/map007.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </button>

                        <button type="button" onClick={() => setDeleteField(true)} className="btn btn-sm btn-light-danger me-2">
                            {t("fields:buttons.delete")}
                        </button>
                        <button type="button" onClick={() => setUpdateField(true)} className="btn btn-sm btn-light-primary">
                            {t("fields:buttons.update")}
                        </button>
                    </div>
                </div>
                <div id="kt_view_field_collapsible" className="collapse show">
                    <div className="card-body" style={{padding: 0, paddingTop: '0px'}}>
                        <ListLandsButton field={field} />
                        <NewLandButton field={field} />
                        <NewGapButton field={field} />
                    </div>
                </div>
            </div>
        </div>
        <div className="d-flex flex-column-auto mt-4">
            <UpdateField open={updateField} setOpen={setUpdateField} />
            <DeleteField open={deleteField} setOpen={setDeleteField} />
            <Routes>
                <Route path="/" element={<ListLands />} />
                <Route path="/land/view/:land_id/*" element={<ViewLand />} />
            </Routes>
        </div>
        <div className="d-flex flex-column-auto mt-4">
            <Routes>
                <Route path="/" element={<ListGaps />} />
            </Routes>
        </div>
        </>
    );
};

const ViewFieldContainer = () => {
    const viewFieldContext = useViewFieldContext();
    const { field_id }  = useParams();

    useEffect(() => {
      viewFieldContext.setFieldId(field_id);
    }, [field_id]);

    return (
        <>
            <div className="d-flex flex-column flex-row-auto field-view-flex-container">
                <ViewField />
            </div>
        </>
    );
};

export default ViewFieldContainer;