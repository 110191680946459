import { Routes, Route } from "react-router-dom";

import ViewAccountInformation from "./ViewAccountInformation";
import UpdateAccountInformation from "./UpdateAccountInformation/UpdateAccountInformation";

const AccountInformation = () => {

    return (
        <>
            <Routes>
                <Route path="/update" element={<UpdateAccountInformation />} />
                <Route index element={<ViewAccountInformation />} />
            </Routes>
        </>
    );
};

export default AccountInformation;