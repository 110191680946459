
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import {
    CircleMode,
    DragCircleMode,
    DirectMode,
    SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import CutPolygonMode from 'mapbox-gl-draw-cut-polygon-mode';
import mapboxGlDrawPassingMode from 'mapbox-gl-draw-passing-mode';
import DrawStyle from "./DrawStyle";

const DrawStatus = {
    NOTSET: "notset",
    DRAWING: "drawing"
};

export const DrawTools = {
    POLYGON: "polygon",
    CIRCLE: "circle",
    POINT: "point",
    UPLOAD_FILE: "uploadFile"
};

class DrawState {
    constructor() {
        this.map = null;
        this.mapState = null;
        this.draw = null;

        this.status = DrawStatus.NOTSET;
        this._canTrashVertices = false;
        this._mode = null;
        this.enabledTools = [];
        this.onCreate = null;
        this.onUpdate = null;
        this.onDelete = null;
    }

    init(mapState, map) {
        this.map = map;
        this.mapState = mapState;
        this.draw = new MapboxDraw({
            displayControlsDefault: false,
            styles: DrawStyle,
            modes: Object.assign({
                draw_circle: CircleMode,
                drag_circle: DragCircleMode,
                direct_select: DirectMode,
                simple_select: SimpleSelectMode,
                static: StaticMode,
                cutPolygonMode: CutPolygonMode,
                passing_mode_polygon: mapboxGlDrawPassingMode(
                    MapboxDraw.modes.draw_polygon
                ),
            }, MapboxDraw.modes)
        });
        map.addControl(this.draw);
        this._setEvents();
    }

    start({ tools, onCreate, onUpdate, onDelete }) {
        this.status = DrawStatus.DRAWING;
        this.enabledTools = tools;
        this.onCreate = onCreate;
        this.onUpdate = onUpdate;
        this.onDelete = onDelete;
    }

    end() {
        this.status = DrawStatus.NOTSET;
        this.enabledTools = [];
        this.onCreate = null;
        this.onUpdate = null;
        this._canTrashVertices = false;
        this._mode = null;
    }

    forceCallback(arg1, arg2) {
        if (this.onCreate != null) {
            this.onCreate(arg1, arg2);
        }
    }

    get(featureId) {
        return this.draw.get(featureId);
    }

    add(polygon) {
        return this.draw.add(polygon)[0];
    }

    onDraw() {
        const $this = this;
        return (e) => {
            if (e.type == 'draw.create') {
                if (typeof $this.onCreate == 'function') {
                    $this.onCreate(e);
                }
            } else if (e.type == 'draw.update') {
                if (typeof $this.onUpdate == 'function') {
                    $this.onUpdate(e);
                }
            }
        };
    };

    onDeleteEvent() {
        const $this = this;
        return (e) => {
            if (e.type == 'draw.delete') {
                if (typeof $this.onDelete == 'function') {
                    $this.onDelete(e);
                }
            }
        };
    };

    _setEvents() {
        const $this = this;
        this.map.on('dblclick', e => {
            if ($this._canTrashVertices) {
                $this.draw.trash()
            }
        });
        this.map.on('draw.modechange', (e) => {
            if (e.mode.simple_select == 'simple_select') {
                $this.map.getCanvas().style.cursor = 'unset';
            }
        });
        this.map.on('draw.create', this.onDraw());
        this.map.on('draw.update', this.onDraw());
        this.map.on('draw.delete', this.onDeleteEvent());
        this.map.on('draw.actionable', (e) => {
            if (e.actions.trash) {
                $this._canTrashVertices = true;
            }
        });
        this.map.on('draw.modechange', (e) => {
            if (e.mode.simple_select == 'simple_select') { 
                $this.map.getCanvas().style.cursor = 'unset'; 
            } 
        });
    }

    mode(_mode) {
        if (this.isDrawing == false) {
            throw new Error("You can't set a mode if its not drawing");
        }

        this._mode = _mode;

        let desired_mode;
        if (_mode == DrawTools.POLYGON) {
            desired_mode = "draw_polygon";
        } else if (_mode == DrawTools.CIRCLE) {
            desired_mode = "drag_circle";
        } else if (_mode == DrawTools.POINT) {
            desired_mode = "draw_point";
        }
        
        this.draw.changeMode(desired_mode);

        if (_mode == DrawTools.CIRCLE) {
            this.map.getCanvas().style.cursor = "crosshair";
        }
    }

    directSelect(featureId) {
        this.draw.changeMode('direct_select', { featureId });
    }
    
    deleteLayer(layerId) {
        this.draw.delete(layerId);
    }

    deleteAll() {
        if (this.draw != null) {
            this.draw.deleteAll();
        }
    }

    getAll() {
        return this.draw.getAll();
    }

    isDrawing() {
        return this.status == DrawStatus.DRAWING;
    }

    acceptPolygon() {
        return this.enabledTools.indexOf(DrawTools.POLYGON) != -1;
    }

    acceptCircle() {
        return this.enabledTools.indexOf(DrawTools.CIRCLE) != -1;
    }

    acceptFile() {
        return this.enabledTools.indexOf(DrawTools.UPLOAD_FILE) != -1;
    }
};

export const drawState = new DrawState();