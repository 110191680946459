import { useTranslation } from "react-i18next";

import { LoadingCard } from "../../../../../../../../_metronic/elements/Loading";
import { useViewAmbientContext } from "../AmbientsContext";


const CreatingAmbient = () => {
    const { ambientData } = useViewAmbientContext();
    const { t } = useTranslation();

    if (ambientData.progress * 100 < 1) {
        return (
            <LoadingCard loadingText={t("ambient:create.messages.in_queue")} />
        );
    }
    
    return (
        <>
            <LoadingCard loadingText={t("ambient:create.messages.creating") + " " + (parseInt((ambientData.progress * 100))) + "%"} />
        </>
    );
};

export default CreatingAmbient;