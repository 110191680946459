import { useTranslation } from "react-i18next";
import { InputText } from "../../../../_metronic/elements/Inputs";


const ChangePasswordForm = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <InputText
            inputProps={{
              type: 'password',
              placeholder: t("account:change_password.inputs.old_password.placeholder")
            }}
            disabled={formik.isSubmitting}
            name="old_password"
            formik={formik} />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <InputText
            inputProps={{
              type: 'password',
              placeholder: t("account:change_password.inputs.new_password.placeholder")
            }}
            disabled={formik.isSubmitting}
            name="new_password"
            formik={formik} />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-12">
          <InputText
            inputProps={{
              type: 'password',
              placeholder: t("account:change_password.inputs.repeat_new_password.placeholder")
            }}
            disabled={formik.isSubmitting}
            name="repeat_new_password"
            formik={formik} />
        </div>
      </div>

    </>
  )
};

export default ChangePasswordForm;