import { useMemo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../../../../../../_metronic/elements/Loading";
import { format, parse } from "date-fns";

import { KTSVG } from "../../../../../../../_metronic/helpers";

const ModalHeader = ({ campaign }) => {
    const { field_id, land_id } = useParams();
    const navigate = useNavigate();
    const dateRepresentation = format(parse(campaign.sowing_date, "yyyy-MM-dd'T'HH:mm:ss", new Date()), "dd/MM/yyyy")

    return (
        <div className="modal-header p-5">
            <h5 className="modal-title">Campaña - {dateRepresentation}</h5>
            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => navigate(`/gis/field/view/${field_id}/land/view/${land_id}/natalseeds/campaign`)} aria-label="Close">
                <KTSVG
                    path="/media/icons/duotune/arrows/arr061.svg"
                    className="svg-icon svg-icon-2x"
                />
            </div>
        </div>
    );
};

const ModalBody = ({ campaign }) => {
    
    const toDateRepresentation = (date) => {

        return format(parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date()), "dd/MM/yyyy")
    };

    const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en-US', {minimumFractionDigits: 0}).replaceAll(",", ".");
    };

    const isNotEmpty = (q) => {
        return q != "" && q != null;
    }

    return (
        <>
        <div className="modal-body p-5">
            <div className="row">
                <div className='col-lg-12'>
                    <p><span className="fw-bolder">AGENTE:</span> {campaign.agent}</p>
                    <p><span className="fw-bolder">CLIENTE:</span> {campaign.client}</p>
                </div>
                <div className='col-lg-12 mt-2'>
                    <p className="fw-bolder" style={{color: "var(--kt-secondary)"}}>VISITA POSTSIEMBRA</p>
                    {isNotEmpty(campaign.date_after_sowing) && (<p><span className="fw-bolder">FECHA DE VISITA POSTSIEMBRA:</span> {toDateRepresentation(campaign.date_after_sowing)}</p>)}
                    {isNotEmpty(campaign.hybrid) && (<p><span className="fw-bolder">HÍBRIDO:</span> {campaign.hybrid}</p>)}
                    {isNotEmpty(campaign.sowing_date) && (<p><span className="fw-bolder">FECHA DE SIEMBRA:</span> {toDateRepresentation(campaign.sowing_date)}</p>)}
                    {isNotEmpty(campaign.theory_density_sowing) && (<p><span className="fw-bolder">DENSIDAD TEORICA DE SIEMBRA (pl/ha):</span> {formatNumber(campaign.theory_density_sowing)}</p>)}
                    {isNotEmpty(campaign.final_density_sowing) && (<p><span className="fw-bolder">DENSIDAD LOGRADA (pl/ha):</span> {formatNumber(campaign.final_density_sowing)}</p>)}
                    {isNotEmpty(campaign.observations_after_sowing) && (<p><span className="fw-bolder">OBSERVACIONES:</span> {campaign.observations_after_sowing}</p>)}
                </div>

                <div className='col-lg-12 mt-2'>
                    <p className="fw-bolder" style={{color: "var(--kt-secondary)"}}>VISITA FLORACÍON</p>
                    {isNotEmpty(campaign.flowering_visit_date) && (<p><span className="fw-bolder">FECHA VISITA FLORACIÓN:</span> {toDateRepresentation(campaign.flowering_visit_date)}</p>)}
                    {isNotEmpty(campaign.flowering_observation) && (<p><span className="fw-bolder">OBSERVACIONES:</span> {campaign.flowering_observation}</p>)}
                </div>


                <div className='col-lg-12 mt-2'>
                    <p className="fw-bolder" style={{color: "var(--kt-secondary)"}}>VISITA COSECHA</p>
                    {isNotEmpty(campaign.harvest_visit_date) && (<p><span className="fw-bolder">FECHA VISITA COSECHA:</span> {toDateRepresentation(campaign.harvest_visit_date)}</p>)}
                    {isNotEmpty(campaign.yield_grain) && (<p><span className="fw-bolder">RINDE GRANO (kg/ha):</span> {formatNumber(campaign.yield_grain)}</p>)}
                    {isNotEmpty(campaign.yield_green_matter) && (<p><span className="fw-bolder">RINDE MATERIA VERDE (kg/ha):</span> {formatNumber(campaign.yield_green_matter)}</p>)}
                    {isNotEmpty(campaign.harvest_observation) && (<p><span className="fw-bolder">OBSERVACIONES:</span> {campaign.harvest_observation}</p>)}
                </div>

            </div>
        </div>
        </>
    );
};

const ViewNatalseedsCampaign = ({ loading, campaigns }) => {
    const { campaign_id, field_id, land_id } = useParams();
    const navigate = useNavigate();
    const campaign = useMemo(() => {
        const r = campaigns.filter(x => x.id == campaign_id);
        if (r.length < 1) {
            return null;
        }
        return r[0];
    }, [campaign_id, campaigns]);
    
    useEffect(() => {
        if (campaign == null) {
            navigate(`/gis/field/view/${field_id}/land/view/${land_id}/natalseeds/campaign`);
        }
    }, [campaign])

    if (loading) {
        return (
            <div className="p-5">
                <Loading />
            </div>
        );
    }
  
    return (
        <>
            <ModalHeader campaign={campaign} />
            <ModalBody campaign={campaign} />
        </>
    );
};

export default ViewNatalseedsCampaign;