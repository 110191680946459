import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import "./AmbientMapLegend.style.scss";

const getColor = (key, numClasses) => {
  return {
    2: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(26,150,65)'
    },
    3: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(255,255,192)',
      'pp': 'rgb(26,150,65)'
    },
    4: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(254,201,129)',
      'pp': 'rgb(196,230,135)',
      'ap': 'rgb(26,150,65)'
    },
    5: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(253,174,97)',
      'pp': 'rgb(255,255,192)',
      'ap': 'rgb(166,217,106)',
      'map': 'rgb(26,150,65)'
    },
    6: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(246,144,83)',
      'pp': 'rgb(255,223,154)',
      'ap': 'rgb(220,240,158)',
      'map': 'rgb(138,204,98)',
      'five': 'rgb(26,150,65)'
    },
    7: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(241,124,74)',
      'pp': 'rgb(254,201,129)',
      'ap': 'rgb(255,255,192)',
      'map': 'rgb(196,230,135)',
      'five': 'rgb(119,195,92)',
      'six': 'rgb(26,150,65)'
    }
  }[parseInt(numClasses)][key];
};

const getValidClasses = (numClasses) => {
  return {
    2: [
      'mbp',
      'bp'
    ],
    3: [
      'mbp',
      'bp',
      'pp'
    ],
    4: [
      'mbp',
      'bp',
      'pp',
      'ap'
    ],
    5: [
      'mbp',
      'bp',
      'pp',
      'ap',
      'map'
    ],
    6: [
      'mbp',
      'bp',
      'pp',
      'ap',
      'map',
      'five'
    ],
    7: [
      'mbp',
      'bp',
      'pp',
      'ap',
      'map',
      'five',
      'six'
    ]
  }[numClasses]
};


const AmbientMapLegend = ({ ambient }) => {
  const { t } = useTranslation();
  let formattedDate;
  if (ambient.params.layer_date !== undefined) {
    const date = new Date(ambient.params.layer_date);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());  

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    formattedDate = `${day}/${month}/${year}`;
  }
  
  const data = useMemo(() => {
    const base = {
      mbp: {
        sort: 6,
        ha: 0
      },
      bp: {
        sort: 5,
        ha: 0
      },
      pp: {
        sort: 4,
        ha: 0
      },
      ap: {
        sort: 3,
        ha: 0
      },
      map: {
        sort: 2,
        ha: 0
      },
      five: {
        sort: 1,
        ha: 0
      },
      six: {
        sort: 0,
        ha: 0
      }
    };

    let total = 0;
    [ambient].forEach(am => {
      am.geojson.features.forEach(e => {
        let currentSupha = e.properties.sup_ha;
        total += e.properties.sup_ha;
        switch (e.properties.cls) {
          case 0:
            base.mbp.ha += currentSupha
            break
          case 1:
            base.bp.ha += currentSupha
            break
          case 2:
            base.pp.ha += currentSupha
            break
          case 3:
            base.ap.ha += currentSupha
            break
          case 4:
            base.map.ha += currentSupha
            break
          case 5:
            base.five.ha += currentSupha
            break
          case 6:
            base.six.ha += currentSupha
            break
        };
      });
    });

    Object.keys(base).forEach(k => {
      base[k].percentage = (base[k].ha * 100) / total;
    });
    const numClass = Math.max.apply(Math, [ambient].map(e => e.params.breaks || e.params.num_classes));
    const arr = Object.keys(base).map(k => {
      base[k].cls = k;
      return base[k]
    }).sort((a, b) => a.sort > b.sort ? 1 : -1).filter(a => {
      return getValidClasses(numClass).indexOf(a.cls) !== -1;
    });
    return { data: arr, total, max: numClass };
  }, [ambient]);

  return (
    <>
      <div className="card shadow-sm mb-5 displayable right-card-data">
        <div className="card-header collapsible">
          <h3 className="card-title cursor-pointer" data-bs-toggle="collapse" data-bs-target="#kt_ambient_legend">
            {t("ambient:legend.title")}
          </h3>
        </div>
        <div id="kt_ambient_legend" className="collapse show">
          <div className="card-body">
            <div className="row legend-row legend-top-column">
              <div className="col-4 fw-bold fs-7">Mayor Productividad</div>
              <div className="col-4 fw-bold fs-7">ha</div>
              <div className="col-4 fw-bold fs-7">%</div>
            </div>
            {data['data'].map((e, index) => {
              return (
                <div className="row legend-row mb-2" key={`ambient-${ambient.ambient_id}-${index}`}>
                  <div className="col-4" style={{ paddingRight: '0px' }}>
                    <div className={`box-square-ambient`} style={{ backgroundColor: getColor(e.cls, data['max']) }}></div>
                  </div>
                  <div className="col-4">{parseFloat(e['ha']).toFixed(2)}</div>
                  <div className="col-4">{parseFloat(e['percentage']).toFixed(2)}%</div>
                </div>
              );
            })}
            <div className="row legend-row legend-top-column">
            <div className="col-4 fw-bold fs-7">Menor Productividad</div>
            </div>
            {ambient.params.layer_date && (
              <div className="fs-7">Fecha de la imagen EVI: {formattedDate}</div>
            )}
          </div>

        </div>
      </div>

    </>
  );
};

export default AmbientMapLegend;