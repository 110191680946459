/* eslint-disable jsx-a11y/anchor-is-valid */
import { useIntl } from 'react-intl';
import { Routes, Route, useParams } from "react-router-dom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { useMediaQuery } from 'react-responsive'
import DateFnsUtils from '@date-io/date-fns'; 

import { PageTitle } from '../../../_metronic/layout/core';
import { GisMap, GisMapProvider, DrawProvider } from './GisMap';
import { DrawV2Provider } from './Map/Draw/DrawV2Context';
import { GisProvider } from './GisContext';
import { LeftContainerFields, AnyContainerFields } from './Fields';
import { Content } from '../../../_metronic/layout/components/Content';
import { ViewFieldProvider } from './Fields/ViewField/ViewFieldContext';
import { ViewImageProvider } from './Fields/ViewField/ViewLand/Images/ViewImageContext';
import { useViewLandContext, ViewLandProvider } from './Fields/ViewField/ViewLand/ViewLandContext';
import { MapLayersProvider } from './Fields/MapLayers';
import { MapLayersV2Provider} from "./Map/MapLayersV2Context";
import { MapLegendProvider, useMapLegendContext, CommonMapLegend, AmbientMapLegend } from './Fields/ViewField/ViewLand/Helpers/MapLegend';
import { Toolbar, MobileContent } from './Toolbar';
import { SearchProvider } from './Toolbar/SearchContext';
import { LeftContainerReports } from './Reports';
import { DrawEndButtonProvider } from './Map/Draw/DrawEndButton/DrawEndButtonContext';
import DrawEndButton from './Map/Draw/DrawEndButton/DrawEndButton';
import RightDrawer from './RightDrawer';
import './Gis.style.scss';

const Legends = () => {
    const mapLegendContext = useMapLegendContext();
    const viewLandContext = useViewLandContext();

    return (
        <>
            {(mapLegendContext.basicLegend != null && viewLandContext.land && mapLegendContext.activeLand == viewLandContext.land.land_id) && <CommonMapLegend headers={mapLegendContext.basicLegend} />}
            {mapLegendContext.ambient != null && <AmbientMapLegend ambient={mapLegendContext.ambient} />}
        </>
    )
}

const ContentX = () => {
    return (
        <>
            <DrawEndButton />
            <Legends />
            <Routes>
                <Route path='/field/*' element={<LeftContainerFields />} />
                <Route path='/reports/*' element={<LeftContainerReports />} />
            </Routes>
        </>
    );
};

const UsingNonFlex = () => {
    const requireDrawer = useMediaQuery({
        query: '(max-width: 700px)'
    });

    return (

        <>
            {requireDrawer && (
                <RightDrawer>
                    <MobileContent />
                    <ContentX />
                </RightDrawer>
            )}
            {!requireDrawer && (

                <div className='right-gis-container pb-5'>
                    <div className="d-flex flex-column flex-row-fluid w-400px">
                        <ContentX />
                    </div>
                </div>
            )}
        </>
    )
};


const GisModule = () => {
    const intl = useIntl();
    const params = useParams();
    const requireDrawer = useMediaQuery({
        query: '(max-width: 700px)'
    });

    return (
        <>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <GisProvider>
                <GisMapProvider>
                    <SearchProvider>
                        <DrawProvider>
                            <DrawV2Provider>
                                <DrawEndButtonProvider>
                                    <MapLayersProvider>
                                        <MapLayersV2Provider>
                                                <ViewFieldProvider>
                                                    <ViewLandProvider>
                                                        <MapLegendProvider>
                                                            <ViewImageProvider>
                                                                <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
                                                                <div id='kt_content' className='p-0 content d-flex flex-column flex-column-fluid'>
                                                                    {!requireDrawer && <Toolbar />}
                                                                    <div className='post d-flex flex-column-fluid' id='kt_post'>
                                                                        <Content>
                                                                            <UsingNonFlex />
                                                                            <GisMap />
                                                                            <Routes>
                                                                                <Route path='/field/*' element={<AnyContainerFields />} />
                                                                            </Routes>
                                                                        </Content>
                                                                    </div>
                                                                </div>
                                                            </ViewImageProvider>
                                                        </MapLegendProvider>
                                                    </ViewLandProvider>
                                                </ViewFieldProvider>
                                            </MapLayersV2Provider>
                                    </MapLayersProvider>
                                </DrawEndButtonProvider>
                            </DrawV2Provider>
                        </DrawProvider>
                    </SearchProvider>
                </GisMapProvider>
            </GisProvider>
        </MuiPickersUtilsProvider>
        </>
    );
};

export { GisModule };
