import { useEffect, useMemo, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import { format } from "date-fns";

import { useSearchContext } from "../../../Toolbar/SearchContext";
import { Modal, useModalContext } from "../../../../../../_metronic/elements/Modal";
import { KTSVG } from "../../../../../../_metronic/helpers";
import { Loading } from "../../../../../../_metronic/elements/Loading";
import { useCreateNDVIReportContext, CreateNDVIReportProvider } from "./CreateNDVIReportContext";
import { useTranslation } from "react-i18next";

const ModalHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{t("reports:ndvi.create.title")}</h5>
      </div>
    </>
  );
};

const ModalFooter = () => {
  const modal = useModalContext();
  const { t } = useTranslation();
  const { loading, onSubmit } = useCreateNDVIReportContext();

  return (
    <div className="modal-footer">
      <button type="button" className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("reports:ndvi.create.buttons.cancel")}
      </button>
      <button onClick={onSubmit} disabled={loading} type="button" className="btn btn-primary">
        {t("reports:ndvi.create.buttons.create")}
      </button>
    </div>
  );
};

const Land = ({ field, land }) => {
  const { selected, updateLandSelected } = useCreateNDVIReportContext();

  return (
    <label className="d-flex align-items-center p-5 land-item cursor-pointer">
      <span className="bullet bullet-vertical h-40px bg-primary me-5"></span>
      <div className="flex-grow-1">
        <span className="text-gray-800 text-hover-primary fw-bold fs-6">{land.name}</span>
      </div>
      <input type="checkbox" checked={selected[land.id]} onClick={() => updateLandSelected(field, land)} />
    </label>
  );
};

const Field = ({ field }) => {
  const { selected, updateFieldSelected } = useCreateNDVIReportContext();

  return (
    <div className="accordion-item">
      <h2 style={{ position: "relative" }} className="accordion-header" id={"kt_accordion_1_header_1" + field.field_id}>
        <button className="accordion-button fs-4 fw-semibold collapsed fs-4 fw-semibold" type="button" data-bs-toggle="collapse" data-bs-target={"#kt_accordion_1_body_1" + field.field_id} aria-controls={"kt_accordion_1_body_1" + field.field_id}>
          {field.name}
        </button>
        <div style={{
          position: "absolute",
          top: "15px",
          right: "60px",
          zIndex: 50
        }} className="form-check form-switch form-check-custom form-check-solid">
          <input className="form-check-input" type="checkbox" checked={field.lands.every(l => selected[l.id])} onClick={() => updateFieldSelected(field)} id="flexRadioSm" />
        </div>
      </h2>
      <div id={"kt_accordion_1_body_1" + field.field_id} className="accordion-collapse collapse" aria-labelledby={"kt_accordion_1_header_1" + field.field_id} data-bs-parent={"#kt_accordion_1" + field.field_id}>
        <div className="accordion-body p-0">
          {field.lands.map(land => {
            return (
              <Land key={land.id} field={field} land={land} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

const ListLands = ({ fields }) => {
  const { t } = useTranslation();
  const fieldsFiltered = useMemo(() => fields.filter(field => field.lands.length >= 1), [fields]);

  return (
    <div className="accordion" id="kt_accordion_1">
      {fieldsFiltered.map(field => {
        return (
          <Field key={field.field_id} field={field} />
        );
      })}
      {fieldsFiltered.length < 1 && (
        <p>{t("reports:ndvi.create.errors.lands_not_exists")}</p>
      )}
    </div>
  );
};

const DateSelector = ({ date, setDate, dateText }) => {
  const [openedDatePicker, setOpenedDatePicker] = useState(false);

  return (
    <>
      <a onClick={(e) => {
        e.preventDefault();
        setOpenedDatePicker(true);
      }} href="#" className={`fw-bold text-gray-800 text-hover-primary fs-7 `}>

        <KTSVG
          path="/media/icons/duotune/general/gen014.svg"
          className="svg-icon svg-icon-2x svg-icon-primary me-5"
        />
        {!date || date == null ? dateText : format(
          date, 'dd/MM/yyyy'
        )}
      </a>
      <DatePicker
        open={openedDatePicker}
        value={date}
        onOpen={() => setOpenedDatePicker(true)}
        onClose={() => setOpenedDatePicker(false)}
        onChange={(x) => setDate(x)}
        shouldDisableDate={(date) => {
          return date > new Date();
        }}
        animateYearScrolling
        TextFieldComponent={() => null}
      />
    </>
  );
};

const ModalBody = ({ fields }) => {
  const { t } = useTranslation();
  const { selected, fromDate, setFromDate, toDate, setToDate, name, setName } = useCreateNDVIReportContext();
  const [search, setSearch] = useState(null);
  const [filteredFields, setFilteredFields] = useState(JSON.parse(JSON.stringify(fields)));
  const totalHa = useMemo(() => {
    let total = 0;
    const indexedLand = {};
    fields.forEach(field => {
      field.lands.forEach(e => {
        indexedLand[e.id] = e;
      })
    });
    Object.keys(selected).forEach(land_id => {
      if (selected[land_id]) {
        total += parseFloat(indexedLand[land_id].ha);
      }
    })
    return parseFloat(total).toFixed(2);
  }, [selected]);

  useEffect(() => {
    const filterIt = (prev) => {
      // TODO: This search sucks too much
      const searchSplitted = search.split(" ");
      const q = JSON.parse(JSON.stringify(fields)).filter(field => {
        const fieldName = field.name.toLowerCase();
        const stringField = JSON.stringify(field).toLowerCase();
        return searchSplitted.filter(word => fieldName.indexOf(word) !== -1 || stringField.indexOf(word) !== -1).length >= 1;
      }).map(field => {
        field["lands"] = field["lands"].filter(land => {
          return searchSplitted.filter(word => land.name.toLowerCase().indexOf(word) != -1).length >= 1;
        });
        return field
      });
      return q;
    };

    if (search != null && search.length >= 1) {
      setFilteredFields(filterIt);
    } else {
      setFilteredFields(fields);
    }
  }, [search, fields]);

  return (
    <>
      <div className="modal-body">
        <p className="fw-bold fs-5">{t("reports:ndvi.create.inputs.name.label")}</p>
        <input
          type="text"
          className="form-control form-control-solid mb-3"
          placeholder={t("reports:ndvi.create.inputs.name.placeholder")}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <p className="fw-bold fs-5">{t("reports:ndvi.create.inputs.dates.title")}</p>
        <div className="row mb-5">
          <div className="col-6">
            <DateSelector date={fromDate} setDate={setFromDate} dateText={t("reports:ndvi.create.inputs.dates.from_date")} />
          </div>
          <div className="col-6">
            <DateSelector date={toDate} setDate={setToDate} dateText={t("reports:ndvi.create.inputs.dates.to_date")} />
          </div>
        </div>
        <p className="fw-bold fs-5">{t("reports:ndvi.create.inputs.lands.title")}</p>
        <p>{t("reports:ndvi.create.inputs.lands.total_ha")} <span className="fw-bold text-success">{totalHa} ha</span></p>
        <div className="alert alert-primary d-flex align-items-center p-5 mb-5">
          <div className="d-flex flex-column">
            <span>{t("reports:ndvi.create.inputs.lands.advice")}</span>
          </div>
        </div>
        <input
          type="text"
          className="form-control form-control-solid mb-3"
          placeholder="Buscar"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <ListLands fields={filteredFields} />
      </div>
    </>
  );
};

const Body = () => {
  const { t } = useTranslation();
  const { groupedOptions } = useSearchContext();
  const { loading } = useCreateNDVIReportContext();

  return (
    <>
      {loading && (
        <div className="p-5">
          <Loading loadingText={t("reports:ndvi.create.creating")} />
        </div>
      )}
      {!loading && (
        <>
          <ModalBody fields={groupedOptions} />
          <ModalFooter />
        </>
      )}
    </>
  )
}

const CreateNDVIReportContainer = () => {
  return (
    <>
      <CreateNDVIReportProvider>
        <Modal open={true} setOpen={() => { }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <ModalHeader />
              <Body />
            </div>
          </div>
        </Modal>
      </CreateNDVIReportProvider>
    </>
  );
};

export default CreateNDVIReportContainer;