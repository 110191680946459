import { Routes, Route, Outlet } from "react-router-dom";

import { CreateLand, ViewField } from "./ViewField";
import CreateField from "./CreateField";
import CreateGap from "./ViewField/Gap/CreateGap";
import { ListFields } from "./ListFields";

export const LeftContainerFields = () => {

    return (
        <>
            <Routes>
                <Route path='/view/:field_id/land/new' element={<CreateLand />} />
            </Routes>
            <Routes>
                <Route path="/view/:field_id/*" element={<ViewField />} />
                <Route path="/view/:field_id/land/new" element={<ViewField />} />
                <Route path="/view/:field_id/gap/new" element={<ViewField />} />
                <Route path="/list" element={<ListFields />} />
            </Routes>
        </>
    );
};

export const AnyContainerFields = () => {
    return (
        <Routes>
            <Route element={<Outlet />}>
                <Route path='/new' element={<CreateField />} />
                <Route path='/view/:field_id/gap/new' element={<CreateGap />} />
            </Route>
        </Routes>
    );
};