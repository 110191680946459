import { useTranslation } from "react-i18next";

const FirstName = ({ loading, formik, getInputClasses }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fv-row mb-10">
        <label className='form-label fs-6 fw-bolder text-dark'>{t("auth:create_account.input.first_name.label")}</label>
        <input
          disabled={loading}
          placeholder={t("auth:create_account.input.first_name.placeholder")}
          type="text"
          className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
            "first_name"
          )}`}
          name="first_name"
          {...formik.getFieldProps("first_name")}
        />
        {formik.touched.first_name && formik.errors.first_name ? (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">{formik.errors.first_name}</div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default FirstName;