import { useTranslation } from "react-i18next";

import { KTSVG } from "../../../../_metronic/helpers";
import { LoadingCard } from "../../../../_metronic/elements/Loading";
import { openAlert } from "../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import * as ResellerService from "../services/ResellerService";

const Table = ({ users, setOpen, setUser }) => {
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();

  const login = (user) => () => {
    if (user.confirmed == false) {
      openAlert("danger", t("reseller:list_users.user_not_confirmed.title"), t("reseller:list_users.user_not_confirmed.message"));
      return;
    }

    errorHandler(ResellerService.token({
      user_id: user.id
    })).then(res => {
      if (res.ok) {
        window.location = `login-as?token=${res.data.token}`;
      }
    });
  };

  return (
    <table className="table gs-5 gy-5 gx-5">
      <thead>
        <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
          <th>{t("reseller:list_users.table.columns.name")}</th>
          <th>{t("reseller:list_users.table.columns.email")}</th>
          <th>{t("reseller:list_users.table.columns.confirmed")}</th>
          <th>{t("reseller:list_users.table.columns.free_ha_ambients")}</th>
          <th>{t("reseller:list_users.table.columns.free_ha_reports_ndvi")}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {users.length < 1 && (
          <>
            <tr>
              <td colSpan={4} style={{ textAlign: "center" }}>
                {t("reseller:list_users.not_users")}
              </td>
            </tr>
          </>
        )}
        {users.map(user => {
          const ambients = user.plan.resources.filter(e => e.resource_id == 'ambients')[0].quantity;
          let usage = user.usage.usages.filter(e => e.resource == 'ambients');

          if (usage.length < 1) {
            usage = 0;
          } else {
            usage = usage[0].usage;
          }


          const reportsNdvi = user.plan.resources.filter(e => e.resource_id == 'reports_ndvi_ha')[0].quantity;
          let usageReportsNdvi = user.usage.usages.filter(e => e.resource == 'reports_ndvi_ha');

          if (usageReportsNdvi.length < 1) {
            usageReportsNdvi = 0;
          } else {
            usageReportsNdvi = usage[0].usage;
          }

          return (
            <>
              <tr>
                <td>{user.first_name} {user.last_name}</td>
                <td>{user.email}</td>
                <td>
                  {user.confirmed && (
                    <KTSVG
                      path="/media/icons/duotune/general/gen037.svg"
                      className="svg-icon svg-icon-2x svg-icon-success"
                    />
                  )}
                  {!user.confirmed && (
                    <KTSVG
                      path="/media/icons/duotune/general/gen034.svg"
                      className="svg-icon svg-icon-2x svg-icon-danger"
                    />
                  )}
                </td>
                <td>
                  {parseFloat(ambients - usage).toFixed(2)} ha
                  <button type="button" onClick={() => {
                    setOpen("ambients");
                    setUser(user)
                  }} className="btn btn-light-primary btn-sm" style={{ marginLeft: '10px' }}>
                    {t("reseller:list_users.table.button.assign")}
                  </button>
                </td>

                <td>
                  {parseFloat(reportsNdvi - usageReportsNdvi).toFixed(2)} ha

                  <button type="button" onClick={() => {
                    setOpen("reports_ndvi_ha");
                    setUser(user)
                  }} className="btn btn-light-primary btn-sm" style={{ marginLeft: '10px' }}>
                    {t("reseller:list_users.table.button.assign")}
                  </button>
                </td>
                <td>
                  <button type="button" onClick={login(user)} className="btn btn-light-primary btn-sm" style={{ marginLeft: '5px' }}>
                    {t("reseller:list_users.table.button.login")}
                  </button>
                </td>
              </tr>
            </>
          )
        })}
      </tbody>
    </table>
  );
};

const ListUsers = ({ setUser, setOpen, loading, users }) => {
  const { t } = useTranslation();

  if (loading) {
    return <LoadingCard />
  }

  return (
    <>
      <div className="card card-custom col-xl-12 col-md-12">
        <div className="card-header">
          <h3 className="card-title">{t("reseller:list_users.title")}</h3>
        </div>
        <div className="card-body p-0">
          <Table users={users} setUser={setUser} setOpen={setOpen} />
        </div>
      </div>
    </>
  );
};

export default ListUsers;