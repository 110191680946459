import { useTranslation } from "react-i18next";

const Action = ({ formik, loading }) => {
  const { t } = useTranslation();

  return (
    <div className='text-center'>
      <button
        type='submit'
        className='btn btn-lg btn-primary w-100 mb-5'
        disabled={formik.isSubmitting || !formik.isValid}
      >
        {!loading && <span className='indicator-label'>{t("auth:login_box.login_action")}</span>}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            {t("auth:login_box.loggingin")}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  );
};

export default Action;