import { useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Modal, useModalContext } from "../../../../_metronic/elements/Modal";
import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import { InputText } from "../../../../_metronic/elements/Inputs";
import { openAlert } from "../../../../_metronic/elements/NotificationAlert";
import * as UserService from "../../user/services/UserService";
import * as ResellerService from "../services/ResellerService";
import { useTranslation } from "react-i18next";

const ModalHeader = ({ user }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{t("reseller:assign_resources.title", { name: user.first_name })}</h5>
      </div>
    </>
  );
};

const ModalFooter = ({ submitting }) => {
  const { t } = useTranslation();
  const modal = useModalContext();

  return (
    <div className="modal-footer">
      <button type="button" className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("reseller:assing_resources.buttons.cancel")}
      </button>
      <button disabled={submitting} type="submit" className="btn btn-primary">
        {t("reseller:assing_resources.buttons.assign")}
      </button>
    </div>
  );
};

const ModalBody = ({ submitting, formik, remainingHa }) => {
  const { t } = useTranslation();
  const remainingHaText = parseFloat(remainingHa).toFixed(2);
  return (
    <>
      <div className="modal-body">
        <p>{t("reseller:assing_resources.form.ha.title", { ha: remainingHaText })}</p>
        <InputText disabled={submitting} formik={formik} name="ha" inputProps={{
          placeholder: t("reseller:assing_resources.form.ha.placeholder"),
          type: "number"
        }} />
      </div>
    </>
  );
};

const AssignResource = ({ open, setOpen, user, loadUsers }) => {
  const { errorHandler } = useErrorHandler();
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState({});
  const [usage, setUsage] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    ha: Yup.number()
      .required(t("reseller:assing_resources.form.ha.errors.required"))
      .test(
        'Is positive?',
        t("reseller:assing_resources.form.ha.errors.min"),
        (value) => value > 0
      )
  });
  const remainingHa = useMemo(() => {
    if (open != "ambients" && open != "reports_ndvi_ha") {
      return 0;
    }

    if (Object.keys(plan).length < 1 || Object.keys(usage).length < 1) {
      return 0;
    }

    let reseller_resource;
    if (open == "ambients") {
      reseller_resource = "reseller_ambients_ha"
    } else {
      reseller_resource = "reseller_reports_ndvi_ha"
    }

    const ambients = plan.resources.filter(e => e.resource_id == reseller_resource)[0].quantity;
    let usageHa = usage.usages.filter(e => e.resource == reseller_resource);

    if (usageHa.length < 1) {
      usageHa = 0;
    } else {
      usageHa = usageHa[0].usage;
    }

    return ambients - usageHa;
  }, [plan, usage, open]);
  const load = async () => {
    const res_usage = await errorHandler(UserService.companyUsage());
    const res_plan = await errorHandler(UserService.currentPlan());

    setPlan(res_plan.data.data);
    setUsage(res_usage.data.data);
    setLoading(false);
  };
  const formik = useFormik({
    initialValues: {
      ha: ''
    },
    validationSchema: schema,
    onSubmit: (values, { resetForm }) => {
      setSubmitting(true);
      const base = {
        ha: 0,
        ha_reports_ndvi: 0
      }

      if (open == "ambients") {
        base["ha"] = values.ha;
      } else {
        base["ha_reports_ndvi"] = values.ha;
      }

      errorHandler(ResellerService.assignAmbient({
        company_id: user.company_id,
        ...base
      }), [402]).then(res => {
        if (res.ok) {
          loadUsers();
          resetForm();
          openAlert('success', t("reseller:assing_resources.success.title"), t("reseller:assing_resources.success.message"));
          setOpen(false);
        } else if (res.code == "need_payment") {
          openAlert('error', t("reseller:assing_resources.not_enough_ha.title"), t("reseller:assing_resources.not_enough_ha.message"));
        }

        setSubmitting(false);
      })
    },
  })


  useEffect(() => {
    load();
  }, [open]);

  if (loading || !user) {
    return;
  }

  return (
    <>
      <Modal open={open} setOpen={setOpen}>
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit} className='form'>
              <ModalHeader user={user} />
              <ModalBody submitting={submitting} formik={formik} remainingHa={remainingHa} />
              <ModalFooter submitting={submitting} />
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AssignResource;