import { useRef, useEffect } from "react";

import { Modal } from "bootstrap";
import { ModalProvider } from "./ModalContext";

const OwnModal = ({ open, setOpen, children }) => {
    const modalRef = useRef();
    
    const showModal = () => {
        const modalEle = modalRef.current
        const bsModal = new Modal(modalEle, {
            backdrop: 'static',
            keyboard: false
        });
        bsModal.show()
    }
    
    const hideModal = (finalClose) => {
        if (typeof setOpen == 'function') {
            setOpen(false);
        }
        
        const modalEle = modalRef.current;
        const bsModal= Modal.getInstance(modalEle);

        if (bsModal == null) {
            if (finalClose) {
                const e = document.querySelector(".modal-backdrop");
                
                if (e == null) {
                    return;
                }

                e.parentElement.removeChild(e);
            }

            return;
        }

        bsModal.hide();
    }

    useEffect(() => {
        if (open) {
            showModal();
        } else {
            hideModal();
        }
    }, [open]);


    useEffect(() => {
        return () => {
            hideModal(true);
        };
    }, [])

    return (
        <>
            <div className="modal fade" ref={modalRef} tabIndex="-1" >
                <ModalProvider hideModal={hideModal}>
                    {children}
                </ModalProvider>
            </div>
        </>
    );
};

export default OwnModal;