import {  useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useViewFieldContext } from '../ViewFieldContext';
import { Loading } from "../../../../../../_metronic/elements/Loading";
import Land from './Land';

const ListLands = () => {
    const { t } = useTranslation();
    const { field, loadingLands, lands } = useViewFieldContext();
    const totalHa = useMemo(() => {
        if (lands.length < 1) {
            return 0;
        }

        let totalHa = 0;
        lands.forEach(l => totalHa += l.ha);
        return totalHa;
    }, [lands]);

    return (
        <>
        <div className="card shadow-sm field-view-container right-card-data">
            <div className="card-header collapsible">
                <div data-bs-toggle="collapse" data-bs-target="#kt_list_lands_collapsible" className="card-title cursor-pointer">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center">
                            <h4>{t("lands:list.title")}</h4>
                        </div>
                    </div>
                    <span class="text-gray-400 pt-1 fw-semibold fs-7" style={{marginLeft: '10px'}}>{t("lands:list.total")} {parseFloat(totalHa).toFixed(2)} ha</span>
                </div>
            </div>
            <div id="kt_list_lands_collapsible" className="collapse show">
                <div className={`card-body ${loadingLands ? 'p-5': 'p-0'}`}>
                    {loadingLands && (
                        <Loading />
                    )}
                    {(!loadingLands && lands.length < 1) && (
                        <>
                            <h5 className="m-5">{t("lands:list.empty_lands")}</h5>
                        </>
                    )}
                    {(!loadingLands && lands.length >= 1) && lands.map(land => {
                        return <Land land={land} field={field} />
                    })}
                </div>
            </div>
        </div>
        </>
    );
};

const ListLandsContainer = () => {

    return (
        <>
        <div className="d-flex flex-column flex-row-auto field-view-flex-container displayable">
              <div className="d-flex flex-column-auto">
                  <ListLands />
              </div>
          </div>
        </>
    );
};

export default ListLandsContainer;