/* eslint import/no-webpack-loader-syntax: off */
import { createContext, useContext, useState } from "react";
import mapboxgl from '!mapbox-gl';
import * as turf from '@turf/turf';

import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import { mapState } from "./MapState";
import { drawState } from "./Draw/DrawState";
import * as GeoJsonService from "../services/GeoJsonService";
import "./MapLayersV2.style.scss";
 
const MapLayersV2Context = createContext();

export function useMapLayersV2() {
  return useContext(MapLayersV2Context);
}

export const MapLayersV2Consumer = MapLayersV2Context.Consumer;

export function MapLayersV2Provider({ children }) {
    const { errorHandler } = useErrorHandler();
    const [ loading, setLoading ] = useState(false);

    const loadGeoJson = (callback) => {
        errorHandler(GeoJsonService.fetch()).then(res => {
            if (res.ok) {
                mapState.source().set(res.data);
                
                if (callback) {
                    callback(mapState);
                }
            }
        });
    };
   
    const init = (map) => {
        mapState.setMap(map);
        loadGeoJson((updatedMapState) => {
            updatedMapState.layers().init();
            updatedMapState.markers().init();
            updatedMapState.markers().handleZoom();
            drawState.init(updatedMapState, map);
            setMapEvents(map);
        });
    };
    
    const reloadSource = (callback) => {
        loadGeoJson(() => {
            mapState.markers().reload();
            if (typeof callback == "function") {
                callback();
            }
        });
    }

    const setMapEvents = (map) => {
        map.on('zoom', mapState.markers().handleZoom());
    }

    const fit = (id) => {
        wait(() => {
            let feature = mapState.source().getFeature(id);
            if (feature == null) {
                return;
            }

            feature = feature["geometry"];

            mapState.fit(turf.buffer(feature, 0.5, {
                units: "kilometers"
            }));
        });
    };

    const fitToPolygon = (polygon) => {
        wait(() => {
            mapState.fit(turf.buffer(polygon, 0.5, {
                units: "kilometers"
            }));
        });
    };


    const wait = (f) => {
        if (!mapState.isInitialized()) {
            return setTimeout(() => wait(f), 100);
        }

        return f();
    };

    const land = {
        _unfill: (f) => {
            f["properties"].opacity = 0;
            f["properties"].borderOpacity = 1;
            return f;
        },
        unfill: (id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.id == id, land._unfill));
        },
        unfillByFieldId: (field_id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.field_id == field_id, land._unfill));
        },
        _fill: (f) => {
            f["properties"].opacity = 0.5;
            f["properties"].borderOpacity = 1;
            return f;
        },
        fill: (id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.id == id, land._fill));
        },
        fillByFieldId: (field_id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.field_id == field_id, land._fill));
        },
        hide: (id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.id == id, (f) => {
                f["properties"].opacity = 0;
                f["properties"].borderOpacity = 0;
                return f;
            }));
        },
        addImage: ({ landId, imageType, imageUrl, polygon, bbox }) => {
            wait(() => {
                const layer = `${imageType}-${landId}`;
                mapState.layers().addImage(layer, imageUrl, polygon, bbox);
            });
        },
        deleteImage: ({ landId, imageType }) => {
            wait(() => {
                mapState.layers().deleteLayerAndSource(`${imageType}-${landId}`)
            });
        },
        marker: {
            hide: (id) => {
                wait(() => mapState.markers().hide(id));
            },
            show: (id) => {
                wait(() => mapState.markers().show(id));
            }
        },
        ambient: {
            _onClickEvent: (e) => {
                const supHa = e.features[0].properties.sup_ha;
                const { lat, lng } = e.lngLat;
                new mapboxgl.Popup()
                    .setLngLat([lng, lat])
                    .setHTML(`Superficie: ${parseFloat(supHa).toFixed(2)} ha`)
                    .addTo(mapState.map);
            },
            add: ({ landId, geojson }) => {
                const layer = mapState.layers().addAmbient({ landId, geojson });
                mapState.map.on('click', layer, land.ambient._onClickEvent);
            },
            remove: ({ landId }) => {
                const layer = mapState.layers().removeAmbient({ landId });
                mapState.map.off('click', layer, land.ambient._onClickEvent);
            }
        }
    };

    const field = {
        hide: (id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.id == id, (f) => {
                f["properties"].borderOpacity = 0;
                return f;
            }));
        },
        show: (id) => {
            wait(() => mapState.source().updateFeature((f) => f.properties.id == id, (f) => {
                f["properties"].borderOpacity = 1;
                return f;
            }));
        },
    }

    const on = (event, layer, callback) => {
        wait(() => {
            if (callback) {
                mapState.map.on(event, layer, callback(mapState.map)); 
            } else {
                mapState.map.on(event, layer(mapState.map));
            }
        });
    }

    const value = {
        init,
        reloadSource,
        fit,
        fitToPolygon,
        land,
        field,
        on,
        loading, setLoading
    };

    return (
        <>
            <MapLayersV2Context.Provider value={value}>
                {children}
            </MapLayersV2Context.Provider>
        </>
    )
};