import { useTranslation } from "react-i18next";
import { useAmbientDialogContext } from "../AmbientDialogContext";

const CountAmbients = () => {
  const { countAmbients, setCountAmbients, disabled } = useAmbientDialogContext();
  const { t } = useTranslation();

  return (
    <>
      <p className="fs-7 text-center fw-bold mb-3">{t("ambient:create.input.count_ambients.label")}</p>
      <div className="d-flex flex-center">
        {[2, 3, 4, 5, 6, 7].map(i => {
          return (
            <div className="form-check form-check-custom form-check-solid me-3">
              <input checked={`${i}` == countAmbients} onChange={(e) => setCountAmbients(e.target.value)} value={`${i}`} disabled={disabled} className="form-check-input h-30px w-30px" name="count-ambients" type="radio" id={`radio-${i}`} />
              <label className="form-check-label" for={`radio-${i}`}>
                {i}
              </label>
            </div>
          );
        })}

      </div>
    </>
  );
};

export default CountAmbients;