import { Link } from "react-router-dom";

import { KTSVG } from "../../../../../_metronic/helpers";
import "./Field.style.scss";

const Field = ({ field }) => {

    return (
        <Link to={`/gis/field/view/${field.field_id}`} className="d-flex align-items-center p-5 field-item">
            <span className="bullet bullet-vertical h-40px bg-primary me-5"></span>
            <div className="flex-grow-1">
                <span className="text-gray-800 text-hover-primary fw-bold fs-6">{field.name}</span>
            </div>
            <span className="btn btn-sm btn-light-primary fs-8 fw-bold"><KTSVG path="/media/icons/duotune/arrows/arr024.svg" /></span>
        </Link>
    );
}

export default Field;