import { useCallback, useEffect, useState } from "react";
import { useViewAmbientContext } from "../AmbientsContext";
import { KTSVG } from "../../../../../../../../_metronic/helpers";
import { useViewLandContext } from "../../ViewLandContext";
import { useMapLegendContext } from "../../Helpers/MapLegend";
import { useViewImageContext } from "../../Images/ViewImageContext";
import { useMapLayersV2 } from "../../../../../Map/MapLayersV2Context";
import { useTranslation } from "react-i18next";
import { Divider } from '@material-ui/core';
import { openAlert } from "../../../../../../../../_metronic/elements/NotificationAlert";

const ViewAmbient = () => {
  const [opened, setOpened] = useState(false);
  const { ambientData, setDeleteAmbient, updateAmbientDialog, imageAmbientData,selectedElement, setSelectedElement} = useViewAmbientContext();
  const { land } = useViewLandContext();
  const { t } = useTranslation();
  const mapLayers = useMapLayersV2();
  const mapLegendContext = useMapLegendContext();
  const { openedAmbients, setOpenPrescription, addOpenedAmbients } = useViewImageContext();
  const viewAmbient = useCallback(() => {
    setOpened(true);
    setSelectedElement('ambient');
    addOpenedAmbients(land.land_id);
    mapLayers.fit(land.land_id);
    mapLegendContext.handleAmbient(land.land_id, ambientData);
    const colors = {
      2: ['rgb(215,25,28)', 'rgb(26,150,65)'],
      3: ['rgb(215,25,28)', 'rgb(255,255,192)', 'rgb(26,150,65)'],
      4: ['rgb(215,25,28)', 'rgb(254,201,129)', 'rgb(196,230,135)', 'rgb(26,150,65)'],
      5: ['rgb(215,25,28)', 'rgb(253,174,97)', 'rgb(255,255,192)', 'rgb(166,217,106)', 'rgb(26,150,65)'],
      6: ['rgb(215,25,28)', 'rgb(246,144,83)', 'rgb(255,223,154)', 'rgb(220,240,158)', 'rgb(138,204,98)', 'rgb(26,150,65)'],
      7: ['rgb(215,25,28)', 'rgb(241,124,74)', 'rgb(254,201,129)', 'rgb(255,255,192)', 'rgb(196,230,135)', 'rgb(119,195,92)', 'rgb(26,150,65)']
    };

    let a = Object.assign({}, ambientData.geojson);
    a.features = a.features.map(e => {
      e['properties']['color'] = colors[ambientData.params.breaks][e.properties.cls];
      return e;
    });

    mapLayers.land.ambient.remove({
      landId: land.land_id
    });

    mapLayers.land.ambient.add({
      landId: land.land_id,
      geojson: a
    });
  }, [land, ambientData]);

  const updateAmbient = useCallback(() => {
    if (!ambientData.editable){
      openAlert("danger", "Error", t("ambient:view.messages.cant_update_old_ambient"));
      return
    }
    updateAmbientDialog();    
  }, [ambientData])

  const viewImageAmbient = useCallback(() => {
    setOpened(true);
    setSelectedElement('imageAmbient');
    addOpenedAmbients(land.land_id);
    mapLayers.fit(land.land_id);
    mapLegendContext.handleAmbient(land.land_id, imageAmbientData);
    const colors = {
      2: ['rgb(215,25,28)', 'rgb(26,150,65)'],
      3: ['rgb(215,25,28)', 'rgb(255,255,192)', 'rgb(26,150,65)'],
      4: ['rgb(215,25,28)', 'rgb(254,201,129)', 'rgb(196,230,135)', 'rgb(26,150,65)'],
      5: ['rgb(215,25,28)', 'rgb(253,174,97)', 'rgb(255,255,192)', 'rgb(166,217,106)', 'rgb(26,150,65)'],
      6: ['rgb(215,25,28)', 'rgb(246,144,83)', 'rgb(255,223,154)', 'rgb(220,240,158)', 'rgb(138,204,98)', 'rgb(26,150,65)'],
      7: ['rgb(215,25,28)', 'rgb(241,124,74)', 'rgb(254,201,129)', 'rgb(255,255,192)', 'rgb(196,230,135)', 'rgb(119,195,92)', 'rgb(26,150,65)']
    };

    let a = Object.assign({}, imageAmbientData.geojson);
    a.features = a.features.map(e => {
      e['properties']['color'] = colors[imageAmbientData.params.breaks][e.properties.cls];
      return e;
    });

    mapLayers.land.ambient.remove({
      landId: land.land_id
    });

    mapLayers.land.ambient.add({
      landId: land.land_id,
      geojson: a
    });
  }, [land, imageAmbientData]);

  useEffect(() => {
    if (openedAmbients.indexOf(land.land_id) == -1 && opened) {
      setOpened(false);
      mapLayers.land.ambient.remove({
        landId: land.land_id
      });
      mapLegendContext.deleteLegend(land.land_id);
    }
  }, [openedAmbients]);

  useEffect(() => {
    return () => {
      mapLegendContext.deleteLegend(land.land_id);
    };
  }, []);

  useEffect(() => {
    if (selectedElement === 'ambient' && ambientData) {
      viewAmbient();
    } else if (selectedElement === 'imageAmbient' && imageAmbientData) {
      viewImageAmbient();
    }
  }, [selectedElement, viewAmbient, viewImageAmbient]);

    return (
      <>
        {(ambientData && ambientData.geojson) &&<div onClick={() => viewAmbient()} className={`d-flex align-items-center div-type-selector rounded p-5 ${selectedElement === 'ambient'? 'active' : ''}`}>
          <span className="svg-icon svg-icon-warning me-5">
            <KTSVG path="/media/icons/duotune/general/gen006.svg" />
          </span>
          <div className="flex-grow-1 me-2">
            <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("ambient:view.buttons.view")}</span>
          </div>
        </div>}
        {imageAmbientData && <div onClick={() => viewImageAmbient()} className={`d-flex align-items-center div-type-selector rounded p-5 ${selectedElement === 'imageAmbient'? 'active' : ''}`}>
          <span className="svg-icon svg-icon-warning me-5">
            <KTSVG path="/media/icons/duotune/general/gen006.svg" />
          </span>
          <div className="flex-grow-1 me-2">
            <span className="fw-bold text-gray-800 text-hover-primary fs-6">{t("img_ambient:view.buttons.view")}</span>
          </div>
        </div>}
        <div className="d-flex flex-column ms-4">
        {opened && (
          <>
          <Divider />
            <div onClick={() => setOpenPrescription(true)} className={`d-flex align-items-center div-type-selector rounded p-5`}>
              <span className="svg-icon svg-icon-warning me-5">
                <KTSVG path="/media/icons/duotune/communication/com008.svg" />
              </span>
              <div className="flex-grow-1 me-2">
                <span className="fw-bold text-gray-800 text-hover-primary fs-7">{t("ambient:view.buttons.create_prescription")}</span>
              </div>
            </div>
            {ambientData && selectedElement === 'ambient' && <div onClick={() => {
              updateAmbient()
            }} className={`d-flex align-items-center div-type-selector rounded p-5 ${!ambientData.editable  ? 'disabled' : ''}`}>
              <span className="svg-icon svg-icon-warning me-5">
                <KTSVG path="/media/icons/duotune/coding/cod009.svg" />
              </span>
              <div className="flex-grow-1 me-2">
                <span className="fw-bold text-gray-800 text-hover-primary fs-7">{t("ambient:view.buttons.update")}</span>
              </div>
            </div>}
            <div onClick={() => setDeleteAmbient(true)} className={`d-flex align-items-center div-type-selector rounded p-5`}>
              <span className="svg-icon svg-icon-warning me-5">
                <KTSVG path="/media/icons/duotune/art/art004.svg" />
              </span>
              <div className="flex-grow-1 me-2">
                <span className="fw-bold text-gray-800 text-hover-primary fs-7">{t("ambient:view.buttons.delete")}</span>
              </div>
            </div>
          </>
        )}
        </div>
      </>
    );
  };

export default ViewAmbient;