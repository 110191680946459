import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";
import UpdateAccountInformationInputs from "./UpdateAccountInformationInputs";
import * as UserService from "../../services/UserService";

const UpdateAccountInformationForm = ({ userData }) => {
  const { t } = useTranslation();
  const { errorHandler, setInputError } = useErrorHandler();
  const navigate = useNavigate();
  const schema = Yup.object().shape({
    first_name: Yup.string().required(
      t("account:update.fields.first_name.errors.required")
    ).test('length', t("account:update.fields.first_name.errors.max"), (e) => {
      if (e) {
        if (e.length >= 32) {
          return false;
        }
      }

      return true;
    }),
    last_name: Yup.string().required(
      t("account:update.fields.last_name.errors.required")
    ).test('length', t("account:update.fields.last_name.errors.max"), (e) => {
      if (e) {
        if (e.length > 32) {
          return false;
        }
      }

      return true;
    }),
    company_name: Yup.string().required(
      t("account:update.fields.company_name.errors.required")
    ).test('length', t("account:update.fields.company_name.errors.max"), (e) => {
      if (e) {
        if (e.length > 64) {
          return false;
        }
      }

      return true;
    }),
    phone: Yup.string().nullable().test('length', t("account:update.fields.phone.errors.max"), (e) => {
      if (e) {
        if (e.length > 32) {
          return false;
        }
      }

      return true;
    }),
    address: Yup.string().nullable().test('length', t("account:update.fields.address.errors.max"), (e) => {
      if (e) {
        if (e.length > 64) {
          return false;
        }
      }

      return true;
    }),
    city: Yup.string().nullable().test('length', t("account:update.fields.city.errors.max"), (e) => {
      if (e) {
        if (e.length > 32) {
          return false;
        }
      }

      return true;
    }),
    province: Yup.string().nullable(),
    zip_code: Yup.string().nullable().test('length', t("account:update.fields.zip_code.errors.max"), (e) => {
      if (e) {
        if (e.length > 32) {
          return false;
        }
      }

      return true;
    })
  });

  const getInitialValues = () => {
    const v = Object.assign({}, userData);
    v['company_name'] = v.company.name;
    return v;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema: schema,
    onSubmit: async (values, { setFieldError }) => {
      if (values.company_name != userData.company.name) {
        // Update company data
        await errorHandler(
          UserService.updateCompany({
            name: values.company_name
          })
        );
      }

      const res = await errorHandler(
        UserService.updateMe(values)
      );

      if (res.ok) {
        openAlert('success', t("account:update.success.title"), t("account:update.success.message"));
        navigate('/user');
      } else if (res.error_type == 'field_error') {
        setInputError({ response: res.data, setFieldError });
      }
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='form'>
        <div className="card card-custom col-xl-6 col-md-6">
          <div className="card-header">
            <h3 className="card-title">{t("account:update.title")}</h3>
          </div>
          <div className="card-body">
            <UpdateAccountInformationInputs formik={formik} />
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
            <button disabled={formik.isSubmitting} type="submit" className="btn btn-sm btn-primary">
              {t("account:update.buttons.update")}
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UpdateAccountInformationForm