import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../_metronic/elements/NotificationAlert";
import * as AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";

function ConfirmAccountWithPassword({ token }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: { password: "" },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, t("confirm:input.password.errors.min"))
        .max(50, t("confirm:input.password.errors.max"))
        .required(t("confirm:input.password.errors.required")),
      repeatpassword: Yup.string()
        .required(t("confirm:input.repeatpassword.errors.required"))
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("confirm:input.repeatpassword.errors.invalid")
          ),
        })
    }),
    onSubmit: (values, { setSubmitting }) => {
      errorHandler(AuthService.confirmAccount(token, { password: values.password }))
        .then((res) => {
          if (res.ok) {
            openAlert('success', t("confirm:success.title"), t("confirm:success.message"));
            navigate('/auth/login');
          } else {
            setSubmitting(false);
          }
        });
    },
  });

  return (
    <>
      <div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-10">
          <h3 className="font-size-h1">{t("confirm:title")}</h3>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          <div className="form-group fv-plugins-icon-container mb-5">
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              placeholder={t("confirm:input.password.placeholder")}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <input
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "repeatpassword"
              )}`}
              placeholder={t("confirm:input.repeatpassword.placeholder")}
              name="repeatpassword"
              {...formik.getFieldProps("repeatpassword")}
            />
            {formik.touched.repeatpassword && formik.errors.repeatpassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.repeatpassword}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={formik.isSubmitting}
            >
              {t("confirm:buttons.complete")}
            </button>
            <Link to="/auth/login">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                {t("confirm:buttons.cancel")}
              </button>
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default ConfirmAccountWithPassword;
