import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { openAlert } from "../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import { Loading } from "../../../../_metronic/elements/Loading";
import ConfirmAccountWithPassword from "./ConfirmAccountWithPassword";
import * as AuthService from '../services/AuthService';

const ConfirmAccount = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [ needPassword, setNeedPassword ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    const { errorHandler } = useErrorHandler();
    const { t } = useTranslation();
    const token = useMemo(() => {
        try {
            return  location.search.split('token=')[1];
        } catch (e) {
            return null;
        }
    }, [location]);

    const validate = async () => {
        setLoading(true);
        const res = await errorHandler(AuthService.confirmAccount(token), [403, 400]);
        if (!res.ok) {
            if (res.data.code == "need_password") {
                setNeedPassword(true);
                setLoading(false);
                return;
            } else {
                openAlert('danger', t("confirm:errors.title"), t("confirm:errors.token_invalid"));
            }
        } else {
            openAlert('success', t("confirm:success.title"), t("confirm:success.message"));
        }
        setLoading(false);
        navigate('/auth');
    };

    useEffect(() => {
        if (token == null) {
            navigate('/auth');
        }

        validate();
    }, []);

    if (loading) {
        return <Loading />
    }

    if (needPassword) {
        return <ConfirmAccountWithPassword token={token} />
    }

    return (
        <>
        </>
    );
};

export default ConfirmAccount;
