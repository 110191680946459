import axios from "axios";

const LOGIN_URL = "/api/v1/users/auth/login";
const ME_URL = "/api/v1/users/me";
const REQUEST_PASSWORD_URL = "/api/v1/users/auth/forgot-password";
const FORGOT_PASSWORD_TOKEN = "/api/v1/users/auth/forgot-password/token/:token";
const REGISTER_URL = "/api/v1/users/auth/account";
const CONFIRM_ACCOUNT_URL = "/api/v1/users/confirm";

export const login = (email, password) => {
    return axios.post(LOGIN_URL, { email, password }).then(res => {
      if (res.status == 200) {
        return {
          ok: true,
          data: res.data
        }
      }
    });
}

export const me = (token) => {
  return axios.get(ME_URL, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }).then(res => {
    if (res.status == 200) {
      return {
        ok: true,
        data: res.data
      }
    }
  });
}

export const requestPassword = (email) => {
  return axios.post(REQUEST_PASSWORD_URL, { email }).then(res => {
    if (res.status == 201) {
      return {
        ok: true
      }
    }
  });

};

export const validateTokenForgotPassword = (token) => {
  return axios.get(FORGOT_PASSWORD_TOKEN.replace(':token', token)).then(res =>  {
    if (res.status == 200) {
      return {
        ok: true
      };
    }
  })
}

export const changePasswordForgot = (token, body) => {
  return axios.post(FORGOT_PASSWORD_TOKEN.replace(':token', token), body).then(res =>  {
    if (res.status == 200) {
      return {
        ok: true
      };
    }
  });
}

export const register = (body) => {
  return axios.post(REGISTER_URL, body).then(res => {
    if (res.status == 201) {
      return {
        ok: true,
        data: res.data
      };
    }
  });
}

export const confirmAccount = (token, data) => {
  return axios.post(CONFIRM_ACCOUNT_URL, {
    token, ...data
  }).then(res =>  {
    if (res.status == 200) {
      return {
        ok: true
      };
    }
  })

}

