/* eslint-disable jsx-a11y/anchor-is-valid */

import { useNavigate, Link, useMatch } from "react-router-dom";
import "./Toolbar.scss";

const Toolbar = () => {
  return (
    <>
    <div className='toolbar' id='kt_toolbar' >
        <div
            id='kt_toolbar_container'
            className={'container-fluid d-flex flex-stack'}
        >
            <ul className="nav nav-tabs nav-line-tabs nav-line-tabs-2x fs-6">
                <li className="nav-item">
                    <Link className={"nav-link " + ([useMatch("/user"), useMatch("/user/update")].filter(e => e != null).length >= 1 ? 'active' : '')} to="/user">
                      Información de mi cuenta
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={"nav-link " + (useMatch("/user/password") ? 'active' : '')} to="/user/password">
                      Cambiar contraseña
                    </Link>
                </li>
                <li className="nav-item">
                    <Link className={"nav-link " + (useMatch("/user/payment") ? 'active' : '')} to="/user/payment">
                      Información de pagos
                    </Link>
                </li>
            </ul>
        </div>
    </div>
    </>
  )
}

export default Toolbar;
