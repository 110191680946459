import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { geoMercator, geoPath } from "d3-geo";
import { useImageAmbient } from "./ImageAmbientContext";
import "./ImageAmbientForm.scss";

const sortOrder = [
  'six',
  'five',
  'map',
  'ap',
  'pp',
  'bp',
  'mbp'
];

const getColor = (key, numClasses) => {
  return {
    2: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(26,150,65)'
    },
    3: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(255,255,192)',
      'pp': 'rgb(26,150,65)'
    },
    4: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(254,201,129)',
      'pp': 'rgb(196,230,135)',
      'ap': 'rgb(26,150,65)'
    },
    5: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(253,174,97)',
      'pp': 'rgb(255,255,192)',
      'ap': 'rgb(166,217,106)',
      'map': 'rgb(26,150,65)'
    },
    6: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(246,144,83)',
      'pp': 'rgb(255,223,154)',
      'ap': 'rgb(220,240,158)',
      'map': 'rgb(138,204,98)',
      'five': 'rgb(26,150,65)'
    },
    7: {
      'mbp': 'rgb(215,25,28)',
      'bp': 'rgb(241,124,74)',
      'pp': 'rgb(254,201,129)',
      'ap': 'rgb(255,255,192)',
      'map': 'rgb(196,230,135)',
      'five': 'rgb(119,195,92)',
      'six': 'rgb(26,150,65)'
    }
  }[numClasses][key];
};

const ImageAmbientForm = ({ img_ambient, numClasses, geojson_preview, bounding_box }) => {
  const { columns, setColumns, prod, setProd } = useImageAmbient();
  const { t } = useTranslation();
  const totalHa = useMemo(() => {
    let total = 0;
    Object.keys(img_ambient).forEach(key => { total += img_ambient[key]['ha'] });
    return total;
  }, [img_ambient]);

  const filtered = useMemo(() => {
    return sortOrder.filter(key => img_ambient[key]['ha'] > 0);
  }, [img_ambient]);

  // Proyección con fitSize aplicado a todo el conjunto de datos
    const projection = geoMercator().fitSize([400, 400], geojson_preview);
    const pathGenerator = geoPath().projection(projection);

  return (
    <>
      <div className="row">
        <div className="col-4">
          <span className="text-title">Mayor Productividad</span>
        </div>

        <div className="col-4">
          <span className="text-title">ha</span>
        </div>

        <div className="col-4">
          <span className="text-title">%</span>
        </div>
      </div>

      {filtered.map(key => {
        return (
          <>
            <div className="row mt-2">
              <div className="col-4">
                <div className="box-cls" style={{ backgroundColor: getColor(key, numClasses) }}></div>
              </div>
              <div className="col-4">
                {parseFloat(img_ambient[key]['ha']).toFixed(2)}
              </div>
              <div className="col-4">
                {parseFloat((img_ambient[key]['ha'] * 100) / totalHa).toFixed(2)}
              </div>
            </div>
          </>
        )
      })}
      <div className="row">
        <div className="col-4">
          <span className="text-title">Menor Productividad</span>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-2">
          <span className="text-title">Previsualización</span>

        </div>
        <svg width="100%" height="400"  viewBox="0 0 400 400" style={{ padding: '5px' }}>
        {geojson_preview.features.map((feature, index) => (
            <path 
              key={index} 
              d={pathGenerator(feature)} 
              fill={getColor(filtered[filtered.length - feature.properties.cls - 1], numClasses)} 
            />
          ))}
        </svg>
      </div>
    </>
  );
};

export default ImageAmbientForm;