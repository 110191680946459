import { Link } from "react-router-dom";

import { KTSVG } from "../../../../../../_metronic/helpers";
import "./Land.style.scss";

const Land = ({ field, land }) => {
    return (
        <Link to={`/gis/field/view/${field.field_id}/land/view/${land.land_id}`} className="d-flex align-items-center p-5 land-item">
            <span className="bullet bullet-vertical h-40px bg-primary me-5"></span>
            <div className="flex-grow-1">
                <span className="text-gray-800 text-hover-primary fw-bold fs-6">{land.name}</span>
                <span className="text-muted fw-semibold d-block">{parseFloat(land.ha).toFixed(2)} ha</span>
            </div>
            <span className="btn btn-sm btn-light-primary fs-8 fw-bold"><KTSVG path="/media/icons/duotune/arrows/arr024.svg" /></span>
        </Link>
    )
}

export default Land;