import { useEffect, useCallback } from "react";
import { format } from "date-fns";
import axios from "axios";

import { useErrorHandler } from "../../../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewImageContext } from "../../ViewImageContext";
import { useMapLegendContext } from "../../../Helpers/MapLegend";
import { useMapLayersV2 } from "../../../../../../Map/MapLayersV2Context";
import PopupEvent from "../PopupEvent";
import * as LandService from "../../../../../services/LandService";

const parseHeaders = (headers) => {
    return {
        ...headers,
        'x-index-range': JSON.stringify(JSON.parse(headers['x-index-range']).map(interval => {; 
            return {
                ...interval,
                percentage: interval['percentage'] * 100
            }
        })),
    };
};


const NDVI = ({ land, selectedDate }) => {
    const { errorHandler } = useErrorHandler();
    const mapLayers = useMapLayersV2();
    const viewImageContext = useViewImageContext();
    const mapLegendContext = useMapLegendContext();
    const cancelTokenRequest = axios.CancelToken.source();

    const deleteOldImage = () => {
        mapLayers.land.deleteImage({ landId: land.land_id, imageType: "ndvi" });
    }

    const loadImage = useCallback(async () => {
        mapLayers.fit(land.land_id);
        viewImageContext.setLoading(true);
        const enhanced = !mapLegendContext.enhanced[land.land_id] ? false : true
        const res = await errorHandler(LandService.getLayerV2(land.land_id, "ndvi", {
            date: format(selectedDate, 'yyyy-MM-dd'),
            enhanced
        }, cancelTokenRequest));

        const tif = await errorHandler(LandService.getLayerTiff(land.land_id, "ndvi", {
            date: format(selectedDate, 'yyyy-MM-dd')
        }, cancelTokenRequest));
        viewImageContext.addLandTiff(land.land_id, tif.data);

        if (res.ok) {
            const url = window.URL || window.webkitURL;
            const src = url.createObjectURL(res.data);

            mapLayers.land.addImage({
                landId: land.land_id,
                imageType: "ndvi",
                imageUrl: src,
                polygon: land.polygons,
                bbox: JSON.parse(res.headers["x-bbox"]),
            });

            mapLegendContext.changeBasicLegend(land.land_id, parseHeaders(res.headers));
        }

        viewImageContext.setLoading(false);
    }, [land, selectedDate, mapLegendContext.enhanced[land.land_id]])

    useEffect(() => {
        loadImage();
    }, [land, selectedDate]);


    useEffect(() => {
        deleteOldImage();
        loadImage();
    }, [mapLegendContext.enhanced[land.land_id]])

    useEffect(() => {
        return () => {
            mapLegendContext.deleteLegend(land.land_id);
            deleteOldImage();
        }
    }, []);

    return (
        <>
           
        </>
    );
};

export default NDVI;