import { useMemo, useState } from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import { parseISO, format, parse } from "date-fns";
import { DatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import Chart from "react-apexcharts";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { useViewNDVIContext } from "../ViewNDVIContext";
import { KTSVG } from "../../../../../../../_metronic/helpers";

const LandChart = ({ landId }) => {
  const { t } = useTranslation();
  const context = useViewNDVIContext();
  const data = useMemo(() => {
    const chart = {
      options: {
        chart: {
          id: "avg-index"
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          decimalsInFloat: 3,
        }
      },
      series: [],
    };
    const categories = [];
    const dates = [];
    const avgIndex = [];

    let reportDates = context.report.ndvi_land_dates.filter(
      e => e.land_id == landId
    ).map(e => {
      const n = { ...e }
      n["date"] = parseISO(n["date"]);
      return n;
    }).sort((a, b) => {
      return a.date - b.date;
    });

    for (var x in reportDates) {
      const current = reportDates[x];
      const currentDate = format(current.date, "dd/LL/yyyy", new Date());
      if (current.usage == "USED") {
        categories.push(currentDate);
        dates.push({
          date: currentDate,
          used: true
        });
        avgIndex.push(current.avg_index);
      } else {
        dates.push({
          date: currentDate,
          used: false
        });
      }
    }

    chart.options.xaxis.categories = categories;
    chart.series.push({
      name: "NDVI",
      data: avgIndex
    })

    return {
      chart,
      dates
    }
  }, [landId]);

  return (
    <>
      <h4 className="mt-5">{t("reports:ndvi.view.reports.land.avg_ndvi")}</h4>
      <Chart
        options={data.chart.options}
        series={data.chart.series}
        type="line"
        width="100%"
      />
    </>
  );
};

const HaDistribution = ({ landId }) => {
  const { t } = useTranslation();
  const [openedDatePicker, setOpenedDatePicker] = useState(false);
  const [date, setDate] = useState(null);
  const context = useViewNDVIContext();
  const { availableDates } = useMemo(() => {
    const reports = context.report.ndvi_land_dates.filter(e => e.land_id == landId && e.usage == "USED");
    const availableDates = reports.map(e => format(parseISO(e.date), "yyyy-LL-dd"));
    if (availableDates.length < 1) {
      return { availableDates: [] };
    }

    setDate(parse(availableDates[0], "yyyy-LL-dd", new Date()));
    return { availableDates };
  }, [landId, context.report]);
  const chart = useMemo(() => {
    if (date == null) {
      return null;
    }

    const reports = context.report.ndvi_land_dates.filter(
      e => e.land_id == landId && e.usage == "USED"
    ).filter(
      e => format(parseISO(e.date), "yyyy-LL-dd") == format(date, "yyyy-LL-dd", new Date())
    );

    if (reports.length < 1) {
      return null;
    }

    const report = reports[0];

    const categories = [];
    for (var i = 0; i < report.interval.length - 1; i++) {
      categories.push(`${parseFloat(report.interval[i].interval).toFixed(3)} - ${parseFloat(report.interval[i + 1].interval).toFixed(3)}`);
    }
    categories.push(`> ${parseFloat(report.interval[report.interval.length - 1].interval).toFixed(3)}`)

    return {
      options: {
        chart: {
          id: "ha"
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          decimalsInFloat: 2,
        },
        colors: [
          function ({ value, seriesIndex, dataPointIndex, w }) {
            if (dataPointIndex == 0) {
              return "#f1416c";
            } else if (dataPointIndex == 1) {
              return "#ffc700";
            } else if (dataPointIndex == 2) {
              return "#7239ea";
            } else if (dataPointIndex == 3) {
              return "#50cd89";
            }
          }
        ]
      },
      series: [
        {
          name: t("reports:ndvi.view.reports.land.series_hectareas"),
          data: report.interval.map(e => e.ha)
        }
      ],
    };

  }, [date, landId]);

  return (
    <>
      <h4 className="mt-5 mb-5">{t("reports:ndvi.view.reports.land.ha_distribution")}</h4>
      <DatePicker
        open={openedDatePicker}
        value={date}
        onOpen={() => setOpenedDatePicker(true)}
        onClose={() => setOpenedDatePicker(false)}
        onChange={(x) => setDate(x)}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          if (availableDates.indexOf(format(day, "yyyy-LL-dd")) == -1) {
            return dayComponent;
          }

          let extraClass = "";
          if (selectedDate != null && selectedDate.getTime() == day.getTime()) {
            extraClass = "active";
          }
          return <div className={'date-with-data ' + extraClass}>{dayComponent}</div>;
        }}
        shouldDisableDate={(date) => {
          return availableDates.indexOf(format(date, "yyyy-LL-dd")) == -1
        }}
        animateYearScrolling
        TextFieldComponent={() => null}
      />
      <a onClick={(e) => {
        e.preventDefault();
        setOpenedDatePicker(true);
      }} href="#" className={`fw-bold text-gray-800 text-hover-primary fs-7 mt-5 mb-5`}>
        <KTSVG
          path="/media/icons/duotune/general/gen014.svg"
          className="svg-icon svg-icon-2x svg-icon-primary me-5"
        />
        {!date || date == null ? t("reports:ndvi.view.reports.land.select_date") : format(
          date, 'dd/MM/yyyy'
        )}
      </a>
      {chart != null && (
        <>
          <Chart
            options={chart.options}
            series={chart.series}
            type="bar"
            width="100%"
          />
          {chart.options.xaxis.categories.map((cat, index) => {
            return (
              <>
                <div className="d-flex fw-semibold align-items-center">
                  <div className={"bullet w-10px h-10px rounded-2 me-3 " + [
                    "bg-danger",
                    "bg-warning",
                    "bg-info",
                    "bg-success"
                  ][index]}></div>
                  <div className="flex-grow-1 me-4">{cat} - {[
                    t("reports:ndvi.view.status.regular"),
                    t("reports:ndvi.view.status.good"),
                    t("reports:ndvi.view.status.very_good"),
                    t("reports:ndvi.view.status.superior")
                  ][index]}</div>
                  <div className="fw-bolder text-gray-700 text-xxl-end">{chart.series[0].data[index]} ha</div>
                </div>
              </>
            )
          })}
        </>
      )}
    </>
  );
};

const Land = () => {
  const { t } = useTranslation();
  const context = useViewNDVIContext();
  const [landSelection, setLandSelection] = useState([]);
  const options = useMemo(() => {
    return context.report.lands.map(land => {
      return {
        id: land.land_id,
        name: `${land.field.name} / ${land.name}`
      }
    });
  }, [context.report]);

  return (
    <>
      <Typeahead
        id="basic-typeahead"
        labelKey="name"
        onChange={setLandSelection}
        options={options}
        placeholder={t("reports:ndvi.view.reports.land.selector")}
        selected={landSelection}
      />
      {landSelection.length >= 1 && (
        <>
          <LandChart landId={landSelection[0].id} />
          <HaDistribution landId={landSelection[0].id} />
        </>
      )}
    </>
  );
};

export default Land;
