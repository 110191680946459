import * as turf from '@turf/turf';

import { MapSource } from "./MapSource";
import { MapLayers } from "./MapLayers";
import { MapMarkers } from "./MapMarkers";

class MapState {
    constructor() {
      this.indexedFields = new Map();
      this.indexedLands = new Map();
      this.globalState = { ok: false, value: null }; // State to keep track of current drawing status
  
      this.landsActive = []; // List of lands with active layers
      this.fieldsActive = []; // List of fields with active layers

      // map instance
      this.map = null;

      // map source;
      this._source = null;

      // map layers
      this._layers = null;

      // map markers
      this._markers = null;
    }
  
    setMap(map) {
        this.map = map;
    }

    isInitialized() {
        return this.map != null && this._source != null && this._source.isInitialized();
    }

    source() {
        if (this._source == null) {
            this._source = new MapSource(this.map);
        }

        return this._source;
    }

    layers() {
        if (this._layers == null) {
            this._layers = new MapLayers(this.map, this);
        }

        return this._layers;
    }

    markers() {
        if (this._markers == null) {
            this._markers = new MapMarkers(this.map, this);
        }

        return this._markers;
    }

    fit(polygon) {
        this.map.fitBounds(turf.bbox(polygon));
    }

    reload(){
        this._source = null;
        this._layers = null;
        this._markers = null;
        this.map = null;

    }
}
  

export const mapState = new MapState();