
import { Modal, useModalContext } from "../../../../../../../../_metronic/elements/Modal";
import { useViewAmbientContext } from "../AmbientsContext";
import { CountAmbients, Season, Year, Months } from "./FormGroups";
import { KTSVG } from "../../../../../../../../_metronic/helpers";
import { AmbientDialogProvider, useAmbientDialogContext } from "./AmbientDialogContext";
import { openAlert } from "../../../../../../../../_metronic/elements/NotificationAlert";
import { useTranslation } from "react-i18next";

const ModalHeader = () => {
  const modal = useModalContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{t("ambient:create.title")}</h5>
        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => modal.hideModal()} aria-label="Close">
          <KTSVG
            path="/media/icons/duotune/arrows/arr061.svg"
            className="svg-icon svg-icon-2x"
          />
        </div>
      </div>
    </>
  );
};

const ModalFooter = () => {
  const modal = useModalContext();
  const { disabled } = useAmbientDialogContext();
  const { ambientData } = useViewAmbientContext();
  const { t } = useTranslation();
  return (
    <div className="modal-footer">
      <button type="button" disabled={disabled} className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("ambient:create.buttons.cancel")}
      </button>
      <button type="submit" disabled={disabled} className="btn btn-primary">
        {ambientData == null ? t("ambient:create.buttons.create_personalized") : t("ambient:create.buttons.update")}
      </button>
    </div>
  );
}

const ModalContent = () => {
  const viewAmbientContext = useViewAmbientContext();
  const { monthTo, monthFrom, years, countAmbients, setDisabled } = useAmbientDialogContext();
  const { t } = useTranslation();
  const onSubmit = async (e) => {
    e.preventDefault();

    let error;
    if (!monthTo || monthTo.length < 1) {
      error = t("ambient:create.input.month_to.errors.required");
    }

    if (!monthFrom || monthFrom.length < 1) {
      error = t("ambient:create.input.month_from.errors.required");
    }

    if (!years || years.length < 2) {
      error = t("ambient:create.input.years.errors.required");
    }

    if (!countAmbients || countAmbients.length < 1) {
      error = t("ambient:create.input.count_ambients.errors.required");
    }

    if (error) {
      return openAlert('danger', 'Error', error);
    }

    setDisabled(true);
    const res = await viewAmbientContext.create('personalized', {
      monthRange: [monthFrom, monthTo],
      yearsList: years,
      countAmbients: countAmbients,
      update: viewAmbientContext.ambientData != null
    });

    if (res == "need_payment") {
      openAlert('danger', 'Error', t("ambient:create.messages.not_enough_ha"));
    };

    setDisabled(false);
  };

  return (
    <div className="modal-dialog">
      <div className="modal-content">
        <form onSubmit={onSubmit} className='form'>
          <ModalHeader />
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <CountAmbients />
              </div>
              <div className="col-12 mt-5">
                <Season />
              </div>
              <div className="col-12 mt-5">
                <Year />
              </div>
              <div className="col-12 mt-5">
                <Months />
              </div>
            </div>
          </div>
          <ModalFooter />
        </form>
      </div>
    </div>
  )
}

const DialogAmbient = () => {
  const viewAmbientContext = useViewAmbientContext();

  return (
    <>
      <Modal open={viewAmbientContext.ambientDialog} setOpen={viewAmbientContext.setAmbientDialog}>
        {viewAmbientContext.ambientDialog && (
          <AmbientDialogProvider>
            <ModalContent />
          </AmbientDialogProvider>
        )}
      </Modal>
    </>
  );
};

export default DialogAmbient;