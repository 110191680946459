import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useViewNDVIContext } from "./ViewNDVIContext";
import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import { openAlert } from "../../../../../../_metronic/elements/NotificationAlert";
import * as ReportService from "../../services/ReportService";
import { useNDVIReportContext } from "../NDVIReportContext";

const DeleteReport = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();
  const { deleteReport, setDeleteReport } = useViewNDVIContext();
  const [ deleting, setDeleting ] = useState(false);
  const { id } = useParams();
  const { load } = useNDVIReportContext();

  const handleDelete = () => {
    errorHandler(ReportService.deleteNdviReport(id)).then(res => {
      if (res.ok) {
        openAlert(
            'success',
            t("reports:ndvi.delete.success.title"),
            t("reports:ndvi.delete.success.message")
        );
        load();
        navigate("/gis/reports/ndvi");
      }

      setDeleting(false);
    });
  };
 
  if (deleteReport == false) {
    return (<></>);
  }
  
  return (
    <>
      <div className="row mb-5">
        <div className="col-12">
          <span className="fw-bold">{t("reports:ndvi.delete.question")}</span>
        </div>
        <div className="col-12 mt-3">
          <button onClick={() => handleDelete()} disabled={deleting} type="button" className="btn btn-sm btn-danger me-2">{t("reports:ndvi.delete.yes_button")}</button>
          <button type="button" onClick={() => setDeleteReport(false)} className="btn btn-sm btn-primary">{t("reports:ndvi.delete.no_button")}</button>
        </div>
      </div>
    </>
  );
};

export default DeleteReport;