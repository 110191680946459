import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";

import { KTSVG } from "../../../../../_metronic/helpers";
import "./Report.scss";

const Report = ({ report }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = () => {
    if (report.status != "completed") {
      openAlert('info', t("reports:ndvi.list.still_creating"));
      return;
    }

    navigate(`/gis/reports/ndvi/view/${report.id}`)
  }

  return (
    <span style={{ cursor: "pointer" }} onClick={onClick} className="d-flex report-item align-items-center p-5">
      <span className="bullet bullet-vertical h-40px bg-primary me-5"></span>
      <div className="flex-grow-1">
        <span className="text-gray-800 text-hover-primary fw-bold fs-6">{report.name}</span>
        <p className="p-0 d-block">
          {report.status == "completed" && <span class="badge badge-light-success">{t("reports:ndvi.list.status.completed")}</span>}
          {report.status == "in_progress" && <span class="badge badge-light-info">{t("reports:ndvi.list.status.in_progress")}</span>}
          {report.status == "scheduled" && <span class="badge badg-light-primary">{t("reports:ndvi.list.status.scheduled")}</span>}
        </p>
      </div>
      <span className="btn btn-sm btn-light-primary fs-8 fw-bold"><KTSVG path="/media/icons/duotune/arrows/arr024.svg" /></span>
    </span>
  );
}

export default Report;