import { createContext, useContext, useEffect, useState } from "react";
import { useViewAmbientContext } from "../AmbientsContext";

const AmbientDialogContext = createContext();

export function useAmbientDialogContext() {
  return useContext(AmbientDialogContext);
}

export const AmbientDialogConsumer = AmbientDialogContext.Consumer;

export function AmbientDialogProvider({ children }) {
  const viewAmbient = useViewAmbientContext();

  /* ambient dialog form */
  const totalYears = [];
  for (var i = 2013; i <= new Date().getFullYear(); i++) {
    totalYears.push(i);
  }

  const [countAmbients, setCountAmbients] = useState('5');
  const [season, setSeason] = useState('Estival');
  const [years, setYears] = useState(totalYears);
  const [monthFrom, setMonthFrom] = useState("10");
  const [monthTo, setMonthTo] = useState("05");
  const [disabled, setDisabled] = useState(false);

  const value = {
    countAmbients, setCountAmbients,
    season, setSeason,
    years, setYears,
    monthFrom, setMonthFrom,
    monthTo, setMonthTo,
    disabled, setDisabled
  };

  useEffect(() => {
    if (viewAmbient.dialogInitialData != null) {
      const initial = viewAmbient.dialogInitialData;
      setCountAmbients(initial['countAmbients']);
      setYears(initial['years']);
      setMonthFrom(initial['monthFrom']);
      setMonthTo(initial['monthTo']);
      viewAmbient.setDialogInitialData(null);
    }
  }, [viewAmbient.dialogInitialData])

  return (
    <>
      <AmbientDialogContext.Provider value={value}>
        {children}
      </AmbientDialogContext.Provider>
    </>
  );
};