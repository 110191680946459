import { createContext, useContext, useState, useEffect, useCallback, useRef } from "react";

import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewLandContext } from "../ViewLandContext";
import * as LandService from "../../../services/LandService";
import { openAlert } from "../../../../../../../_metronic/elements/NotificationAlert";
import { useTranslation } from "react-i18next";

const ViewAmbientContext = createContext();

export function useViewAmbientContext() {
  return useContext(ViewAmbientContext);
}

export const ViewAmbientConsumer = ViewAmbientContext.Consumer;

export function ViewAmbientProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [createTriggered, setCreateTriggered] = useState();
  const [ambientData, setAmbientData] = useState(null);
  const [imageAmbientData, setImageAmbientData] = useState(null);
  const [ambientDialog, setAmbientDialog] = useState(false);
  const [deleteAmbient, setDeleteAmbient] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();
  const timeout = useRef(null);
  const viewLandContext = useViewLandContext();

  const loadAmbientData = useCallback(async () => {
    const res = await errorHandler(LandService.getAmbient(viewLandContext.land.land_id), [404]);
    if (res.ok) {
      if (res.data.data.status == 0) {
        timeout.current = setTimeout(() => loadAmbientData(), 30000);
      }

      setAmbientData(res.data.data);
    } else {
      setAmbientData(null);
    }

    setLoading(false);
  }, [viewLandContext.land]);

  const loadImageAmbientData = useCallback(async () => {
    const res = await errorHandler(LandService.getImageAmbient(viewLandContext.land.land_id), [404]);
    if (res.ok) {

      setImageAmbientData(res.data.data);
    
    } else {
      setImageAmbientData(null);
    }

    setLoading(false);
  }, [viewLandContext.land]);

  useEffect(() => {
    setLoading(true);
    if (viewLandContext.land != null) {
      loadAmbientData();
      loadImageAmbientData();
    }
  }, [viewLandContext.land]);

  const create = useCallback(async (triggered, { monthRange, yearsList, countAmbients, update }) => {
    setCreateTriggered(triggered);

    const data = {
      month_range: monthRange,
      years_list: yearsList,
      count_ambients: countAmbients
    };
    if (!monthRange) {
      data['month_range'] = ["10", "05"];
    }

    if (!yearsList) {
      const def = [];
      for (var i = 2013; i <= new Date().getFullYear(); i++) {
        def.push(i);
      }
      data['years_list'] = def;
    }

    if (!countAmbients) {
      data['count_ambients'] = 5;
    }

    let res;

    let ignore = []
    if (triggered == "personalized") {
      ignore = [402];
    }

    if (update == true) {
      res = await errorHandler(LandService.updateAmbient(
        viewLandContext.land.land_id, data
      ), ignore);
    } else {
      res = await errorHandler(LandService.createAmbient(
        viewLandContext.land.land_id, data
      ), ignore);
    }

    if (res.ok) {
      openAlert("success", t("ambient:create.messages.success.title"), t("ambient:create.messages.success.message"));
      setAmbientData(res.data.data);
      timeout.current = setTimeout(() => loadAmbientData(), 10000);

      if (triggered == 'personalized') {
        setAmbientDialog(false);
      }
      setCreateTriggered(null);
    } else if (res.error_type == "need_payment") {
      setCreateTriggered(null);
      return "need_payment";
    }

    setCreateTriggered(null);
  }, [viewLandContext.land]);

  const [dialogInitialData, setDialogInitialData] = useState(null);
  const updateAmbientDialog = () => {
    setAmbientDialog(true);
    setDialogInitialData({
      countAmbients: ambientData.params.breaks,
      monthFrom: ambientData.params.month_range[0],
      monthTo: ambientData.params.month_range[1],
      years: ambientData.params.years
    });
  };

  const value = {
    loading,
    loadAmbientData,
    ambientData,
    imageAmbientData, setImageAmbientData,
    loadImageAmbientData,
    createTriggered,
    create,
    selectedElement, setSelectedElement,

    ambientDialog, setAmbientDialog,
    deleteAmbient, setDeleteAmbient,
    dialogInitialData, setDialogInitialData,
    updateAmbientDialog
  };

  return (
    <>
      <ViewAmbientContext.Provider value={value}>
        {children}
      </ViewAmbientContext.Provider>
    </>
  );
};