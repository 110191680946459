import { Search, CreateFieldButton, ListFieldButton, Reports } from "./Toolbar";

export const MobileContent = () => {

    return (
        <>
            <div className="p-2">
                <Search />
            </div>
            <div className="d-flex flex-row-auto flex-center mt-5">      
                <Reports />      
                <div className="bullet bg-secondary h-35px w-1px mx-6"></div>
                <ListFieldButton />
                <div className="bullet bg-secondary h-35px w-1px mx-6"></div>
                <CreateFieldButton />
            </div>
        </>
    );
};