import { createContext, useContext, useState } from "react";

import PaymentModal from "./PaymentModal";

const PaymentContext = createContext();

export function usePayment() {
  return useContext(PaymentContext);
}

export const PaymentConsumer = PaymentContext.Consumer;

export function PaymentProvider({ children }) {
    const [ active, setActive ] = useState(false);

    const value = {
        active,
        setActive
    };

    return (
        <>
            <PaymentContext.Provider value={value}>
                <PaymentModal />
                {children}
            </PaymentContext.Provider>
        </>
    )
};