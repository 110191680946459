import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Action = ({ formik, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="form-group d-flex flex-wrap flex-center">
      <button
        type="submit"
        disabled={
          loading || !formik.isValid
        }
        className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
      >
        <span>{t("auth:create_account.action")}</span>
        {loading && <span className="ml-3 spinner spinner-white"></span>}
      </button>

      <Link to="/auth/login">
        {t("auth:create_account.already_has_account")}
      </Link>
    </div>
  );
};

export default Action;