import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import clsx from 'clsx';

const Password = ({ formik }) => {
    const { t } = useTranslation();

    return (
        <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>{t("auth:login_box.input.password.label")}</label>
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              {t("auth:login_box.forgot_password_action")}
            </Link>
          </div>
        </div>
        <input
          type='password'
          placeholder={t("auth:login_box.input.password.placeholder")}
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
    );
};

export default Password;