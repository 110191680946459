import { createContext, useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useErrorHandler } from "../../../../../../_metronic/helpers/ErrorHandler";
import { useMapLayersV2 } from "../../../Map/MapLayersV2Context";
import { useViewFieldContext } from "../ViewFieldContext";
import * as LandService from '../../services/LandService';
import { drawState } from "../../../Map/Draw/DrawState";

const ViewLandContext = createContext();

export function useViewLandContext() {
  return useContext(ViewLandContext);
}

export const ViewLandConsumer = ViewLandContext.Consumer;

export function ViewLandProvider({ children }) {
    const [ loading, setLoading ] = useState(true);
    const [ land, setLand ] = useState(null);
    const [ landId, setLandId ] = useState(null);
    const { errorHandler } = useErrorHandler();
    const [ mapLoaded, setMapLoaded ] = useState(false);
    const currentLand = useRef(null);
    const viewFieldContext = useViewFieldContext();
    const navigate = useNavigate();
    const mapLayersV2Context = useMapLayersV2();

    const initViewLand = (map) => {
        setMapLoaded(true);

        map.on('click', 'land', (e) => {
            if (drawState.isDrawing()) {
                return
            };

            const currentLand = e.features[0].properties;

            if (currentLand.opacity != 0) {
                navigate(`/gis/field/view/${currentLand.field_id}/land/view/${currentLand.land_id}`);
            }
        });

        map.on('mouseenter', 'land', (e) => {
            if (drawState.isDrawing()) {
                return
            };

            map.getCanvas().style.cursor = 'pointer';
        });

        map.on('mouseleave', 'land', (e) => {
            if (drawState.isDrawing()) {
                return
            };
            
            map.getCanvas().style.cursor = 'unset';
        });
    };

    const loadLand = () => {
        errorHandler(LandService.get(landId)).then(res => {
            if (res.ok) {
                setLand(res.data.data);
                startView(landId);

                setLoading(false);
            } else {
                navigate(`/gis/field/view/${viewFieldContext.field.field_id}`)
            }
        });
    };

    const startView = (id) => {
        mapLayersV2Context.fit(id);
        mapLayersV2Context.land.unfill(id);
        mapLayersV2Context.land.marker.hide(id);
        
        if (id != currentLand.current && currentLand.current != null) {
            _mapShow(currentLand.current);
        }

        currentLand.current = id;
    };

    const _mapShow = (landId) => {
        mapLayersV2Context.land.fill(landId);
        mapLayersV2Context.land.marker.show(landId);
    }

    const stopViewingLand = (landId) => {
        setLandId(null);
        setLand(null);
        _mapShow(landId);
    }

    useEffect(() => {
        if (landId != null) {
            setLoading(true);
            if (mapLoaded == false) { return; }

            loadLand();
        }
    }, [landId, mapLoaded]);

    const value = {
        land,
        setLand,
        setLandId,
        initViewLand,
        loading,
        stopViewingLand,
    };

    return (
        <>
            <ViewLandContext.Provider value={value}>
                {children}
            </ViewLandContext.Provider>
        </>
    );
};