/* eslint import/no-webpack-loader-syntax: off */
import { useEffect, useRef } from 'react';

import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { useGisMapContext } from './GisMapContext';
import { useMapLayersV2 } from '../Map/MapLayersV2Context';
import { useViewLandContext } from '../Fields/ViewField/ViewLand/ViewLandContext';
import './GisMap.style.scss';

const GisMap = () => {
    const container = useRef(null);
    const { initViewLand } = useViewLandContext();
    const { setMap } = useGisMapContext();
    const mapLayersV2 = useMapLayersV2(); 

    useEffect(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoiZ2lhbm90dGlmcmFuY28iLCJhIjoiY2w2ZHMzNjF4MDNuMjNpcDU1MHoxMmJ2OSJ9.-08oVGVdX82fh1cnhY40pQ";
        const map = new mapboxgl.Map({
            maxZoom: 20,
            container: container.current,
            center: [0, 0],
            zoom: 3,
            //style: 'mapbox://styles/mapbox/satellite-v9',
            style: {
                'version': 8,
                'sources': {
                    'raster-tiles': {
                        'type': 'raster',
                        'tiles': [
                            'https://mt0.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
                            'https://mt1.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
                            'https://mt2.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
                            'https://mt3.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                        ],
                        'tileSize': 256,
                    }
                },
                "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
                'layers': [{
                    'id': 'simple-tiles',
                    'type': 'raster',
                    'source': 'raster-tiles',
                    'minzoom': 0,
                    'maxzoom': 0
                }]
            }
        });

        setMap(map);

        const coordinatesGeocoder = function (query) {
            // Match anything which looks like
            // decimal degrees coordinate pair.
            const matches = query.match(
                /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i
            );

            if (!matches) {
                return null;
            }
             
            function coordinateFeature(lng, lat) {
                return {
                    center: [lng, lat],
                    geometry: {
                        type: 'Point',
                        coordinates: [lng, lat]
                    },
                    place_name: 'Lat: ' + lat + ' Lng: ' + lng,
                    place_type: ['coordinate'],
                    properties: {},
                    type: 'Feature'
                };
            }
             
            const coord1 = Number(matches[1]);
            const coord2 = Number(matches[2]);
            const geocodes = [];
             
            if (coord1 < -90 || coord1 > 90) {
                // must be lng, lat
                geocodes.push(coordinateFeature(coord1, coord2));
            }
             
            if (coord2 < -90 || coord2 > 90) {
                // must be lat, lng
                geocodes.push(coordinateFeature(coord2, coord1));
            }
             
            if (geocodes.length === 0) {
                // else could be either lng, lat or lat, lng
                geocodes.push(coordinateFeature(coord1, coord2));
                geocodes.push(coordinateFeature(coord2, coord1));
            }
             
            return geocodes;
        };
            
        map.on('load', () => { 
            // disable map rotation using right click + drag
            map.dragRotate.disable();
            
            // disable map rotation using touch rotation gesture
            map.touchZoomRotate.disableRotation();

            // Geocoder
            map.addControl(new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                localGeocoder: coordinatesGeocoder,
                reverseGeocode: true
            }), 'top-left');

            // Navigatio Control
            map.addControl(new mapboxgl.NavigationControl({
                showCompass: false
            }), 'top-left');

            // Initialize maplayers
            mapLayersV2.init(map);

            // Initialize view land
            initViewLand(map);
        });
    }, []);

    return (
        <>
            {mapLayersV2.loading && <div className="map-loading">
                <div className="d-flex align-items-center">
                    <span style={{width: "5rem", height: "5rem" }} className="spinner-grow text-primary align-middle ml-auto"></span>
                </div>
            </div>}
            <div ref={el => container.current = el} className="map-container" />
        </>
    );
};

export { GisMap };