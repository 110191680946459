import { useEffect, useCallback } from "react";
import { format } from "date-fns";
import axios from "axios";

import { useErrorHandler } from "../../../../../../../../../_metronic/helpers/ErrorHandler";
import * as LandService from "../../../../../services/LandService";
import { useMapLayers } from "../../../../../MapLayers";
import { useViewImageContext } from "../../ViewImageContext";
import { useMapLayersV2 } from "../../../../../../Map/MapLayersV2Context";

const Visible = ({ land, selectedDate }) => {
    const mapLayers = useMapLayersV2();
    const { errorHandler } = useErrorHandler();
    const viewImageContext = useViewImageContext();
    const cancelTokenRequest = axios.CancelToken.source();
    const layer = `visible-${land.land_id}-${selectedDate}`;
    const loadImage = useCallback(async () => {
        mapLayers.fit(land.land_id);
        viewImageContext.setLoading(true);
        const res = await errorHandler(LandService.getLayerV2(land.land_id, "visible", {
            date: format(selectedDate, 'yyyy-MM-dd')
        }, cancelTokenRequest));

        if (res.ok) {
            const url = window.URL || window.webkitURL;
            const src = url.createObjectURL(res.data);
            mapLayers.land.addImage({
                landId: land.land_id,
                imageType: "visible",
                imageUrl: src,
                polygon: land.polygons,
                bbox: JSON.parse(res.headers["x-bbox"]),
            });
        }

        viewImageContext.setLoading(false);
    }, [land, selectedDate]);

    useEffect(() => {
        loadImage();
    }, [land, selectedDate]);

    useEffect(() => {
        return () => {
            mapLayers.land.deleteImage({ landId: land.land_id, imageType: "visible" });
        }
    }, []);

    return (
        <>
        </>
    );
};

export default Visible;