import axios from 'axios'

const CREATE_FIELD_PATH = "/api/v1/fields";
const LIST_FIELD_PATH = "/api/v2/fields";
const GET_FIELD_PATH = "/api/v2/fields/:id";

export const create = (name, polygons) => {
    return axios.post(CREATE_FIELD_PATH, {
        name,
        polygons
    }).then(res => {
        if (res.status == 201) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const list = () => {
    return axios.get(LIST_FIELD_PATH).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
}

export const get = (fieldId) => {
    return axios.get(GET_FIELD_PATH.replace(":id", fieldId)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    })
};

export const update = (field_id, data) => {
    return axios.put("/api/v2/fields/:id".replace(':id', field_id), data).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};

export const deleteField = (field_id) => {
    return axios.delete("/api/v2/fields/:id".replace(':id', field_id)).then(res => {
        if (res.status == 200) {
            return {
                ok: true,
                data: res.data
            };
        }
    });
};