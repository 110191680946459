import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { KTSVG } from '../../../helpers'

const LanguageSelector = ({
  toggleBtnClass = '',
  toggleBtnIconClass = 'svg-icon-2',
  menuPlacement = 'bottom-end',
  menuTrigger = "{default: 'click', lg: 'hover'}",
}) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const switchLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      {/* begin::Menu toggle */}
      <a
        href='#'
        className={clsx('btn btn-icon ', toggleBtnClass)}
        data-kt-menu-trigger={menuTrigger}
        data-kt-menu-attach='parent'
        data-kt-menu-placement={menuPlacement}
      >
        {lang === 'es' && (
          <KTSVG
            path='/media/svg/languages/es.svg'
            className={clsx('theme-light-hide', toggleBtnIconClass)}
          />
        )}

        {lang === 'en' && (
          <KTSVG
            path='/media/svg/languages/en.svg'
            className={clsx('theme-dark-hide', toggleBtnIconClass)}
          />
        )}
      </a>
      {/* begin::Menu toggle */}

      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', { active: lang === 'es' })}
            onClick={() => switchLang('es')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/svg/languages/es.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>Español</span>
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3 my-0'>
          <a
            href='#'
            className={clsx('menu-link px-3 py-2', { active: lang === 'en' })}
            onClick={() => switchLang('en')}
          >
            <span className='menu-icon' data-kt-element='icon'>
              <KTSVG path='/media/svg/languages/en.svg' className='svg-icon-3' />
            </span>
            <span className='menu-title'>English</span>
          </a>
        </div>
        {/* end::Menu item */}

      </div>
      {/* end::Menu */}
    </>
  )
}

export default LanguageSelector;
