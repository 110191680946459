import { createContext, useContext, useRef } from "react";

const GisMapContext = createContext();

export function useGisMapContext() {
  return useContext(GisMapContext);
}

export const GisMapConsumer = GisMapContext.Consumer;

export function GisMapProvider({ children }) {
    const map = useRef(null);
    const draw = useRef(null);

    const setMap = (m) => {
        map.current = m
    };

    const setDraw = (d) => {
        draw.current = d;
    };

    const value = {
        setMap,
        setDraw,

        map
    }

    return (
        <>
            <GisMapContext.Provider value={value}>
                {children}
            </GisMapContext.Provider>
        </>
    )
};