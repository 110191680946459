import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next"

import { useErrorHandler } from '../../../../../_metronic/helpers/ErrorHandler';
import { Loading } from '../../../../../_metronic/elements/Loading';
import Field from './Field';
import * as FieldService from '../services/FieldService';

const ListFields = () => {
    const [ loading, setLoading ] = useState(true);
    const [ fields, setFields ] = useState([]);
    const { errorHandler } = useErrorHandler();
    const { t } = useTranslation(); 
    const navigate = useNavigate();

    useEffect(() => {
        errorHandler(FieldService.list()).then(res => {
            if (res.ok) {
                setFields(sortFieldsByName(res.data.fields));
            }

            setLoading(false);
        });
    }, []);

    const sortFieldsByName = (fieldsArray) => {
        return fieldsArray.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();
    
            return nameA.localeCompare(nameB, 'en', { numeric: true });
        });
    };

    return (
        <>
        <div className="card shadow-sm field-view-container displayable right-card-data">
            <div className="card-header collapsible">
                <h4 className="card-title cursor-pointer" data-bs-toggle="collapse" data-bs-target="#kt_list_fields_collapsible">{t("fields:list.title")}</h4>
                <div className="card-toolbar">
                    <button disabled={false} className='btn btn-sm btn-primary cursor-pointer' id='kt_toolbar_primary_button' onClick={() => navigate("/gis/field/new")}>
                        {t("fields:buttons.create_field")}
                    </button>
                </div>
            </div>
            <div id="kt_list_fields_collapsible" className="collapse show">
                <div className={`card-body ${(loading || fields.length < 1) ? "p-5" : "p-0"}`}>
                    {loading && <Loading />}
                    {!loading && fields.map(field => {
                        return <Field field={field} />
                    })}
                    {!loading && fields.length < 1 && (
                        <>
                            <h5>{t("fields:list.messages.no_fields")}</h5>
                        </>
                    )}
                </div>
            </div>
        </div>
        </>
    );
};

const ListFieldsContainer = () => {

    return (
        <>
        <div className="d-flex flex-column flex-row-auto field-view-flex-container">
              <div className="d-flex flex-column-auto">
                  <ListFields />
              </div>
          </div>
        </>
    );
};

export default ListFieldsContainer;