import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import clsx from 'clsx'
import * as Yup from 'yup'

import { useErrorHandler } from '../../../../../_metronic/helpers/ErrorHandler'
import * as AuthService from "../../services/AuthService";
import { useTranslation } from 'react-i18next'

function ForgotPassword() {
  const { t } = useTranslation();
  const { errorHandler } = useErrorHandler();

  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState(undefined);
  const [email, setEmail] = useState(null);

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("auth:forgot_password.input.email.errors.wrong_format"))
      .min(3, t("auth:forgot_password.input.email.errors.min"))
      .max(50, t("auth:forgot_password.input.email.errors.max"))
      .required(t("auth:forgot_password.input.email.errors.required")),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true)
      setHasErrors(undefined)
      errorHandler(AuthService.requestPassword(values.email))
        .then((res) => {
          if (res.ok) {
            setEmail(values.email);
            resetForm();
            setHasErrors(false)
          }

          setLoading(false);
        });
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>{t("auth:forgot_password.title")}</h1>
        </div>

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-success' dangerouslySetInnerHTML={{ __html: t("auth:forgot_password.success", { email }) }}></div>
          </div>
        )}

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t("auth:forgot_password.input.email.label")}</label>
          <input
            type='email'
            placeholder={t("auth:forgot_password.input.email.placeholder")}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>

        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            disabled={loading || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>{t("auth:forgot_password.action")}</span>}
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {t("auth:forgot_password.completing")}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={loading}
            >
              {t("auth:forgot_password.cancel")}
            </button>
          </Link>{' '}
        </div>
      </form>
    </>
  )
}

export default ForgotPassword;