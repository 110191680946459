import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useFormik } from 'formik';
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';

import { Modal } from "../../../../../_metronic/elements/Modal";
import { InputText } from "../../../../../_metronic/elements/Inputs";
import { openAlert } from "../../../../../_metronic/elements/NotificationAlert";
import { useErrorHandler } from "../../../../../_metronic/helpers/ErrorHandler";
import { useDrawV2 } from "../../Map/Draw/DrawV2Context";
import { DrawTools } from "../../Map/Draw/DrawState";
import { useMapLayersV2 } from "../../Map/MapLayersV2Context";
import { useDrawEndButton } from "../../Map/Draw/DrawEndButton/DrawEndButtonContext";
import * as FieldService from '../services/FieldService';
import { useSearchContext } from "../../Toolbar/SearchContext";

const ModalHeader = () => {
  const { t } = useTranslation();

  return (
    <div className="modal-header p-5">
      <h5 className="modal-title">{t("fields:create.title")}</h5>
    </div>
  );
};

const ModalBody = ({ formik, loading }) => {
  const { t } = useTranslation();

  return (
    <div className="modal-body p-5">
      <div className="row">
        <div className='col-lg-12 fv-row'>
          <InputText disabled={loading} formik={formik} name="name" inputProps={{
            placeholder: t("fields:create.input.name.placeholder")
          }} />
        </div>
      </div>
    </div>
  );
};

const ModalFooter = ({ loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="modal-footer">
      <button disabled={loading == true} type="button" className="btn btn-light" onClick={() => {
        navigate('/gis');
      }}>{t("fields:create.buttons.cancel")}</button>
      <button type="submit" className="btn btn-primary">
        {!loading && t("fields:create.buttons.create")}
        {loading && (
          <span className='indicator-progress' style={{ display: 'block' }}>
            {t("fields:create.messages.creating")}{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        )}
      </button>
    </div>
  );
};

const CreateField = () => {
  const [modalOpened, setModalOpened] = useState(false);
  const [polygonDraw, setPolygonDraw] = useState(null);
  const [loading, setLoading] = useState(false);
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();
  const mapLayers = useMapLayersV2();
  const navigate = useNavigate();
  const draw = useDrawV2();
  const search = useSearchContext();
  const drawEndButton = useDrawEndButton();
  const schema = Yup.object().shape({
    name: Yup.string().min(
      1, t("fields:create.input.name.errors.min")
    ).max(
      32, t("fields:create.input.name.errors.max")
    ).required(t("fields:create.input.name.errors.required")),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      errorHandler(FieldService.create(values.name, polygonDraw.geometry)).then(res => {
        if (res.ok) {
          draw.deleteLayer(polygonDraw.id);
          search.load();
          mapLayers.reloadSource();
          openAlert('success', t("fields:create.messages.success", { name: res.data.data.name }));
          navigate(`/gis/field/view/${res.data.data.field_id}`)
        }

        setLoading(false);
      })
    },
  });

  useEffect(() => {
    openAlert('info', t("fields:create.title"), t("fields:create.messages.start_drawing"));
    draw.startDrawing({
      tools: [DrawTools.POLYGON, DrawTools.CIRCLE],
      onCreate: (e, isFile) => {
        if (!isFile) {
          setPolygonDraw(e.features[0]);
        }

        drawEndButton.show({
          title: t("fields:create.messages.on_drawed"),
          onFinish: () => {
            setModalOpened(true);
            drawEndButton.hide();
            draw.endDrawing();
          },
          onCancel: () => {
            navigate('/gis');
          }
        });
      },
      onUpdate: (e) => {
        setPolygonDraw(e.features[0]);
      }
    });

    return () => {
      drawEndButton.hide();
      draw.endDrawing();
    };
  }, []);

  return (
    <>
      <Modal open={modalOpened}>
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={formik.handleSubmit} className='form'>
              <ModalHeader />
              <ModalBody formik={formik} loading={loading} />
              <ModalFooter loading={loading} />
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CreateField;