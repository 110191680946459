import { useState, useMemo, useEffect } from "react";
import { DatePicker } from "@material-ui/pickers";
import { parseISO, differenceInDays, format, parse } from "date-fns";
import { useTranslation } from "react-i18next";

import { KTSVG } from "../../../../../../../_metronic/helpers";
import { useViewNDVIContext } from "../ViewNDVIContext";
import { Loading } from "../../../../../../../_metronic/elements/Loading";

const DateSelector = ({ text, date, setDate, openedDatePicker, setOpenedDatePicker, availableDates }) => {
  return (
    <>
      <DatePicker
        open={openedDatePicker}
        value={date}
        onOpen={() => setOpenedDatePicker(true)}
        onClose={() => setOpenedDatePicker(false)}
        onChange={(x) => setDate(x)}
        renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
          if (availableDates.indexOf(format(day, "yyyy-LL-dd")) == -1) {
            return dayComponent;
          }

          let extraClass = "";
          if (selectedDate != null && selectedDate.getTime() == day.getTime()) {
            extraClass = "active";
          }
          return <div className={'date-with-data ' + extraClass}>{dayComponent}</div>;
        }}
        shouldDisableDate={(date) => {
          return availableDates.indexOf(format(date, "yyyy-LL-dd")) == -1
        }}
        animateYearScrolling
        TextFieldComponent={() => null}
      />
      <a onClick={(e) => {
        e.preventDefault();
        setOpenedDatePicker(true);
      }} href="#" className={`fw-bold text-gray-800 text-hover-primary fs-7 mt-5 mb-5`}>
        <KTSVG
          path="/media/icons/duotune/general/gen014.svg"
          className="svg-icon svg-icon-2x svg-icon-primary me-5"
        />
        {!date || date == null ? text : format(
          date, 'dd/MM/yyyy'
        )}
      </a>
    </>
  )
};

const getClosestDate = (dates, targetDate) => {
  let currentDiff = Number.MAX_VALUE;
  let choiced = null;

  dates.forEach(report => {
    const diff = Math.abs(differenceInDays(targetDate, parseISO(report.date)));
    if (diff < currentDiff) {
      currentDiff = diff;
      choiced = report;
    }
  });

  return choiced
};

const getRanking = (report, firstDate, secondDate) => {
  const { lands, ndvi_land_dates } = report;
  const reportDatesGroupByLand = {};

  ndvi_land_dates.forEach(date => {
    if (!reportDatesGroupByLand[date.land_id]) {
      reportDatesGroupByLand[date.land_id] = [];
    }

    reportDatesGroupByLand[date.land_id].push(date);
  });

  const firstDatesReport = Object.values(reportDatesGroupByLand).map(
    e => getClosestDate(e.filter(e => e.usage == "USED"), firstDate)
  ).sort(
    (a, b) => a.avg_index > b.avg_index ? -1 : 1
  );
  const secondDatesReport = Object.values(reportDatesGroupByLand).map(
    e => getClosestDate(e.filter(e => e.usage == "USED"), secondDate)
  ).sort(
    (a, b) => a.avg_index > b.avg_index ? -1 : 1
  );

  const total = [];
  for (var z in firstDatesReport) {
    const currentLand = lands.filter(e => e.land_id == firstDatesReport[z].land_id)[0];

    if (!currentLand) {
      continue;
    }

    const [_, secondIndex, secondValue] = secondDatesReport.map(
      (q, index) => [q.land_id == currentLand.land_id, index, q]
    ).filter(
      e => e[0]
    )[0]
    total.push({
      land: currentLand,
      position: parseInt(z) + 1,
      firstAvgIndex: firstDatesReport[z].avg_index,
      secondRankingPosition: parseInt(secondIndex) + 1,
      secondIndex: secondValue.avg_index
    });
  }

  return total;
}


const Ranking = () => {
  const context = useViewNDVIContext();
  const [loading, setLoading] = useState(false);
  const [firstDate, setFirstDate] = useState(null);
  const [secondDate, setSecondDate] = useState(null);
  const [firstOpenedDatePicker, setFirstOpenedDatePicker] = useState(false);
  const [secondOpenedDatePicker, setSecondOpenedDatePicker] = useState(false);
  const { t } = useTranslation();
  const { ranking, availableDates } = useMemo(() => {
    if (firstDate == null || secondDate == null) {
      return { ranking: null, availableDates: null };
    }

    const availableDates = context.report.ndvi_land_dates.filter(e => e.usage == "USED").map(e => parseISO(e.date)).map(e => format(e, "yyyy-LL-dd"))
    return { ranking: getRanking(context.report, firstDate, secondDate), availableDates };
  }, [firstDate, secondDate, context.report]);

  useEffect(() => {
    const dates = context.report.ndvi_land_dates.map(e =>
      [parseISO(e.date), parse(e.date, "yyyy-LL-dd", new Date())]
    ).sort((a, b) => a[1] - b[1]);
    setFirstDate(dates[dates.length - 1][0]);
    setSecondDate(dates[0][0]);
  }, [])

  if (loading || ranking == null) {
    return <><Loading /></>;
  }

  return (
    <>
      <div className="row">
        <div className="col-6">
          <DateSelector
            text={t("reports:ndvi.view.reports.ranking.ranking_date")}
            date={firstDate}
            setDate={setFirstDate}
            openedDatePicker={firstOpenedDatePicker}
            setOpenedDatePicker={setFirstOpenedDatePicker}
            availableDates={availableDates} />
        </div>
        <div className="col-6">
          <DateSelector
            text={t("reports:ndvi.view.reports.ranking.second_date")}
            date={secondDate}
            setDate={setSecondDate}
            openedDatePicker={secondOpenedDatePicker}
            setOpenedDatePicker={setSecondOpenedDatePicker}
            availableDates={availableDates}
            minDate={secondDate} />
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-row-bordered">
          <thead>
            <tr class="fw-bold fs-7 text-gray-800">
              <th>{t("reports:ndvi.view.reports.ranking.columns.position")}</th>
              <th>{t("reports:ndvi.view.reports.ranking.columns.land")}</th>
              <th>{t("reports:ndvi.view.reports.ranking.columns.avg_ndvi")} {format(firstDate, "dd/LL/yyyy")}</th>
              <th>{t("reports:ndvi.view.reports.ranking.columns.avg_ndvi")} {format(secondDate, "dd/LL/yyyy")}</th>
              <th>{t("reports:ndvi.view.reports.ranking.columns.variation_ranking")}</th>
            </tr>
          </thead>
          <tbody>
            {ranking.map(e => {
              const variation = e.secondRankingPosition - e.position;
              let color = "#ffffff";
              if (variation > 0) {
                color = "#7cff9b"
              } else if (variation < 0) {
                color = "#ff7c7c"
              }
              return (
                <tr>
                  <td>{e.position}</td>
                  <td>{e.land.name}</td>
                  <td>{parseFloat(e.firstAvgIndex).toFixed(3)}</td>
                  <td>{parseFloat(e.secondIndex).toFixed(3)}</td>
                  <td style={{ backgroundColor: color }}>{variation}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Ranking;