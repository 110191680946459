import { createContext, useContext, useRef, useState } from "react";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import {
    CircleMode,
    DragCircleMode,
    DirectMode,
    SimpleSelectMode
} from 'mapbox-gl-draw-circle';
import CutPolygonMode from 'mapbox-gl-draw-cut-polygon-mode';
import mapboxGlDrawPassingMode from 'mapbox-gl-draw-passing-mode';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import DrawStyle from "./DrawStyle";

const DrawContext = createContext();

export function useDraw() {
  return useContext(DrawContext);
}

export const DrawConsumer = DrawContext.Consumer;

export function DrawProvider({ draw, children }) {
    const mapInstance = useRef(null);
    const drawInstance = useRef(null);
    const canTrashVertice = useRef(false);
    const drawing = useRef(false);
    const [ isDrawing, setIsDrawing ] = useState({ isDrawing: false, circle: false, polygon: false });
    const [ endButton, setEndbutton ] = useState({ show: false });
    const [ onCreateCallback, onUpdateCallback ] = [useRef(null), useRef(null)];
    const [ endButtonOnFinishCallback, endButtonOnCancelCallback ] = [useRef(null), useRef(null)];

    const onDraw = (e) => {
        if (e.type == 'draw.create') {
            if (typeof onCreateCallback.current == 'function') {
                onCreateCallback.current(e);
            }
        } else if (e.type == 'draw.update') {
            if (typeof onUpdateCallback.current == 'function') {
                onUpdateCallback.current(e);
            }
        }
    };

    const initDraw = (map) => {
        return;
        mapInstance.current = map;

        /* Initialize draw */
        const draw = new MapboxDraw({
            displayControlsDefault: false,
            styles: DrawStyle,
            modes: Object.assign({
                draw_circle: CircleMode,
                drag_circle: DragCircleMode,
                direct_select: DirectMode,
                simple_select: SimpleSelectMode,
                static: StaticMode,
                cutPolygonMode: CutPolygonMode,
                passing_mode_polygon: mapboxGlDrawPassingMode(
                    MapboxDraw.modes.draw_polygon
                ),
            }, MapboxDraw.modes)
        });
        drawInstance.current = draw;

        // Draw
        map.addControl(draw);

        /* Draw events */
        map.on('dblclick', e => {
            if (canTrashVertice.current) {
                draw.trash();
            }
        });
        map.on('draw.modechange', (e) => {
            if (e.mode.simple_select == 'simple_select') {
                map.getCanvas().style.cursor = 'unset';
            }
        });
        map.on('draw.create', onDraw);
        map.on('draw.update', onDraw);
        map.on('draw.actionable', (e) => {
            if (e.actions.trash) {
                canTrashVertice.current = true;
            }
        });
        map.on('draw.modechange', (e) => { if (e.mode.simple_select == 'simple_select') { map.getCanvas().style.cursor = 'unset'; } });
    };

    const startDrawing = ({ polygon, circle, point, uploadFile, onCreate, onUpdate }) => {
        drawing.current = true;
        onCreateCallback.current = onCreate;
        onUpdateCallback.current = onUpdate;
        setIsDrawing({
            isDrawing: true,
            polygon,
            circle,
            point,
            uploadFile
        });
    };

    const startDrawingSilent = () => {
        drawing.current = true;
    }

    const endDrawing = () => {
        drawing.current = false;
        mapInstance.current.getCanvas().style.cursor = 'unset';
        setIsDrawing({
            isDrawing: false
        });
        hideEndButton();
    };

    const showEndButton = ({ title, showCancelButton, onFinish, onCancel, buttonText }) => {
        endButtonOnFinishCallback.current = onFinish;
        endButtonOnCancelCallback.current = onCancel;
        setEndbutton({
            show: true,
            title,
            showCancelButton,
            buttonText
        });
    };

    const hideEndButton = () => {
        setEndbutton({
            show: false
        })
    }

    const endButtonEventEmitter = (action) => {
        if (action == 'finish') {
            if (endButtonOnFinishCallback.current != null) {
                endButtonOnFinishCallback.current();
            }
        } else if (action == 'cancel') {
            if (endButtonOnCancelCallback.current != null) {
                endButtonOnCancelCallback.current()
            }
        }
    };

    const deleteLayer = (layerId) => {
        drawInstance.current.delete(layerId);
    };

    const deleteAllLayers = () => {
        drawInstance.current.deleteAll();
    };

    const callOnCreateCallback = (arg1, arg2) => {
        if (typeof onCreateCallback.current == 'function') {
            onCreateCallback.current(arg1, arg2);
        }
    }

    const value = {
        map: mapInstance,
        draw: drawInstance,
        drawing,
        initDraw,
        startDrawing,
        startDrawingSilent,
        endDrawing,

        isDrawing,

        endButton,
        showEndButton,
        hideEndButton,
        endButtonEventEmitter,
        callOnCreateCallback,

        deleteLayer,
        deleteAllLayers
    };

    return (
        <>
            <DrawContext.Provider value={value}>
                {children}
            </DrawContext.Provider>
        </>
    )
};