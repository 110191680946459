import { useState, useCallback, useEffect } from "react";
import { useMatch } from "react-router-dom";

import { useModalContext } from "../../../../../../../_metronic/elements/Modal";
import { usePrescription } from "./PrescriptionContext";
import { openAlert } from "../../../../../../../_metronic/elements/NotificationAlert";
import { Loading } from "../../../../../../../_metronic/elements/Loading";
import { useErrorHandler } from "../../../../../../../_metronic/helpers/ErrorHandler";
import { useViewLandContext } from "../ViewLandContext";
import { useViewImageContext } from "../Images/ViewImageContext";
import { useViewAmbientContext } from "../Ambients";
import { format } from "date-fns";
import PrescriptionForm from "./PrescriptionForm";
import * as LandService from "../../../services/LandService";
import { useTranslation } from "react-i18next";

const clsPresc = {
  0: 'mbp',
  1: 'bp',
  2: 'pp',
  3: 'ap',
  4: 'map',
  5: 'five',
  6: 'six'
};

const clsPrescInv = {
  'mbp': 0,
  'bp': 1,
  'pp': 2,
  'ap': 3,
  'map': 4,
  'five': 5,
  'six': 6
};

const ModalHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="modal-header">
        <h5 className="modal-title">{t("prescription:creation.title")}</h5>
      </div>
    </>
  );
};

const ModalFooter = ({ downloading }) => {
  const modal = useModalContext();
  const { t } = useTranslation();

  return (
    <div className="modal-footer">
      <button disabled={downloading} type="button" className="btn btn-light" onClick={() => modal.hideModal()}>
        {t("prescription:creation.buttons.cancel")}
      </button>
      <button disabled={downloading} type="submit" className="btn btn-primary">
        {t("prescription:creation.buttons.download")}
      </button>
    </div>
  )
}

const PrescriptionDialogClasses = ({ open, setOpen, ambient, numClasses }) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [prescription, setPrescription] = useState({});
  const { prod, columns } = usePrescription();
  const { errorHandler } = useErrorHandler();
  const { t } = useTranslation();
  const isAmbientActive = useMatch('/gis/field/view/:field_id/land/view/:land_id/ambients');
  const viewLandContext = useViewLandContext();
  const viewImageContext = useViewImageContext();
  const viewAmbientContext = useViewAmbientContext();

  const load = useCallback(async () => {
    setLoading(true);

    const presc = {
      map: { ha: 0 },
      ap: { ha: 0 },
      pp: { ha: 0 },
      bp: { ha: 0 },
      mbp: { ha: 0 },
      five: { ha: 0 },
      six: { ha: 0 }
    };

    if (!isAmbientActive || !ambient) {
      const imageLand = viewImageContext.getLand(viewLandContext.land.land_id);

      if (imageLand == null) {
        return;
      }

      const res = await errorHandler(LandService.classifyPrescription(viewLandContext.land.land_id, {
        layer: imageLand['imageType'] == 'evi' ? 'evi' : 'ndvi',
        date: format(imageLand['selectedDate'], 'yyyy-MM-dd'),
        num_classes: numClasses
      }));

      for (var z in res.data.data.classes) {
        const { cls, sup_ha } = res.data.data.classes[z];
        presc[clsPresc[cls]]['ha'] += sup_ha;
        presc[clsPresc[cls]]['cls'] = cls;
      }
    } else {
      ambient.geojson.features.forEach(e => {
        presc[clsPresc[e.properties.cls]]['ha'] += e.properties.sup_ha;
        presc[clsPresc[e.properties.cls]]['cls'] = e.properties.cls;
      });
    }

    setPrescription(presc);
    setLoading(false);
  }, [ambient, viewLandContext.land, isAmbientActive]);

  useEffect(() => {
    load();
  }, [ambient]);

  const handleDownload = async (e) => {
    e.preventDefault();
    setDownloading(true);
    const validPrescription = Object.keys(prescription).filter(e => prescription[e]['ha'] > 0).length;
    const data = [];

    const dataName = { 0: 'prod1', 1: 'prod2', 2: 'prod3' };
    const validColumns = [];

    for (var i = 0; i < validPrescription; i++) {
      validColumns.push(clsPresc[i]);
    }

    for (var z in columns) {
      let valid = true;

      Object.keys(prescription).filter(e => prescription[e]['ha'] > 0).forEach(e => {
        const currentCls = prescription[e]['cls'];
        if (!columns[z][currentCls] || columns[z][currentCls].length < 1) {
          valid = false;
        }
      });

      if (valid && Object.keys(columns[z]).length >= validPrescription) {
        data.push({
          name: prod[dataName[z]] || `Prod ${z + 1}`,
          values: Object.keys(columns[z]).map(key => {
            return {
              'cls': key,
              'value': parseFloat(columns[z][key])
            };
          })
        });
      }
    }

    if (data.length < 1) {
      openAlert('danger', t("prescription:creation.errors.title"), t("prescription:creation.errors.missing_at_least_column"));
      setDownloading(false);
      return;
    }

    let res;
    if (!ambient || !isAmbientActive) {
      const imageLand = viewImageContext.getLand(viewLandContext.land.land_id);
      const totalRequest = {
        layer: imageLand['imageType'] == 'evi' ? 'evi' : 'ndvi',
        date: format(imageLand['selectedDate'], 'yyyy-MM-dd'),
        num_classes: numClasses,
        driver: 'ESRI Shapefile',
        data: data
      };
      res = await errorHandler(LandService.downloadPrescription({ land_id: viewLandContext.land.land_id, data: totalRequest }));
    } else {
      const totalRequest = {
        driver: 'ESRI Shapefile',
        data: data
      };
      if (viewAmbientContext.selectedElement === 'ambient'){
        res = await errorHandler(LandService.downloadPrescriptionAmbient({ land_id: viewLandContext.land.land_id, data: totalRequest }));
      }
      else if (viewAmbientContext.selectedElement === 'imageAmbient'){
        res = await errorHandler(LandService.downloadPrescriptionImageAmbient({ land_id: viewLandContext.land.land_id, data: totalRequest }));
      }
      
      
    }

    if (res.ok) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', t("prescription:creation.success.filename"));
      document.body.appendChild(link);
      link.click();

      openAlert('success', t("prescription:creation.success.title"), t("prescription:creation.success.message"));
    }

    setDownloading(false);
  }

  if (loading) {
    return <div className="p-5"><Loading /></div>;
  }

  if (prescription == null || Object.keys(prescription).length < 1) {
    return (<></>);
  }

  return (
    <>
      <form onSubmit={handleDownload} className='form'>
        <ModalHeader />
        <div className="modal-body">
          {!loading && <PrescriptionForm numClasses={numClasses} prescription={prescription} />}
        </div>
        <ModalFooter downloading={downloading} />
      </form>
    </>
  );
};

export default PrescriptionDialogClasses;