import { useState } from "react";
import { useTranslation } from "react-i18next";

import { TotalAvgHistory, AccumulatedPerDate, Land, Ranking } from "./Charts";
import DeleteReport from "./DeleteReport";

const ModalBody = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState("history");

  const changeReport = (choice) => (e) => {
    e.preventDefault();
    setSelected(choice);
  }

  return (
    <>
      <div className="modal-body p-5">
        <DeleteReport />
        <div className="row">
          <div className="col-3">
            <nav className="nav flex-column nav-pills">
              <a onClick={changeReport("history")} className={"nav-link " + (selected == "history" ? "active" : "")} aria-current="page" href="#">{t("reports:ndvi.view.menu.ndvi_avg")}</a>
              <a onClick={changeReport("accumulated-per-date")} className={"nav-link " + (selected == "accumulated-per-date" ? "active" : "")} href="#">{t("reports:ndvi.view.menu.date_comparative")}</a>
              <a onClick={changeReport("ranking")} className={"nav-link " + (selected == "ranking" ? "active" : "")} href="#">{t("reports:ndvi.view.menu.ranking")}</a>
              <a onClick={changeReport("land")} className={"nav-link " + (selected == "land" ? "active" : "")} href="#">{t("reports:ndvi.view.menu.land")}</a>
            </nav>
          </div>
          <div className="col-9">
            {selected == "history" && <TotalAvgHistory />}
            {selected == "accumulated-per-date" && <AccumulatedPerDate />}
            {selected == "land" && <Land />}
            {selected == "ranking" && <Ranking />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalBody;