import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAmbientDialogContext } from '../AmbientDialogContext';

const Months = () => {
  const { monthFrom, setMonthFrom, monthTo, setMonthTo, season, disabled } = useAmbientDialogContext();
  const { t } = useTranslation();
  const months = useMemo(() => {
    return [
      ['01', t("ambient:create.months.january")],
      ['02', t("ambient:create.months.february")],
      ['03', t("ambient:create.months.march")],
      ['04', t("ambient:create.months.april")],
      ['05', t("ambient:create.months.may")],
      ['06', t("ambient:create.months.june")],
      ['07', t("ambient:create.months.july")],
      ['08', t("ambient:create.months.august")],
      ['09', t("ambient:create.months.september")],
      ['10', t("ambient:create.months.october")],
      ['11', t("ambient:create.months.november")],
      ['12', t("ambient:create.months.december")]
    ];
  }, []);

  useEffect(() => {
    if (season == 'Estival') {
      setMonthFrom('10');
      setMonthTo('05')
    } else {
      setMonthFrom('04');
      setMonthTo('11');
    }
  }, [season]);

  return (
    <>
      <p className="fs-7 text-center fw-bold mb-3">{t("ambient:create.input.month_from.label")}</p>
      <div className="row">
        <div className="col-6">
          <select disabled={disabled} className="form-select" value={monthFrom} onChange={(e) => setMonthFrom(e.target.value)}>
            {months.map(e =>
              (<option value={e[0]}>{e[1]}</option>)
            )}
          </select>
        </div>
        <div className="col-6">
          <select disabled={disabled} className="form-select" value={monthTo} onChange={(e) => setMonthTo(e.target.value)}>
            {months.map(e =>
              (<option value={e[0]}>{e[1]}</option>)
            )}
          </select>
        </div>
      </div>
    </>
  );
};

export default Months;