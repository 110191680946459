import { createContext, useContext, useState, useCallback, useEffect } from "react";

import { useErrorHandler } from "../../../../_metronic/helpers/ErrorHandler";
import * as LandService from "../Fields/services/LandService";

const SearchContext = createContext();

export function useSearchContext() {
  return useContext(SearchContext);
}

export const SearchConsumer = SearchContext.Consumer;

export function SearchProvider({ children }) {
    const [ options, setOptions ] = useState([]);
    const [ groupedOptions, setGroupedOptions ] = useState([]);
    const { errorHandler } = useErrorHandler();
    const load = useCallback(() => { 
        errorHandler(LandService.all()).then(res => {
            const total = res.data.data.map(r => ({ "id": r.land_id, "field_id": r.field.field_id, "label": r.field.name + " / " + r.name}));
            const totalGroup = {};

            res.data.data.forEach(r => {
                if (!totalGroup[r.field.field_id]) {
                    totalGroup[r.field.field_id] = {
                        "name": r.field.name,
                        "field_id": r.field.field_id,
                        "lands": []
                    };
                }

                totalGroup[r.field.field_id]["lands"].push({
                    "id": r.land_id,
                    "name": r.name,
                    "ha": r.ha
                });
            });

            setOptions(total);
            setGroupedOptions(Object.values(totalGroup));
        });
    }, []);

    const value = {
        options, setOptions,
        groupedOptions,
        load
    };

    useEffect(() => {
        load();
    }, [])

    return (
        <>
            <SearchContext.Provider value={value}>
                {children}
            </SearchContext.Provider>
        </>
    );
};