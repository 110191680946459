import { createContext, useContext, useState } from "react";
import { drawEndButtonState } from "./DrawEndButtonState";

const DrawEndButtonContext = createContext();

export function useDrawEndButton() {
    return useContext(DrawEndButtonContext);
};

export const DrawEndButtonConsumer = DrawEndButtonContext;

export function DrawEndButtonProvider({ children }) {
    const [ isShowing, setIsShowing ] = useState(false);

    const show = ({  title, onFinish, onCancel, buttonText }) => {
         drawEndButtonState.show({ title, onFinish, onCancel, buttonText });
        setIsShowing(drawEndButtonState.isShowing());
    };
    
    const emit = (event) => {
        drawEndButtonState.emit(event);
    }

    const hide = () => {
        drawEndButtonState.hide();
        setIsShowing(drawEndButtonState.isShowing());
    }

    const value = {
        show,
        hide,
        emit,
        isShowing,
    };

    return (
        <>
            <DrawEndButtonContext.Provider value={value}>
                {children}
            </DrawEndButtonContext.Provider>
        </>
    );
};