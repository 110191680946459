import { useTranslation } from "react-i18next";
import clsx from 'clsx';

const Email = ({ formik }) => {
    const { t } = useTranslation();

    return (
        <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>{t("auth:login_box.input.email.label")}</label>
        <input
          type='email'
          name='email'
          placeholder={t("auth:login_box.input.email.placeholder")}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 
              'is-invalid': formik.touched.email && formik.errors.email 
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
    );
};

export default Email;