import { Routes, Route } from "react-router-dom";

import ForgotPassword from "./ForgotPassword";
import Recover from "./Recover";


const ForgotPasswordModule = () => {
    return ( 
        <>
            <Routes>
                <Route path='recover' element={<Recover />} />
                <Route index element={<ForgotPassword />} />
            </Routes>
        </>
    );
};

export default ForgotPasswordModule;