import { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export function useModalContext() {
  return useContext(ModalContext);
}

export const ModalConsumer = ModalContext.Consumer;

export function ModalProvider({ hideModal, children }) {

    const value = {
        hideModal
    };

    return (
        <>
            <ModalContext.Provider value={value}>
                {children}
            </ModalContext.Provider>
        </>
    );
};